import { http } from '@/http'

export default {
  list(params) {
    return http.get('/api/v1/admin/post', { params })
  },
  get(id) {
    return http.get(`/api/v1/admin/post/${id}`);
  },
  update(id, payload) {
    return http.put(`/api/v1/admin/post/${id}`, payload);
  }
}
