<template>
  <div class="dashboard-card">
    <div class="payment-details">
      <img src="@/core/assets/images/payments/pago-efectivo-lg.png" alt="Logo Izipay" class="logo rounded-circle" />

      <HelpCollapse>
        <template slot="content">
          <div class="help-collapse-content">
            <p>
              Para empezar a recibir pagos a través de PagoEfectivo, por favor completa el siguiente formulario.
            </p>
            <p>
              Por motivos de seguridad, cuando vuelvas a ingresar a este formulario, no verás cargada la información
              sensible y deberás actualizarla nuevamente en caso haya cambiado.
            </p>
          </div>
        </template>
      </HelpCollapse>

      <form action="" class="custom-form mt-4" @submit.prevent="save()">
        <div class="form-group">
          <label for="storeId">ID Comercio *</label>
          <FormInput id="storeId" type="text" placeholder="Ingresar ID de Comercio" v-model="form.storeId"
            :showError="$v.form.storeId.$error && !$v.form.storeId.required" />
          <form-error message="ID Comercio es requerido"
            v-if="$v.form.storeId.$error && !$v.form.storeId.required"></form-error>
        </div>
        <div class="form-group">
          <label for="accessKey">Access Key *</label>
          <FormInput id="accessKey" type="text" placeholder="Ingresar access key" v-model="form.accessKey"
            :showError="$v.form.accessKey.$error && !$v.form.accessKey.required" />
          <form-error message="Access Key es requerida"
            v-if="$v.form.accessKey.$error && !$v.form.accessKey.required"></form-error>
        </div>
        <div class="form-group">
          <label for="secretKey">Secret Key *</label>
          <FormInput id="secretKey" type="text" placeholder="Ingresar access key" v-model="form.secretKey"
            :showError="$v.form.secretKey.$error && !$v.form.secretKey.required" />
          <form-error message="Secret Key es requerida"
            v-if="$v.form.secretKey.$error && !$v.form.secretKey.required"></form-error>
        </div>

        <div class="form-group">
          <label for="expirationHours">Tiempo de expiración en horas</label>
          <FormInput id="expirationHours" type="number" placeholder="Ingresar tiempo de expiración en horas"
            :showError="$v.form.expirationHours.$error && !$v.form.expirationHours.required" v-model="form.expirationHours" />
          <form-error message="Tiempo de expiración en horas es requerido"
            v-if="$v.form.expirationHours.$error && !$v.form.expirationHours.required"></form-error>
        </div>

        <div class="form-group">
          <label for="environment">Entorno</label>
          <div class="d-flex">
            <b-form-radio v-model="form.env" name="enviroment" value="PRODUCTION">Producción</b-form-radio>
            <b-form-radio v-model="form.env" name="enviroment" value="INTEGRATION"
              class="ml-2">Integración</b-form-radio>
          </div>
        </div>

        <div class="form-group-buttons text-right">
          <router-link :to="{ name: 'config-payment-methods' }" class="button button-light">
            Volver
          </router-link>
          <button class="button button-primary" type="submit">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { FormInput, FormError } from "wize-admin";
import PaymentMethodEntityMixin from "@/core/mixins/PaymentMethodEntityMixin"
import HelpCollapse from "./HelpCollapse.vue";

export default {
  name: "PagoEfectivoForm",
  mixins: [PaymentMethodEntityMixin],
  data() {
    return {
      form: {
        id: "PAGO_EFECTIVO",
        storeId: null,
        accessKey: null,
        secretKey: null,
        expirationHours: null,
        env: "INTEGRATION",
        isActive: false
      },
    };
  },
  validations: {
    form: {
      storeId: { required },
      accessKey: { required },
      secretKey: { required },
      expirationHours: { required }
    },
  },
  components: {
    HelpCollapse,
    FormInput,
    FormError
  },
  async mounted() {
    await this.load();
    this.form.id = "PAGO_EFECTIVO";
  }
};
</script>

<style lang="stylus" scoped>
@import "../../Styles/payment-details.styl"
</style>
