var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar cliente" : "Nuevo cliente")+" ")])]),_c('div',[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"760px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos del cliente" : "Datos del nuevo cliente")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body p-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section"},[_c('h2',{staticClass:"custom-card-subtitle"},[_vm._v("Información general")]),_c('p',{staticClass:"custom-card-description"},[_vm._v(" Ingresa los datos del cliente ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"identifierType"}},[_vm._v("Tipo de documento *")]),_c('FormSelect',{attrs:{"id":"identifierType","items":_vm.identifierTypes,"defaultOption":"Elegir tipo de documento","showError":_vm.$v.customer.identifierType.$error},on:{"change":function($event){return _vm.updateMask()}},model:{value:(_vm.customer.identifierType),callback:function ($$v) {_vm.$set(_vm.customer, "identifierType", $$v)},expression:"customer.identifierType"}}),(
                      _vm.$v.customer.identifierType.$error &&
                      !_vm.$v.customer.identifierType.required
                    )?_c('form-error',{attrs:{"message":"Tipo de documento es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"identifier"}},[_vm._v("Número de documento *")]),_c('FormInput',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.documentMask),expression:"documentMask"}],attrs:{"id":"identifier","type":"text","placeholder":"Ingresar número de documento","show-error":_vm.$v.customer.identifier.$error &&
                      !_vm.$v.customer.identifier.required},model:{value:(_vm.customer.identifier),callback:function ($$v) {_vm.$set(_vm.customer, "identifier", $$v)},expression:"customer.identifier"}}),(
                      _vm.$v.customer.identifier.$error &&
                      !_vm.$v.customer.identifier.required
                    )?_c('form-error',{attrs:{"message":"Número de documento es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"firstName"}},[_vm._v("Nombres *")]),_c('FormInput',{attrs:{"id":"firstName","type":"text","maxlength":"100","placeholder":"Ingresar nombres","show-error":_vm.$v.customer.firstName.$error &&
                      !_vm.$v.customer.firstName.required},model:{value:(_vm.customer.firstName),callback:function ($$v) {_vm.$set(_vm.customer, "firstName", $$v)},expression:"customer.firstName"}}),(
                      _vm.$v.customer.firstName.$error &&
                      !_vm.$v.customer.firstName.required
                    )?_c('form-error',{attrs:{"message":"Nombres son requeridos"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"lastName"}},[_vm._v("Apellidos *")]),_c('FormInput',{attrs:{"id":"lastName","type":"text","maxlength":"100","placeholder":"Ingresar apellidos","show-error":_vm.$v.customer.lastName.$error &&
                      !_vm.$v.customer.lastName.required},model:{value:(_vm.customer.lastName),callback:function ($$v) {_vm.$set(_vm.customer, "lastName", $$v)},expression:"customer.lastName"}}),(
                      _vm.$v.customer.lastName.$error &&
                      !_vm.$v.customer.lastName.required
                    )?_c('form-error',{attrs:{"message":"Apellidos son requeridos"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"mobile"}},[_vm._v("Celular *")]),_c('FormInput',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"id":"mobile","type":"text","maxlength":"20","placeholder":"Ingresar celular","show-error":_vm.$v.customer.mobile.$error &&
                      !_vm.$v.customer.mobile.required},on:{"input":function($event){return _vm.$v.customer.mobile.$touch()}},model:{value:(_vm.customer.mobile),callback:function ($$v) {_vm.$set(_vm.customer, "mobile", $$v)},expression:"customer.mobile"}}),(
                      _vm.$v.customer.mobile.$error &&
                      !_vm.$v.customer.mobile.required
                    )?_c('form-error',{attrs:{"message":"Celular es requerido"}}):_vm._e(),(!_vm.$v.customer.mobile.regex)?_c('form-error',{attrs:{"message":"Celular solo debe contener números o el signo '+'"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Correo electrónico")]),_c('FormInput',{attrs:{"id":"email","type":"email","disabled":_vm.editing,"maxlength":"100","placeholder":"Ingresar correo electrónico","show-error":_vm.$v.customer.email.$error && !_vm.$v.customer.email.required},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),(
                      _vm.$v.customer.email.$error && !_vm.$v.customer.email.required
                    )?_c('form-error',{attrs:{"message":"Correo electrónico es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"birthday"}},[_vm._v("Fecha de nacimiento")]),_c('FormInput',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"id":"DELIVERY_SPAN_DAYS","type":"text","placeholder":"DD/MM/AAAA"},model:{value:(_vm.customer.birthday),callback:function ($$v) {_vm.$set(_vm.customer, "birthday", $$v)},expression:"customer.birthday"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"gender"}},[_vm._v("Género")]),_c('FormSelect',{attrs:{"id":"gender","items":_vm.genders,"defaultOption":"Elegir género"},model:{value:(_vm.customer.gender),callback:function ($$v) {_vm.$set(_vm.customer, "gender", $$v)},expression:"customer.gender"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"referenceId"}},[_vm._v("Referencia de registro")]),_c('FormSelect',{attrs:{"id":"referenceId","items":_vm.referencesOptions,"defaultOption":"Elegir referencia"},model:{value:(_vm.customer.referenceId),callback:function ($$v) {_vm.$set(_vm.customer, "referenceId", $$v)},expression:"customer.referenceId"}})],1)])]),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'clients-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }