<template>
  <div class="page-container products-list">
    <div class="page-title-container">
      <h1 class="page-title">Lista de cotizaciones</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div class="d-flex d-lg-none justify-content-between mb-2">
          <div>
            <button
              type="button"
              class="button-tooltip d-lg-none m-0"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>
          </div>

          <b-button
            :to="{ name: 'tools-quotes-form' }"
            variant="none"
            class="button button-primary"
            >Agregar</b-button
          >
        </div>

        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start"
        >
          <div class="first-section w-100 mt-2 mt-md-0">
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex align-items-center justify-content-between filter"
                @click="showFilters = !showFilters"
              >
                <FiltersIcon />
                <div>Filtrar cotizaciones</div>
              </div>
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section class="w-100 d-flex align-items-center">
            <button
              type="button"
              class="button-tooltip d-none d-lg-block"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>

            <SearchBar
              placeholder="Buscar por Nro de cotización, Nro de documento, teléfono, email o persona de contacto"
              @search="onSearch"
              @input="search = $event"
              class="w-100"
            />

            <div class="d-none d-lg-block">
              <b-button
                :to="{ name: 'tools-quotes-form' }"
                variant="none"
                class="button button-primary ml-lg-2"
                >Agregar</b-button
              >
            </div>
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>
      <div class="table-responsive mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>N° de cotización</th>
              <th>
                Fecha de <br />
                cotización
              </th>
              <th class="pl-md-4">
                N° de <br />
                documento
              </th>
              <th>Razón social/nombre</th>
              <th class="pr-md-2">Contacto</th>
              <th>Teléfono</th>
              <th class="px-md-2">Correo <br> electrónico</th>
              <th class="px-md-2">Monto</th>
              <th>Estado</th>
              <th class="pr-md-2">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center pl-1">{{ o.correlationId }}</td>
              <td class="text-center px-1">{{ getQuoteCreatedAt(o.createdAt) }}</td>
              <td class="text-center">{{ o.customerIdentifier }}</td>
              <td class="pl-3">{{ o.customerFullName || "-" }}</td>
              <td class="text-center">
                {{ o.customerContact || "-" }}
              </td>
              <td class="text-center px-2">{{ o.customerMobile || "-" }}</td>
              <td class="text-center">{{ o.customerEmail || "-" }}</td>
              <td class="text-center px-1"><span v-decimal:pen="o.totalPlusDelivery"></span></td>
              <td class="text-center px-1">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem :to="{ name: 'tools-quotes-edit', params: { id: o.id } }" text="Editar" v-if="o.status == LocalConstants.QuoteStatus.DRAFT.key" />

                    <DropdownItem
                    @click="openDelete(o)"
                      text="Eliminar"
                      v-if="o.status == LocalConstants.QuoteStatus.DRAFT.key"
                    />

                    <DropdownItem
                      text="Descargar"
                      @click="downloadQuote(o.id)"
                      v-if="
                        o.status == LocalConstants.QuoteStatus.GENERATED.key
                      "
                    />

                    <DropdownItem
                      text="Duplicar"
                      @click="cloneQuote(o.id)"
                      v-if="
                        o.status == LocalConstants.QuoteStatus.GENERATED.key
                      "
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  Util,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  FilterMenu,
  FiltersIcon,
  CloseBorderRoundedIcon,
  RefreshTableIcon,
  ConfirmationModalMixin,
  ConfirmationModal
} from "wize-admin";

import QuoteMixin from "@/core/mixins/QuoteMixin";
import { Constants as LocalConstants } from "@/core/utils";
import { Helpers } from "@/core/utils";

import { QuoteService } from "@/core/services";

export default {
  mixins: [PaginationMixin, FilterMixin, ConfirmationModalMixin, QuoteMixin],
  data() {
    return {
      LocalConstants,
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.QuoteStatus.DRAFT,
            LocalConstants.QuoteStatus.GENERATED,
          ],
        },
        {
          label: "Fecha de cotización",
          key: "createdAt",
          type: Constants.TypeFilters.DATE,
        },
      ],
    };
  },
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
    FiltersIcon,
    CloseBorderRoundedIcon,
    RefreshTableIcon,
    ConfirmationModal
  },
  methods: {
    async loadData() {
      try {
        this.$store.dispatch("app/loading", true);

        // Asignar filtros
        let filters = {
          ...this.filters,
        };

        if (filters.createdAt) {
          filters.createdAtStart = Helpers.localDate(
            filters.createdAt.startDate,
            "YYYY-MM-DD"
          );
          filters.createdAtEnd = Helpers.localDate(
            filters.createdAt.endDate,
            "YYYY-MM-DD"
          );
        }

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          search: this.search,
          ...filters,
        };
        const response = await QuoteService.list(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        Alert.error(error);
        console.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.QuoteStatus);
    },
    getQuoteCreatedAt(date) {
      return Helpers.localDate(date, "DD/MM/YYYY");
    },
    async downloadQuote(id) {
      const response = await this.getQuoteData(id);

      if (response) {
        let data = {
          ...response.customer,
          items: response.productList,
        };

        let previewPDF = false;

        this.generatePDF(data, previewPDF);
      }
    },
    async cloneQuote(id) {
      const response = await this.getQuoteData(id);

      if (response) {
        let customer = response.customer;

        let productList = response.productList.map(({ productCode, quantity }) => {
          return {
            productCode,
            quantity,
          };
        })

        let data = {
          ...customer,
          items: productList,
          status: LocalConstants.QuoteStatus.DRAFT.key
        };

        try {
          let responseNewQuote = await QuoteService.save(data)

          if (responseNewQuote.status == "OK") {
            this.$router.push({name: 'tools-quotes-edit', params: { id: responseNewQuote.payload.id }})

            setTimeout(() => {
              this.$smoothScroll({
                scrollTo: document.querySelector("#quote-editor"),
                updateHistory: false
              })
            }, 500)
          }
        } catch (ex) {
          console.error(ex);
          Alert.error(ex.errors.message);
        } finally {
          this.$store.dispatch("app/loading", false);
        }
      }
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteQuote();
    },
    async deleteQuote() {
      try {
        await this.$store.dispatch("app/loading", true);
        await QuoteService.delete(this.selectedOption.id);
        await this.loadData()
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message)
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    }
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
