import ToolsView from "../ToolsView.vue";
import ProductQuoteList from "../Pages/ProductQuoteList.vue"
import ProductQuoteEditor from "../Pages/ProductQuoteEditor.vue"
import RedirectionsList from "../Pages/RedirectionsList.vue";
import RedirectionsEditor from "../Pages/RedirectionsEditor.vue";
import BiolinkPage from '../Pages/BiolinkPage.vue'
import SiteSearchList from '../Pages/SiteSearchList.vue'

const routes = [
  {
    path: '/herramientas',
    component: ToolsView,
    plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
    name: 'tools',
    children: [
      {
        path: 'cotizaciones',
        name: 'tools-quotes-list',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: ProductQuoteList
      },
      {
        path: 'cotizaciones/crear',
        name: 'tools-quotes-form',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: ProductQuoteEditor
      },
      {
        path: 'cotizaciones/:id/editar',
        name: 'tools-quotes-edit',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: ProductQuoteEditor
      },
      {
        path: 'redirecciones',
        name: 'tools-redirections-list',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: RedirectionsList
      },
      {
        path: 'redirecciones/crear',
        name: 'tools-redirections-create',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: RedirectionsEditor
      },
      {
        path: 'redirecciones/:id/editar',
        name: 'tools-redirections-edit',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: RedirectionsEditor
      },
      {
        path: 'biolink',
        name: 'tools-biolink',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: BiolinkPage,
      },
      {
        path: 'busquedas',
        name: 'tools-site-search',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: SiteSearchList
      }
    ]
  },
]

export default routes
