<template>
  <div class="page-container categories-list">
    <div class="page-title-container">
      <h1 class="page-title">Categorías</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar categorías</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por nombre de categoría"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'config-categories-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Agregar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Foto</span>
            </div>
            <div>
              <span>Nombre de categoría</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid text-center">
                <div>
                  <div
                    class="table-image cursor-pointer"
                    @click="setShowGalleryModal(true, o)"
                  >
                    <img :src="o.image.url" alt="" v-if="o.image" />
                  </div>
                </div>

                <div>
                  <span>{{ o.name }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{ name: 'config-categories-edit', params: { id: o.id } }"
                >
                  Editar
                </router-link>

                <a href="" @click.prevent="openChangeStatus(o)" class="mx-3">{{ getStatus(o.isActive).action }}</a>

                <a href="" @click.prevent="openDelete(o)">Eliminar</a>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Slug:</span
                    >
                    <span>{{ o.slug }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Descripción corta:</span
                    >
                    <p
                      class="table-description m-0"
                      :class="{ 'text-center': !o.shortDescription }"
                    >
                      {{ getDescription(o.shortDescription) }}
                    </p>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Cantidad de productos:</span
                    >
                    <span>{{ o.productCount || 0 }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.isActive).color}`">{{
                      getStatus(o.isActive).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Foto</th>
              <th class="pl-md-4">
                Nombre de <br />
                categoría
              </th>
              <th class="pl-md-4">Slug</th>
              <th>Descripción corta</th>
              <th>
                Cantidad de <br />
                productos
              </th>
              <th>Estado</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center pl-md-3">
                <div
                  class="table-image cursor-pointer"
                  @click="setShowGalleryModal(true, o)"
                >
                  <img :src="o.image.url" alt="" v-if="o.image" />
                </div>
              </td>
              <td class="pl-md-4 text-center">{{ o.name }}</td>
              <td class="pl-md-4">{{ o.slug }}</td>
              <td>
                <p
                  class="table-description"
                  :class="{ 'text-center': !o.shortDescription }"
                >
                  {{ getDescription(o.shortDescription) }}
                </p>
              </td>
              <td class="text-center">{{ o.productCount || 0 }}</td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.isActive).color}`">{{
                  getStatus(o.isActive).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      :to="{
                        name: 'config-categories-edit',
                        params: { id: o.id },
                      }"
                      text="Editar"
                    />
                    <DropdownItem
                      :text="getStatus(o.isActive).action"
                      @click="openChangeStatus(o)"
                    />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />

    <GalleryModal
      :show="showGalleryModal"
      @hide="setShowGalleryModal(false)"
      :image="selectedOption.url"
      v-if="selectedOption"
    />
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  Util,
  ConfirmationModalMixin,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ConfirmationModal,
  FilterMenu,
} from "wize-admin";

import { Constants as LocalConstants, Helpers } from "@/core/utils";

import { CategoryService } from "@/core/services";

import GalleryModal from "@/core/components/common/GalleryModal";

export default {
  components: {
    SearchBar,
    FilterMenu,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
    GalleryModal,
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "isActive",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.CategoriesStatus.ACTIVE,
            LocalConstants.CategoriesStatus.INACTIVE,
          ],
        },
      ],
      showGalleryModal: false,
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          sort: "id,asc",
          search: this.search,
          ...this.filters,
        };

        const response = await CategoryService.list(params);

        this.list = response.payload.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        })

      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.CategoriesStatus);
    },
    getDescription(value) {
      return Helpers.sliceText(value, 110);
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteItem();
      else if (this.confirmationModal.action === "update") this.changeStatus();
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (
          await CategoryService.toggleStatus(
            this.selectedOption.id,
            !this.selectedOption.isActive
          )
        ).payload;

        const category = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (category) {
          const index = this.list.indexOf(category);
          this.$set(this.list, index, resp);
        }
        Alert.success("Estado actualizado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description:
          "¿Está seguro que desea cambiar el estado de la categoría?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteItem() {
      try {
        await this.$store.dispatch("app/loading", true);
        await CategoryService.delete(this.selectedOption.id);
        await this.loadData();
        this.$store.commit("app/setCategories", null);
        Alert.success("Categoría eliminada");
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    setShowGalleryModal(value, item) {
      if (value && item) {
        this.selectedOption = item.image;
      } else {
        this.selectedOption = null;
      }

      this.showGalleryModal = value;
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/categories-list.styl"
</style>
