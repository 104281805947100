<template>
  <section class="complaint-details" v-if="customer">
    <div class="dashboard-banner">
      <h1 class="dashboard-title">Datos del cliente</h1>
    </div>

    <div class="card-details">
      <section class="section pb-0 border-0">
        <h2 class="subtitle my-0">Información general</h2>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Nombres y Apellidos</label>
            <p class="text">
              {{ `${customer.firstName} ${customer.lastName}` }}
            </p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Tipo de documento</label>
            <p class="text">
              {{ getIdentifierType(customer.identifierType).label }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">N° de documento</label>
            <p class="text">{{ customer.identifier }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Celular</label>
            <p class="text">{{ customer.mobile }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Correo electrónico</label>
            <p class="text">{{ customer.email }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Fecha de nacimiento</label>
            <p class="text" v-local-date="customer.birthday"></p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Género</label>
            <p class="text">{{ getGender(customer.gender).label }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Fecha de registro</label>
            <p class="text" v-local-date="customer.createdAt"></p>
          </div>
        </div>

        <div class="row buttons-container">
          <div class="col text-right">
            <button
              @click="$router.push({ name: 'clients-list' })"
              class="button button-light"
            >
              Volver
            </button>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { Constants, Util, Alert } from "wize-admin";
import { CustomerService } from "@/core/services";

import { Constants as LocalConstants } from "@/core/utils";

export default {
  data() {
    return {
      customer: null,
    };
  },
  methods: {
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getGender(key) {
      return Util.searchValue(key, LocalConstants.Genders);
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await CustomerService.get(id);
        const payload = response.payload;
        this.customer = { ...payload };
        this.$nextTick(() => {
          this.customer.identifier = payload.identifier;
        });
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({ name: "clients-list" }));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped></style>
