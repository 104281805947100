<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Actualizar stock</h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Carga de archivo .csv</h3>

          <button
            type="button"
            class="button-tooltip font-weight-bold text-dark m-0"
            v-b-tooltip.hover
            title="Descargar plantilla"
            @click="downloadTemplate()"
          >
            <i class="ri-download-2-line"></i>
          </button>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-section">
              <file-input
                @onChange="onChange"
                idFile="relation-batch-file"
                :acceptFile="['.csv']"
                ref="fileUpload"
                class="mt-0"
              />
              <form-error
                message="El archivo es requerido"
                v-if="$v.file.$error && !$v.file.required"
              ></form-error>

              <div class="form-group-buttons text-right">
                <button class="button button-primary" type="submit">
                  Procesar
                </button>
              </div>

              <div
                class="table-container mt-4 overflow-hidden"
                v-if="list.length"
              >
                <LogsTable :items="list" />
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import fileDownload from "js-file-download";

import { Alert, FileInput, FormError, Util } from "wize-admin";

import { BatchService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

import LogsTable from "../Components/LogsTable";

export default {
  data() {
    return {
      file: null,
      editing: false,
      list: [],
    };
  },
  components: {
    FileInput,
    FormError,
    LogsTable,
  },
  validations: {
    file: { required },
  },
  watch: {
    "$route.params.serieId": function () {
      if (this.$route.params.serieId) this.load(this.$route.params.serieId);
    },
  },
  computed: {},
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      const data = Util.getFormData({
        file: this.file,
        clearAll: this.clearAll,
      });
      try {
        const payload = (await BatchService.uploadStock(data)).payload;

        // Obtener items con errores
        let errorItems = payload.filter((item) => item.type === "ERROR");

        this.list = payload;

        if (errorItems.length) {
          return;
        }

        Alert.success("Se ha realizado la carga correctamente");
      } catch (ex) {
        let error = ex.errors
          ? `${ex.errors.message}.`
          : "Ocurrió un error, por favor inténtelo nuevamente";
        Alert.error(error);
      } finally {
        this.$refs.fileUpload.remove();
        await this.$store.dispatch("app/loading", false);
      }
    },
    onChange(file) {
      this.file = file;
    },
    async downloadTemplate() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await BatchService.stockTemplate();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.serieId) this.load(this.$route.params.serieId);
  },
};
</script>

<style lang="stylus" scoped></style>
