<template>
  <div class="help-collapse" :class="{'collapsed': visible}">
    <b-button
      :aria-expanded="visible ? 'true' : 'false'"
      class="help-collapse-button w-100 d-flex justify-content-between align-items-center"
      aria-controls="collapse-4"
      @click="visible = !visible"
    >
      <div>
        <HelpIcon class="fill-primary" />
        Ayuda
      </div>

      <ArrowBottomIcon class="arrow" />
    </b-button>

    <b-collapse id="collapse-4" v-model="visible">
      <slot name="content"></slot>
    </b-collapse>
  </div>
</template>

<script>
import { HelpIcon, ArrowBottomIcon } from "wize-admin";

export default {
  data() {
    return {
      visible: true
    };
  },
  components: {
    ArrowBottomIcon,
    HelpIcon,
  },
};
</script>

<style lang="stylus" scoped>
@import "../../Styles/components/help-collapse.styl"
</style>
