<template>
  <div class="dashboard-card">
    <div class="payment-details">
      <img src="@/core/assets/images/payments/yape-lg.png" alt="Logo Izipay" class="logo rounded-circle" />

      <HelpCollapse>
        <template slot="content">
          <div class="help-collapse-content">
            <p>
              Para empezar a recibir pagos con Yape sólo completa este formulario
              con el número de celular asociado a tu Yape y el código de QR de tu
              perfil.
            </p>
          </div>
        </template>
      </HelpCollapse>

      <form action="" class="custom-form mt-4" @submit.prevent="save()">
        <div class="form-group">
          <label for="holder">Nombre del titular *</label>
          <FormInput id="holder" type="text" placeholder="Ingresar nombre del titular"
            :showError="$v.form.holder.$error && !$v.form.holder.required" v-model="form.holder" />
          <form-error message="Nombre del titular es requerido"
            v-if="$v.form.holder.$error && !$v.form.holder.required"></form-error>
        </div>

        <div class="form-group">
          <label for="mobile">Número de celular *</label>
          <FormInput id="mobile" type="text" placeholder="Ingresar número de celular"
            :showError="$v.form.mobile.$error && !$v.form.mobile.required" v-model="form.mobile" />
          <form-error message="Número de celular es requerido"
            v-if="$v.form.mobile.$error && !$v.form.mobile.required"></form-error>
        </div>

        <div class="form-group">
          <label for="qrCode">Código QR</label>

          <div>
            <UploadImages :maxFiles="1" :files="form.qrCode ? [{ url: form.qrCode }] : []" @onChange="onChange"
              @remove="removeImage" />
          </div>
        </div>

        <div class="form-group">
          <label for="instructions">Instrucciones para el cliente *</label>
          <global-trumbowyg v-model="form.instructions" />
          <form-error message="Instrucciones para el cliente son requeridas"
            v-if="$v.form.instructions.$error && !$v.form.instructions.required"></form-error>
        </div>

        <div class="form-group-buttons text-right">
          <router-link :to="{ name: 'config-payment-methods' }" class="button button-light">
            Volver
          </router-link>
          <button class="button button-primary" type="submit">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Alert, FormError, FormInput, UploadImages } from "wize-admin";

import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";
import UploadImagesMixin from '@/core/mixins/UploadImagesMixin';
import HelpCollapse from "./HelpCollapse.vue";
import PaymentMethodEntityMixin from "@/core/mixins/PaymentMethodEntityMixin"

export default {
  name: "YapeForm",
  mixins: [UploadImagesMixin, PaymentMethodEntityMixin],
  data() {
    return {
      form: {
        id: "YAPE",
        holder: null,
        mobile: null,
        qrCode: null,
        instructions: null,
        isActive: false,
      },
    };
  },
  async mounted() {
    await this.load();
    this.form.id = "YAPE";
  },
  validations: {
    form: {
      holder: { required },
      mobile: { required },
      instructions: { required },
      isActive: {},
    },
  },
  components: {
    HelpCollapse,
    FormInput,
    FormError,
    UploadImages,
    GlobalTrumbowyg
  },
  methods: {
    async onChange(files) {
      if (!files.length) return

      try {
        const uploadedFiles = await this.uploadFiles(files)
        this.form.qrCode = uploadedFiles[0].url
      } catch (error) {
        Alert.error(error)
      }
    },
    removeImage() {
      this.form.qrCode = null
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '../../Styles/payment-details.styl';
</style>
