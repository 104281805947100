<template>
  <div class="page-container products-list">
    <div class="page-title-container">
      <h1 class="page-title">Productos</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar productos</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar"
                @click="download()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por descripción o ID de producto"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'products-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Agregar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Imagen</span>
            </div>
            <div>
              <span>ID producto</span>
            </div>
            <div>
              <span>Nombre de producto</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid text-center">
                <div>
                  <div
                    class="table-image cursor-pointer"
                    @click="setShowGalleryModal(true, o)"
                  >
                    <img :src="o.thumbnail" alt="" v-if="o.thumbnail" />
                  </div>
                </div>

                <div>
                  <span>{{ o.code }}</span>
                </div>

                <div>
                  <span>{{ o.name }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{ name: 'products-edit', params: { id: o.id } }"
                >
                  Editar
                </router-link>

                <a href="" @click.prevent="openChangeStatus(o)" class="mx-3">{{
                  getStatus(o.status).action
                }}</a>

                <a href="" @click.prevent="openDelete(o)">Eliminar</a>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Link:</span
                    >
                    <a :href="setUrl(o)" target="_blank">
                      <LinkIcon class="fill-gray" />
                    </a>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Categorías:</span
                    >
                    <span>{{ getCategoriesName(o.categories) }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Stock:</span
                    >
                    <span>{{ o.stock }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Cant. base:</span
                    >
                    <span>{{ o.baseQuantity }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Precio:</span
                    >
                    <span v-decimal:pen="o.price"></span>
                  </li>
                  <li>
                    <span class="font-weight-bold d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Imagen</th>
              <th class="pl-md-4">
                ID <br />
                Producto
              </th>
              <th>Nombre de producto</th>
              <th>Link</th>
              <th class="px-md-4">Categorías</th>
              <th class="pr-md-4">Stock</th>
              <th>
                Cant. <br />
                base
              </th>
              <th class="px-3">Precio</th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center pl-md-1">
                <div
                  class="table-image cursor-pointer"
                  @click="setShowGalleryModal(true, o)"
                >
                  <img :src="o.thumbnail" alt="" v-if="o.thumbnail" />
                </div>
              </td>
              <td class="text-center pl-md-3">{{ o.code }}</td>
              <td class="product-name pl-3">{{ o.name || "-" }}</td>
              <td class="text-center px-3">
                <a :href="setUrl(o)" target="_blank">
                  <LinkIcon class="fill-gray" />
                </a>
              </td>
              <td class="text-center">
                <p class="product-categories mx-auto">
                  {{ getCategoriesName(o.categories) }}
                </p>
              </td>
              <td class="text-center pr-md-3">{{ o.stock }}</td>
              <td class="text-center">{{ o.baseQuantity }}</td>
              <td class="text-center" v-decimal:pen="o.price"></td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      :to="{ name: 'products-edit', params: { id: o.id } }"
                    />
                    <DropdownItem
                      :text="getStatus(o.status).action"
                      @click="openChangeStatus(o)"
                    />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />

    <GalleryModal
      :show="showGalleryModal"
      @hide="setShowGalleryModal(false)"
      :image="selectedOption.thumbnail"
      v-if="selectedOption"
    />
  </div>
</template>

<script>
import fileDownload from "js-file-download";

import {
  Alert,
  ConfirmationModal,
  ConfirmationModalMixin,
  Constants,
  DropdownItem,
  DropdownTable,
  FilterMenu,
  FilterMixin,
  LinkIcon,
  PaginationMixin,
  SearchBar,
  Util,
} from "wize-admin";

import { ProductService } from "@/core/services";
import { Constants as LocalConstants } from "@/core/utils";
import { mapGetters } from "vuex";

import GalleryModal from "@/core/components/common/GalleryModal";

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
    LinkIcon,
    ConfirmationModal,
    GalleryModal
  },
  mixins: [PaginationMixin, FilterMixin, ConfirmationModalMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ProductsStatus.ACTIVE,
            LocalConstants.ProductsStatus.INACTIVE,
          ],
        },
        {
          label: "Categoría",
          key: "categoryIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Con stock",
          key: "hasStock",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.StockProductsValues.YES,
            LocalConstants.StockProductsValues.NOT,
          ],
        },
        {
          label: "Con Imagen",
          key: "hasImage",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ImageProductsValues.YES,
            LocalConstants.ImageProductsValues.NOT,
          ],
        },
      ],
      showGalleryModal: false
    };
  },
  methods: {
    initialize() {
      const filterOptions = this.filterOptions.find(
        (item) => item.key === "categoryIds"
      );
      filterOptions.options = this.categories.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });
    },
    async loadData() {
      try {
        this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          search: this.search,
          ...this.filters,
        };
        const response = await ProductService.list(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.ProductsStatus);
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);

        let status = this.selectedOption.status;

        if (status == LocalConstants.ProductsStatus.ACTIVE.key) {
          status = LocalConstants.ProductsStatus.INACTIVE.key;
        } else if (status == LocalConstants.ProductsStatus.INACTIVE.key) {
          status = LocalConstants.ProductsStatus.ACTIVE.key;
        }
        await ProductService.toggleStatus(this.selectedOption.id, status);
        await this.loadData();
        Alert.success("Estado actualizado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado del producto?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteItem() {
      try {
        await this.$store.dispatch("app/loading", true);
        await ProductService.delete(this.selectedOption.id);
        await this.loadData();
        Alert.success("Producto eliminado");
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getCategoriesName(categories) {
      if (!categories.length) return "-";
      return categories
        .filter((c) => c.depth === 0)
        .map((item) => item.name)
        .join(", ");
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteItem();
      else if (this.confirmationModal.action === "update") this.changeStatus();
    },
    setShowGalleryModal(value, item) {
      if (value && item) {
        this.selectedOption = item;
      } else {
        this.selectedOption = null;
      }

      this.showGalleryModal = value;
    },
    setUrl(item) {
      let url = `${process.env.VUE_APP_WEB_URL}/p/${item.slug}`;

      return url;
    },
    async download() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          search: this.search,
          ...this.filters,
        };
        const response = await ProductService.downloadProductList(params);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: "app/getCategories",
    }),
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.$store.dispatch("app/loadCategories");
    this.initialize();
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/products-list.styl';
</style>
