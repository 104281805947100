import { ConfigurationService, CategoryService, AttributeService, SupplierService, PlanService } from "@/core/services";

const state = {
  menuOpen: true,
  currentSubmenu:
    localStorage.getItem("currentSubmenu") === null
      ? []
      : localStorage.getItem("currentSubmenu").split(","),
  loading: false,
  storeConfig: [],
  currentPlan: 'BASIC',
  categories: null,
  attributes: null,
  suppliers: null,
  productMaxImages: null,
  currentDevice: null
};

const getters = {
  getCurrentSubmenu: (state) => state.currentSubmenu,
  isMenuOpen: (state) => state.menuOpen,
  isLoading: (state) => state.loading,
  getConfValue(state) {
    return (key) => {
      let item = state.storeConfig.find((item) => item.identifier == key);

      if (item) {
        return item.value;
      }

      return null;
    };
  },
  getCurrentPlan: (state) => state.currentPlan,
  getStoreConfig(state) {
    return state.storeConfig
  },
  getCategories: (state) => state.categories ?? [],
  getAttributes: (state) => state.attributes ?? [],
  getSuppliers: (state) => state.suppliers ?? [],
  getProductMaxImages: (state) => state.productMaxImages ?? 1,
  getCurrentDevice: (state) => state.currentDevice
};

const actions = {
  loading: (store, val) => {
    store.commit("setLoading", val);
  },
  loadStoreConfig: ({ commit }) => {
    return new Promise((resolve, reject) => {
      ConfigurationService.list()
        .then((response) => {
          let data = JSON.parse(JSON.stringify(response.payload));
          if (data) commit("setStoreConfig", data);
          else commit("setStoreConfig", []);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  loadCategories: ({ commit }, params = { sort: "id,asc", }) => {
    return new Promise((resolve, reject) => {
      if (!state.categories) {
        CategoryService.list(params)
          .then((response) => {
            let data = JSON.parse(JSON.stringify(response.payload));
            commit("setCategories", data);
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  },
  loadAttributes: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (!state.attributes) {
        AttributeService.list({ size: 100, sort: "id,asc" })
          .then((response) => {
            let data = JSON.parse(JSON.stringify(response.payload.content));
            commit("setAttributes", data);
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  },
  loadSuppliers: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (!state.suppliers) {
        SupplierService.list({ size: 100, sort: "id,asc", isActive: true })
          .then((response) => {
            let data = JSON.parse(JSON.stringify(response.payload.content));
            commit("setSuppliers", data);
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  },
  loadProductMaxImages: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (!state.productMaxImages) {
        PlanService.getMaxProductImages()
          .then((response) => {
            const data = response.payload;
            commit("setProductMaxImages", Number(data.value));
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  },
};

const mutations = {
  setCurrentSubmenu(state, value) {
    const values = state.currentSubmenu;
    state.currentSubmenu = values.includes(value)
      ? values.filter((e) => e !== value)
      : [...values, value];
    localStorage.setItem("currentSubmenu", state.currentSubmenu);
  },
  toggleMenu(state, value) {
    state.menuOpen = value
  },
  setMenu(state, value) {
    state.menuOpen = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setStoreConfig(state, data) {
    state.storeConfig = data;
  },
  setCurrentPlan(state, data) {
    state.currentPlan = data;
  },
  setCategories(state, data) {
    state.categories = data;
  },
  setAttributes(state, data) {
    state.attributes = data;
  },
  setSuppliers(state, data) {
    state.suppliers = data;
  },
  setProductMaxImages(state, data) {
    state.productMaxImages = data;
  },
  setCurrentDevice(state, value) {
    state.currentDevice = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
