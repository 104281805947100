<template>
  <form
    class="
      general-configuration
      custom-form
      h-100
      d-flex
      flex-column
      animate__animated animate__fadeIn
    "
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Redes sociales</h2>

    <div class="form-container">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="facebook">Facebook</label>
        </div>

        <div class="form-field">
          <FormInput
            id="facebook"
            type="text"
            placeholder="Ingresar Facebook"
            v-model="form[0].value"
          />

          <!-- <form-error
          message="Facebook"
          v-if="$v.form.facebook.$error && !$v.form.facebook.required"
        ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="instagram">Instagram</label>
        </div>

        <div class="form-field">
          <FormInput
            id="instagram"
            type="text"
            placeholder="Ingresar instagram"
            v-model="form[1].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="twitter">Twitter</label>
        </div>

        <div class="form-field">
          <FormInput
            id="twitter"
            type="text"
            placeholder="Ingresar twitter"
            v-model="form[2].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="youtube">Youtube</label>
        </div>

        <div class="form-field">
          <FormInput
            id="youtube"
            type="text"
            placeholder="Ingresar youtube"
            v-model="form[3].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="pinterest">Pinterest</label>
        </div>

        <div class="form-field">
          <FormInput
            id="pinterest"
            type="text"
            placeholder="Ingresar pinterest"
            v-model="form[4].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container">
          <label for="tiktok">Tiktok</label>
        </div>

        <div class="form-field">
          <FormInput
            id="tiktok"
            type="text"
            placeholder="Ingresar tiktok"
            v-model="form[5].value"
          />
        </div>
      </div>
    </div>

    <div class="form-container border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="facebook">Marketplaces</label>
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="falabella">Falabella</label>
        </div>

        <div class="form-field">
          <FormInput
            id="falabella"
            type="text"
            placeholder="Ingresar link a Falabella"
            v-model="form[6].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="ripley">Ripley</label>
        </div>

        <div class="form-field">
          <FormInput
            id="ripley"
            type="text"
            placeholder="Ingresar link a Ripley"
            v-model="form[7].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="rappi">Rappi</label>
        </div>

        <div class="form-field">
          <FormInput
            id="rappi"
            type="text"
            placeholder="Ingresar link a Rappi"
            v-model="form[8].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="juntoz">Juntoz</label>
        </div>

        <div class="form-field">
          <FormInput
            id="juntoz"
            type="text"
            placeholder="Ingresar link a Juntoz"
            v-model="form[9].value"
          />
        </div>
      </div>
    </div>

    <div class="form-group-buttons text-right mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { BiolinkConfigurationService } from "@/core/services";
// import { required } from "vuelidate/lib/validators";

import { Alert, FormInput } from "wize-admin";

export default {
  data() {
    return {
      // form: {
      //   facebook: null,
      //   instagram: null,
      //   twitter: null,
      //   youtube: null,
      //   pinterest: null,
      //   tiktok: null
      // },
      form: [
        {
          id: "FACEBOOK_URL",
          value: null,
        },
        {
          id: "INSTAGRAM_URL",
          value: null,
        },
        {
          id: "TWITTER_URL",
          value: null,
        },
        {
          id: "YOUTUBE_URL",
          value: null,
        },
        {
          id: "PINTEREST_URL",
          value: null,
        },
        {
          id: "TIKTOK_URL",
          value: null,
        },
        {
          id: "FALABELLA_URL",
          value: null,
        },
        {
          id: "RIPLEY_URL",
          value: null,
        },
        {
          id: "RAPPI_URL",
          value: null,
        },
        {
          id: "JUNTOZ_URL",
          value: null
        }
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  components: {
    FormInput,
  },
  validations: {
    form: {
      // facebook: { required },
      // whatsapp: { required, numeric },
      // description: { required }
    },
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            id: item.id,
            value: item.value ? item.value : "",
          };
        });

        const resp = await BiolinkConfigurationService.save(params);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await BiolinkConfigurationService.list();
        let data = response.payload;
        this.form.forEach((item) => {
          let field = data.find((d) => d.id == item.id);
          if (field) {
            item.value = field.value;
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
</style>
