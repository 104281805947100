<template>
  <div id="container" class="general-config page-container">
    <div class="page-title-container">
      <h1 class="page-title">Configuración</h1>
    </div>

    <section
      class="tabs-container d-flex flex-column flex-md-row justify-content-center"
    >
      <div
        class="tabs-sidebar d-flex flex-column"
        :class="{ header: isHeader, theme: isThemeColor }"
      >
        <router-link
          :to="{ name: $route.name, query: { tab: item.tab } }"
          v-for="item in tabs"
          :key="item.id"
          class="tab-link text-decoration-none"
          :class="{
            active:
              item.tab === $route.query.tab ||
              (item.id == 1 && !$route.query.tab),
          }"
          >{{ item.text }}</router-link
        >
      </div>

      <div
        class="tabs-content"
        :class="{ header: isHeader, theme: isThemeColor }"
      >
        <GeneralConfig v-if="isGeneral" />

        <OrdersConfig v-if="isOrders" />

        <HeaderConfig v-if="isHeader" />

        <BannerConfig v-if="isBanner" />

        <FooterConfig v-if="isFooter" />

        <ThemeColorConfig v-if="isThemeColor" />

        <SocialNetworksConfig v-if="isSocialNetworks" />

        <JsCodesConfig v-if="isJsCodes" />
      </div>
    </section>
  </div>
</template>

<script>

import GeneralConfig from "../Components/GeneralConfig";
import OrdersConfig from "../Components/OrdersConfig";
import HeaderConfig from "../Components/HeaderConfig";
import FooterConfig from "../Components/FooterConfig";
import ThemeColorConfig from "../Components/ThemeColorConfig";
import SocialNetworksConfig from "../Components/SocialNetworksConfig";
import JsCodesConfig from "../Components/JsCodesConfig";
import BannerConfig from '../Components/BannerConfig'

export default {
  data() {
    return {
      tabs: [
        {
          id: 1,
          text: "Información general",
          tab: "general",
        },
        {
          id: 2,
          text: "Pedidos",
          tab: "orders",
        },
        {
          id: 3,
          text: "Header",
          tab: "header",
        },
        {
          id: 4,
          text: "Banner",
          tab: "banner",
        },
        {
          id: 5,
          text: "Footer",
          tab: "footer",
        },
        {
          id: 6,
          text: "Color de tema",
          tab: "theme",
        },
        {
          id: 7,
          text: "Redes sociales",
          tab: "social-networks",
        },
        {
          id: 8,
          text: "Códigos javascript",
          tab: "js-codes",
        }
      ],
    };
  },
  components: {
    GeneralConfig,
    OrdersConfig,
    HeaderConfig,
    BannerConfig,
    FooterConfig,
    ThemeColorConfig,
    SocialNetworksConfig,
    JsCodesConfig
  },
  computed: {
    isGeneral() {
      return this.$route.query.tab == "general" || !this.$route.query.tab;
    },
    isOrders() {
      return this.$route.query.tab == "orders"
    },
    isHeader() {
      return this.$route.query.tab == "header";
    },
    isBanner() {
      return this.$route.query.tab == "banner";
    },
    isFooter() {
      return this.$route.query.tab == "footer";
    },
    isThemeColor() {
      return this.$route.query.tab == "theme";
    },
    isSocialNetworks() {
      return this.$route.query.tab == "social-networks";
    },
    isJsCodes() {
      return this.$route.query.tab == "js-codes"
    }
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/general-config-page.styl"
</style>
