<template>
  <div class="page-container attribute-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar atributo" : "Nuevo atributo" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing ? "Datos del atributo" : "Datos del nuevo atributo"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="type">Tipo de atributo *</label>

                        <FormSelect
                          id="type"
                          v-model="attribute.type"
                          :items="atributesTypes"
                          :showError="$v.attribute.type.$error"
                          defaultOption="Elegir tipo de atributo"
                        />

                        <form-error
                        message="Tipo de atributo es requerido"
                        v-if="
                          $v.attribute.type.$error &&
                          !$v.attribute.type.required
                        "
                      ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-0">
                        <label for="name">Nombre *</label>

                        <FormInput
                          id="name"
                          type="text"
                          :show-error="$v.attribute.name.$error && !$v.attribute.name.required"
                          placeholder="Ingresar nombre"
                          v-model="attribute.name"
                        />

                        <form-error
                          message="Nombre es requerido"
                          v-if="
                            $v.attribute.name.$error && !$v.attribute.name.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'config-attributes-list' }"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { Alert, FormInput, FormSelect, Constants } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { AttributeService } from "@/core/services";

export default {
  data() {
    return {
      atributesTypes: [
        LocalConstants.ProductsAttributes.LIST
      ],
      showMap: false,
      previewDesktop: null,
      previewMobile: null,
      attribute: {
        name: null,
        type: null,
      },
      editing: false,
    };
  },
  validations: {
    attribute: {
      type: { required },
      name: { required }
    }
  },
  components: {
    FormInput,
    FormSelect,
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  computed: {},
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true)

      const data = { ...this.attribute };

      try {
        let response;
        if (!this.editing) response = await AttributeService.save(data);
        else
          response = await AttributeService.update(
            this.$route.params.id,
            data
          );

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-attributes-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
        this.$store.commit('app/setAttributes', null)
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false)
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await AttributeService.get(id);
        this.attribute = { ...response.payload };
        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado") message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({ name: "config-attributes-list" }));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
</style>
