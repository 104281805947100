<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="modal-payment-plans"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <h2 class="modal-title">Planes de pago</h2>

      <button type="button" class="bg-transparent border-0" @click="hide()">
        <CloseModalIcon class="fill-white" />
      </button>
    </div>

    <section class="modal-main-content">
      <div class="modal-cards">
        <article class="modal-card">
          <div class="modal-card-header">
            <h4 class="modal-card-subtitle my-0">Plan gratis</h4>
            <h3 class="modal-card-title">Sin costo mensual</h3>
          </div>

          <div class="modal-card-body">
            <ul class="modal-list p-0 list-unstyled">
              <li class="d-flex align-items-start justify-content-start">
                <span>
                  <CartIcon class="fill-primary list-icon" />
                </span>
                <p class="modal-card-description my-0 ml-2">
                  Libre de costo, 0% de comisión, máximo de 50 productos y 100
                  pedidos mensuales
                </p>
              </li>

              <li class="d-flex align-items-start justify-content-start">
                <span>
                  <CloseModalIcon class="fill-gray-4 list-icon x-icon" />
                </span>
                <p class="modal-card-description disabled my-0 ml-2">
                  Códigos de descuento, Tienda en facebook, Productos en
                  Instagram y Enlace Shorby
                </p>
              </li>

              <li class="active d-flex align-items-start justify-content-start">
                <span>
                  <CheckIcon class="fill-primary list-icon check-icon" />
                </span>
                <div class="ml-2">
                  <p
                    class="modal-card-description h-auto text-primary-dark font-weight-bold my-0"
                  >
                    Se incluyen en todos los planes
                  </p>
                  <p class="modal-card-description text-primary-dark mt-2 mb-0">
                    Libro de reclamaciones, Carrito de compras, Certificado SSL
                    gratis, Asistencia y mucho más
                  </p>
                </div>
              </li>
            </ul>

            <router-link to="/" class="modal-card-link d-block text-center">
              Ver todos los detalles del plan
              <LinkIcon class="link-icon fill-primary" />
            </router-link>
          </div>

          <div class="modal-card-footer text-center">
            <p class="current-plan my-0">Plan actual</p>
          </div>
        </article>

        <article class="modal-card">
          <div class="modal-card-header">
            <h4 class="modal-card-subtitle my-0">Plan 1</h4>
            <h3 class="modal-card-title">S/ 99 mensuales</h3>
          </div>

          <div class="modal-card-body">
            <ul class="modal-list p-0 list-unstyled">
              <li class="d-flex align-items-start justify-content-start">
                <span>
                  <StoreIcon class="fill-primary list-icon" />
                </span>
                <p class="modal-card-description my-0 ml-2">
                  Códigos de descuento, Tienda en facebook, Productos en Instagram y Enlace Shorby
                </p>
              </li>

              <li class="d-flex align-items-start justify-content-start">
                <span>
                  <MailIcon class="fill-primary list-icon" />
                </span>
                <p class="modal-card-description my-0 ml-2">
                  Notificaciones Push y Capacidad de generar e-mail marketing
                </p>
              </li>

              <li class="active d-flex align-items-start justify-content-start">
                <span>
                  <CheckIcon class="fill-primary list-icon check-icon" />
                </span>
                <div class="ml-2">
                  <p
                    class="modal-card-description h-auto text-primary-dark font-weight-bold my-0"
                  >
                    Se incluyen en todos los planes
                  </p>
                  <p class="modal-card-description text-primary-dark mt-2 mb-0">
                    Libro de reclamaciones, Carrito de compras, Certificado SSL
                    gratis, Asistencia y mucho más
                  </p>
                </div>
              </li>
            </ul>

            <router-link to="/" class="modal-card-link d-block text-center">
              Ver todos los detalles del plan
              <LinkIcon class="link-icon fill-primary" />
            </router-link>
          </div>

          <div class="modal-card-footer text-center">
            <router-link to="/" class="button button-primary text-decoration-none">Seleccionar plan</router-link>
          </div>
        </article>
      </div>

      <div class="text-center">
        <router-link to="/" class="button button-primary text-decoration-none"
          >Ver todos los planes</router-link
        >
      </div>
    </section>
  </b-modal>
</template>
<script>
import {
  ModalMixin,
  CloseModalIcon,
  CartIcon,
  CheckIcon,
  LinkIcon,
  StoreIcon,
  MailIcon
} from "wize-admin";

export default {
  components: { CloseModalIcon, CartIcon, CheckIcon, LinkIcon, StoreIcon, MailIcon },
  name: "ConfirmationModal",
  mixins: [ModalMixin],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="stylus">
@import "../../styles/components/modal-payment-plans.styl"
</style>
