<template>
  <div class="dashboard-page">
    <section class="dashboard-banner">
      <h1 class="dashboard-title">{{ getStoreTradeName }}</h1>

      <IncomeList />

      <section class="dashboard-container pt-4">
        <DashboardAlert />
      </section>
    </section>
  </div>
</template>

<script>

import ConfigMixin from "@/core/mixins/ConfigMixin";

import DashboardAlert from "../Components/DashboardAlert.vue";
import IncomeList from "../Components/IncomeList.vue";

export default {
  mixins: [ConfigMixin],
  name: "DashboardPage",
  async mounted() {
    await this.getStoreConfig()
  },
  components: {
    DashboardAlert,
    IncomeList
  }
};
</script>

<style lang="stylus">
@import "../Styles/dashboard-page.styl"
</style>
