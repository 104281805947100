<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Descuentos</h1>
    </div>
    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />
            <div>Filtrar Series</div>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}
            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <section class="d-flex align-items-center">
          <ButtonWithTooltip
            text="Descargar"
            tooltip-variant="download-tooltip"
          >
            <template slot="icon">
              <DownloadTableIcon />
            </template>
          </ButtonWithTooltip>

          <SearchBar
            placeholder="Buscar por código o descripción"
            @search="onSearch"
            @input="search = $event"
          />
          <b-button
            :to="{ name: 'config-serie-create' }"
            variant="none"
            class="button button-primary"
            >Agregar serie</b-button
          >
        </section>
      </div>
      <div class="table-responsive mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descripción</th>
              <th>Tipo de movimiento</th>
              <th>Estado</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ o.code }}</td>
              <td class="pl-5">{{ o.description }}</td>
              <td class="text-center"></td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      @click="
                        $router.push({
                          name: 'config-serie-edit',
                          params: { serieId: o.id },
                        })
                      "
                      text="Editar"
                    />
                    <DropdownItem
                      :text="getStatus(o.status).action"
                      @click="openChangeStatus(o)"
                    />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  ConfirmationModalMixin,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ButtonWithTooltip,
  DownloadTableIcon,
  ConfirmationModal,
  FilterMenu,
  FiltersIcon,
  DeleteFilterIcon
} from "wize-admin";

export default {
  components: {
    SearchBar,
    ButtonWithTooltip,
    FilterMenu,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
    DownloadTableIcon,
    FiltersIcon,
    DeleteFilterIcon
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [

      ],
    };
  },
  methods: {
    async loadData() {
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteSerie();
      else if (this.confirmationModal.action === "update") this.changeStatus();
    },
    async changeStatus() {
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado del descuento?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteSerie() {
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
