<template>
  <div class="payment-details">
    <img src="@/core/assets/images/payments/culqi-lg.png" alt="Logo Culqi" class="logo rounded-circle" />

    <HelpCollapse>
      <template slot="content">
        <div class="help-collapse-content">
          <p>
            Para empezar a recibir pagos a través de Culqi, por favor completa el siguiente formulario.
          </p>
          <p>
            Por motivos de seguridad, cuando vuelvas a ingresar a este formulario, no verás cargada la información sensible y deberás actualizarla nuevamente en caso haya cambiado.
          </p>
        </div>
      </template>
    </HelpCollapse>

    <form action="" class="custom-form mt-4" @submit.prevent="save()">
      <div class="form-group">
        <label for="publicKey">Llave pública (PK) *</label>
        <FormInput id="publicKey" type="text" placeholder="Ingresar llave pública" :showError="$v.form.publicKey.$error && !$v.form.publicKey.required" v-model="form.publicKey" />
        <form-error message="Llave pública (PK) requerido"
          v-if="$v.form.publicKey.$error && !$v.form.publicKey.required"></form-error>
      </div>

      <div class="form-group">
        <label for="secretKey">Llave privada (SK) *</label>
        <FormInput id="secretKey" type="text" placeholder="Ingresar llave privada" :showError="$v.form.secretKey.$error && !$v.form.secretKey.required" v-model="form.secretKey" />
        <form-error message="Llave privada (SK)"
          v-if="$v.form.secretKey.$error && !$v.form.secretKey.required"></form-error>
      </div>

      <div class="form-group">
        <label for="env">Entorno *</label>
        <div class="d-flex">
          <b-form-radio v-model="form.env" name="enviroment" value="PRODUCTION">Producción</b-form-radio>
          <b-form-radio v-model="form.env" name="enviroment" value="INTEGRATION" class="ml-2">Integración</b-form-radio>
        </div>
      </div>

      <div class="form-group-buttons text-right">
        <router-link :to="{ name: 'config-payment-details', params: { slug: slug } }" class="button button-light">
          Volver
        </router-link>
        <button class="button button-primary" type="submit">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { FormInput, FormError } from "wize-admin";
import HelpCollapse from "./HelpCollapse.vue";
import PaymentMethodEntityMixin from "@/core/mixins/PaymentMethodEntityMixin"


export default {
  mixins: [PaymentMethodEntityMixin],
  data() {
    return {
      slug: this.$route.params.slug,
      form: {
        id: 'CULQI',
        publicKey: null,
        secretKey: null,
        isActive: false,
        env: "INTEGRATION",
      }
    };
  },
  async mounted() {
    await this.load();
    this.form.id = "CULQI";
  },
  validations: {
    form: {
      publicKey: { required },
      secretKey: { required }
    },
  },
  components: {
    HelpCollapse,
    FormInput,
    FormError
  }
};
</script>

<style lang="stylus" scoped>
@import "../../Styles/payment-details.styl"
</style>
