import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/admin/product", { params });
  },
  get(id) {
    return http.get(`/api/v1/admin/product/${id}`);
  },
  listAll() {
    return http.get(`/api/v1/admin/product/all`);
  },
  save(payload) {
    return http.post("/api/v1/admin/product", payload);
  },
  update(id, payload) {
    return http.put(`/api/v1/admin/product/${id}`, payload);
  },
  toggleStatus(id, status) {
    return http.put(`/api/v1/admin/product/${id}/status`, null, {
      params: {
        status
      },
    });
  },
  delete(id) {
    return http.delete(`/api/v1/admin/product/${id}`);
  },
  downloadProductList(params) {
    return http.get('/api/v1/admin/product/xlsx', {
      responseType: 'blob',
      params
    })
  }
};
