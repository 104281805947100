<template>
  <div class="dashboard-alert" v-if="showAlert && !localLoading">
    <span class="font-weight-bold"
      ><WarningAlertIcon class="warning-icon mr-1" /> ¡IMPORTANTE!</span
    >

    <p>Recuerde configurar correctamente las siguientes acciones:</p>

    <div class="mt-2">
      <p class="m-0" v-if="showEmailMessage">
        1-
        <a href="#" @click.prevent="toConfig()">Configurar correo electrónico</a>
        para permitir el registro de usuarios en su tienda, entre otras
        acciones.
      </p>

      <p class="mt-1 mb-0 mx-0" v-if="!deliveryList.length">
        2-
        <router-link :to="{name: 'config-delivery-list'}"
          >Configurar ubicaciones disponibles</router-link
        >
        para entregas y envíos.
      </p>
    </div>
  </div>
</template>

<script>
import { Alert, WarningAlertIcon } from "wize-admin";

import { ConfigurationService, DeliveryService } from "@/core/services";
import { mapGetters } from "vuex";

export default {
  name: "DashboardAlert",
  data() {
    return {
      config: [],
      deliveryList: [],
      localLoading: true
    };
  },
  async mounted() {
    await this.loadConfig();
    await this.loadDeliveryList();

    this.localLoading = false
  },
  components: {
    WarningAlertIcon,
  },
  methods: {
    async loadConfig() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await ConfigurationService.list();
        this.config = response.payload;
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async loadDeliveryList() {
      try {
        const response = await DeliveryService.list();
        this.deliveryList = response.payload;
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getConfValue(identifier) {
      return this.config.find((item) => item.identifier == identifier);
    },
    toConfig() {
      this.$router.push({ name: "general-configuration", query: {email: true} });

      setTimeout(() => {
        this.$smoothScroll({
          scrollTo: document.querySelector("#email-container"),
          updateHistory: false,
          offset: -120
        });
      }, 700);
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading'
    }),
    showAlert() {
      let status = false;
      let emailConfig = this.getConfValue("EMAIL")

      if (emailConfig) status = emailConfig.value == "";

      if (!this.deliveryList.length) status = true;

      return status;
    },
    showEmailMessage() {
      return this.getConfValue('EMAIL') && this.getConfValue('EMAIL').value == ''
    }
  },
};
</script>

<style lang="stylus">
@import "../Styles/dashboard-alert.styl"
</style>
