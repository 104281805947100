import LoginView from "../LoginView.vue";
import LoginPage from "../Pages/LoginPage.vue";
import ResetPasswordPage from "../Pages/ResetPasswordPage.vue";

const routes = [
  {
    path: '/login',
    component: LoginView,
    children: [
      {
        path: '',
        name: 'login',
        component: LoginPage,
        meta: {excludeAuth: true}
      }
    ]
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPasswordPage,
    meta: {excludeAuth: true}
  }
]

export default routes
