<template>
  <div class="quotes quotes-table custom-form m-0" v-if="list.length">
    <div class="table-responsive">
      <table class="custom-table w-100">
        <thead>
          <tr>
            <th>Imagen</th>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Stock</th>
            <th class="px-3">Precio unitario</th>
            <th class="px-3">Valor</th>
            <th class="pr-md-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, i) in list" :key="o.id">
            <td class="text-center pl-md-4">
              <div class="table-image cursor-pointer">
                <img :src="o.thumbnail" alt="" v-if="o.thumbnail" />
              </div>
            </td>
            <td class="product-name pl-3">{{ o.name || "-" }}</td>
            <td class="text-center">
              <FormInput
                type="number"
                :max="o.stock"
                placeholder="Ingresar"
                :show-error="
                  validationList &&
                  !validationList.$each[i].quantity.required &&
                  validationList.$each[i].quantity.$error
                "
                v-model="o.quantity"
                class="product-quantity"
                tabindex="1"
              />

              <form-error
                message="Campo requerido"
                v-if="
                  validationList &&
                  !validationList.$each[i].quantity.required &&
                  validationList.$each[i].quantity.$error
                "
              ></form-error>
            </td>
            <td class="text-center">{{ o.stock }}</td>
            <td class="text-center">
              <FormInput
                type="number"
                placeholder="Ingresar"
                :show-error="
                  validationList &&
                  !validationList.$each[i].price.required &&
                  validationList.$each[i].price.$error
                "
                v-model="o.price"
                class="product-quantity"
                tabindex="1"
              />

              <form-error
                message="Campo requerido"
                v-if="
                  validationList &&
                  !validationList.$each[i].price.required &&
                  validationList.$each[i].price.$error
                "
              ></form-error>
            </td>
            <td class="text-center" v-decimal:pen="o.price * o.quantity"></td>
            <td class="text-center custom-form d_option pl-md-5 pr-md-4">
              <button
                type="button"
                class="remove-item bg-danger rounded-circle border-0 d-flex justify-content-center align-items-center"
                @click="$emit('remove-selection', o)"
              >
                <TrashIcon class="fill-white trash-icon" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="details d-flex align-items-center justify-content-end mt-2 py-2 px-5"
    >
      <p class="details-item my-0 pr-4">
        Cantidad de items:
        <span class="text-color-dark font-weight-bold">{{ list.length }}</span>
      </p>

      <h3 class="font-sm details-item details-item-total my-0 pl-4">
        Total:
        <span
          class="text-color-dark font-weight-bold"
          v-decimal:pen="total"
        ></span>
      </h3>
    </div>
  </div>
</template>

<script>
import { FormInput, TrashIcon, FormError } from "wize-admin";

export default {
  name: "ProductQuoteTable",
  data() {
    return {
      $v: null,
    };
  },
  props: {
    list: Array,
    validationList: Object,
  },
  components: {
    FormInput,
    TrashIcon,
    FormError,
  },
  computed: {
    total() {
      let total = 0;
      this.list.forEach((item) => {
        total += item.price * item.quantity;
      });

      return total;
    },
  },
};
</script>

<style lang="stylus">
@import '../Styles/products-quote-form.styl';
</style>
