<template>
  <section class="dashboard-container">
    <div class="dashboard-card payment-methods-card">
      <div
        class="payment-methods-card-header d-flex justify-content-between align-items-center"
      >
        <h2 class="subtitle">Pasarelas de pago</h2>

        <div class="options text-center">
          <h4 class="subtitle-2">Habilitar</h4>
          <h4 class="subtitle-2">Editar</h4>
        </div>
      </div>

      <div
        class="payment-item text-decoration-none d-flex justify-content-between align-items-center"
        :class="{ last: index === paymentMethodsList.length - 1 }"
        v-for="(item, index) in paymentMethodsList"
        :key="item.key"
      >
        <div class="image-container d-md-flex">
          <img
            :src="require(`@/core/assets/images/payments/${item.logo}`)"
            :alt="item.label"
            class="img-fluid"
          />
        </div>

        <div class="options">
          <b-form-checkbox
            class="ml-2"
            v-model="item.isActive"
            :disabled="item.disabled"
            switch
            @change="updatePaymentMethodActive($event, item)"
          >
          </b-form-checkbox>

          <div>
            <router-link
              :to="{
                name: 'config-payment-details-entity',
                params: { slug, entitySlug: item.key },
              }"
              class="button button-primary text-white">
            Editar
            </router-link>
          </div>
        </div>
      </div>

      <div class="payment-methods-card-footer d-flex justify-content-end align-items-center">
        <button
          @click="$router.push({ name: 'config-payment-methods' })"
          class="button button-light"
        >
          Volver
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { Constants as LocalConstants } from "@/core/utils";

import PaymentMethodsMixin from "@/core/mixins/PaymentMethodsMixin"

export default {
  name: "PayOnlineList",
  mixins: [PaymentMethodsMixin],
  data() {
    return {
      slug: this.$route.params.slug,
      innerList: true,
      paymentMethodsList: [
        {
          ...LocalConstants.PaymentMethods.IZIPAY,
          switch: true,
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.NIUBIZ,
          switch: true,
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.CULQI,
          switch: true,
          isActive: false
        }
      ],
      serverList: [],
    };
  },
  async mounted() {
    await this.loadData();
  }
};
</script>

<style lang="stylus" scoped>
@import '../../Styles/payment-methods.styl';
</style>
