<template>
  <div class="login d-flex justify-content-center align-items-center">
    <div class="container animate__animated animate__fadeIn">
      <section class="row justify-content-center">
        <div class="col-11 col-md-6 text-center">
          <b-card class="login-card mx-auto border-0 py-4">
            <LogoWizeBlue />
            <h1 class="title">¡Bienvenido!</h1>
            <h2 class="subtitle">
              Accede para descubrir la magia
            </h2>
            <FormLogin @reset="showFormReset = true"/>
          </b-card>

          <section class="text-center new-account">
            <p>© {{ currentYear }} Forjado por <a href="https://wize.pe" target="_blank">Wize</a></p>
          </section>

          <RecoverPasswordModal @hide="showFormReset = false" :show="showFormReset"/>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FormLogin from '../Components/FormLogin'
import RecoverPasswordModal from '../Components/RecoverPasswordModal'

import { LogoWizeBlue } from 'wize-admin'

export default {
  name: "LoginView",
  components: {
    FormLogin,
    RecoverPasswordModal,
    LogoWizeBlue
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showFormReset: false
    };
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/login-page";
</style>
