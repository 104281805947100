<template>
  <div class="page-container store-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar tienda" : "Nueva tienda" }}
      </h1>
    </div>

    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing ? "Datos de la tienda" : "Datos de la nueva tienda"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle">Información general</h2>
                  <p class="custom-card-description">
                    Ingresa los datos
                    {{ editing ? "de la tienda" : "de la nueva tienda" }}
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-0">
                        <label for="name">Nombre de tienda *</label>

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar nombre de tienda"
                          :show-error="
                            $v.store.name.$error && !$v.store.name.required
                          "
                          v-model="store.name"
                        />

                        <form-error
                          message="Nombre es requerido"
                          v-if="$v.store.name.$error && !$v.store.name.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Fotos de la tienda</h2>

                  <p class="custom-card-description">
                    Ingresa máximo 3 imágenes
                  </p>

                  <UploadImages
                    :maxFiles="3"
                    :files="store.images"
                    @onChange="onChange"
                    @remove="removeImage"
                  />
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Horarios de atención</h2>

                  <p class="custom-card-description">
                    Ingresa los datos de los horarios de atención
                  </p>

                  <div class="schedules-form custom-form d-xl-none">
                    <div
                      class="schedules-form-section mb-3"
                      v-for="(item, index) in store.openingHours"
                      :key="index"
                    >
                      <div>
                        <label>Días de atención</label>

                        <SelectDays
                          class="select-delivery-days"
                          :oldSelection="item.days"
                          :daysList="daysList"
                          @selected="selectedDays($event, index)"
                        ></SelectDays>
                      </div>

                      <div class="mt-2">
                        <label>Rango de horario</label>

                        <div class="d-flex justify-content-between">
                          <SelectTimeRange
                            :options="timeRangeOptions"
                            :oldValue="store.openingHours[index].startHour"
                            class="select-time-range mr-2"
                            @selected="setTimeRange($event, index, 'startHour')"
                          />

                          <SelectTimeRange
                            :options="timeRangeOptions"
                            :oldValue="store.openingHours[index].endHour"
                            @selected="setTimeRange($event, index, 'endHour')"
                            class="select-time-range ml-2"
                          />
                        </div>
                      </div>

                      <div class="mt-2 w-full d-flex justify-content-end">
                        <button
                          type="button"
                          class="remove-item bg-danger rounded-circle border-0 d-flex justify-content-center align-items-center"
                          @click="removeItem(index)"
                          v-if="index != 0"
                        >
                          <TrashIcon class="fill-white trash-icon" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Desktop -->
                  <div class="d-none d-xl-block">
                    <table class="custom-table schedule-table w-100">
                      <thead>
                        <tr>
                          <th>Días de atención</th>
                          <th>Rango de horario</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in store.openingHours"
                          :key="index"
                          class="py-4"
                        >
                          <td class="text-center py-2 pl-3">
                            <SelectDays
                              class="select-delivery-days"
                              :oldSelection="item.days"
                              :daysList="daysList"
                              @selected="selectedDays($event, index)"
                            ></SelectDays>
                          </td>

                          <td>
                            <div class="d-flex justify-content-center py-2">
                              <SelectTimeRange
                                :options="timeRangeOptions"
                                :oldValue="store.openingHours[index].startHour"
                                class="mr-2"
                                @selected="
                                  setTimeRange($event, index, 'startHour')
                                "
                              />

                              <SelectTimeRange
                                :options="timeRangeOptions"
                                :oldValue="store.openingHours[index].endHour"
                                @selected="
                                  setTimeRange($event, index, 'endHour')
                                "
                                class="ml-2"
                              />
                            </div>
                          </td>

                          <td class="px-2">
                            <button
                              type="button"
                              class="remove-item bg-danger rounded-circle border-0 d-flex justify-content-center align-items-center"
                              @click="removeItem(index)"
                              v-if="index != 0"
                            >
                              <TrashIcon class="fill-white trash-icon" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div v-if="errorOpeningHours">
                    <form-error
                      message="Todos los campos son requeridos"
                    ></form-error>
                  </div>

                  <div
                    class="add-item d-flex justify-content-end align-items-center mt-4"
                  >
                    <a href="" @click.prevent="addOpeningHoursItem()">
                      <PlusIcon class="fill-primary mr-2" />
                      Agregar horarios
                    </a>
                  </div>
                </div>

                <div class="form-section border-0">
                  <h2 class="custom-card-subtitle">Dirección de la tienda</h2>

                  <p class="custom-card-description">
                    Ingresa los datos de la ubicación de la tienda
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="address">Dirección *</label>

                        <FormInput
                          id="address"
                          type="text"
                          placeholder="Ingresar dirección"
                          :show-error="
                            $v.store.address.$error &&
                            !$v.store.address.required
                          "
                          v-model="store.address"
                        />

                        <form-error
                          message="Dirección es requerido"
                          v-if="
                            $v.store.address.$error &&
                            !$v.store.address.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="department">Departamento *</label>

                        <FormSelect
                          id="department"
                          v-model="store.departmentId"
                          :items="departments"
                          defaultOption="Elegir departamento"
                          :show-error="
                            $v.store.departmentId.$error &&
                            !$v.store.departmentId.required
                          "
                        />

                        <form-error
                          message="Departamento es requerido"
                          v-if="
                            $v.store.departmentId.$error &&
                            !$v.store.departmentId.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="province">Provincia *</label>

                        <FormSelect
                          id="province"
                          v-model="store.provinceId"
                          :items="provinces"
                          defaultOption="Elegir provincia"
                          :show-error="
                            $v.store.provinceId.$error &&
                            !$v.store.provinceId.required
                          "
                        />
                        <form-error
                          message="Provincia es requerido"
                          v-if="
                            $v.store.provinceId.$error &&
                            !$v.store.provinceId.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="district">Distrito *</label>

                        <FormSelect
                          id="district"
                          v-model="store.districtId"
                          :items="districts"
                          defaultOption="Elegir distrito"
                          :show-error="
                            $v.store.districtId.$error &&
                            !$v.store.districtId.required
                          "
                        />
                        <form-error
                          message="Distrito es requerido"
                          v-if="
                            $v.store.districtId.$error &&
                            !$v.store.districtId.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div>
                        <b-form-checkbox
                          id="clear-all-check"
                          v-model="store.showMap"
                        >
                          <span class="font-weight-medium custom-checkbox-label"
                            >Ubicar dirección en el mapa</span
                          >
                        </b-form-checkbox>
                      </div>

                      <div class="mt-2" v-if="store.showMap && !isLoading">
                        <SimpleAlert
                          text="Asegúrese de ubicar el punto en el mapa correctamente"
                          type="light-gray"
                          class="alert-map justify-content-center mt-4"
                        >
                          <template v-slot:icon>
                            <WarningAlertIcon class="mr-2" />
                          </template>
                        </SimpleAlert>

                        <!-- <img
                          :src="
                            require('@/core/assets/images/provisional/map.png')
                          "
                          alt=""
                          class="img-fluid"
                        /> -->

                        <GmapMap
                          ref="mapRef"
                          :options="mapOptions"
                          :center="{ lat: -12.125584, lng: -77.021367 }"
                          :zoom="zoom"
                          :initialZoom="true"
                          @center_changed="updateCenter"
                          :clickable="true"
                          style="
                            width: 100%;
                            height: 350px;
                            margin-bottom: 32px;
                          "
                          class="map"
                        >
                          <GmapMarker
                            :position="store.coordinates"
                            :clickable="false"
                            :draggable="false"
                          />
                        </GmapMap>
                      </div>
                    </div>
                  </div>

                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'config-store-list' }"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  FormError,
  FormInput,
  FormSelect,
  SimpleAlert,
  WarningAlertIcon,
  UploadImages,
  TrashIcon,
  PlusIcon,
} from "wize-admin";

import { required } from "vuelidate/lib/validators";

import { mapGetters } from "vuex";

import { Helpers } from "@/core/utils";
import UploadImagesMixin from "@/core/mixins/UploadImagesMixin";
import { BranchService, UbigeoService } from "@/core/services";
import SelectDays from "@/core/components/common/SelectDays";
import SelectTimeRange from "@/core/components/common/SelectTimeRange";
import debounce from "debounce";

export default {
  mixins: [UploadImagesMixin],
  data() {
    return {
      mapOptions: {
        map: null,
        minZoom: 18,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      zoom: 2,
      ubigeos: [],
      departments: [],
      provinces: [],
      districts: [],
      store: {
        name: null,
        assetIds: [],
        address: null,
        districtId: null,
        coordinates: null,
        departmentId: null,
        provinceId: null,
        department: null,
        province: null,
        district: null,
        openingHours: [],
        showMap: false,
      },
      timeRangeOptions: [],
      editing: false,
      addressLoaded: false,
      daysList: [
        {
          status: false,
          label: "Todos los días",
          key: "all",
          unique: true,
        },
        {
          status: false,
          label: "Lun. a Vie.",
          key: "l-v",
          unique: true,
        },
        {
          status: false,
          label: "Lun. a sáb.",
          key: "l-s",
          unique: true,
        },
        {
          status: false,
          label: "Lunes",
          key: "l",
        },
        {
          status: false,
          label: "Martes",
          key: "m",
        },
        {
          status: false,
          label: "Miércoles",
          key: "mi",
        },
        {
          status: false,
          label: "Jueves",
          key: "j",
        },
        {
          status: false,
          label: "Viernes",
          key: "v",
        },
        {
          status: false,
          label: "Sábado",
          key: "s",
        },
        {
          status: false,
          label: "Domingo",
          key: "d",
        },
      ],
      errorOpeningHours: false,
    };
  },
  components: {
    FormError,
    FormInput,
    FormSelect,
    SimpleAlert,
    WarningAlertIcon,
    UploadImages,
    SelectDays,
    SelectTimeRange,
    TrashIcon,
    PlusIcon,
  },
  validations: {
    store: {
      name: { required },
      address: { required },
      departmentId: { required },
      provinceId: { required },
      districtId: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
    "store.departmentId"(newVal) {
      if (newVal) {
        this.provinces = this.ubigeos.filter((o) => o.parentId === newVal);
      } else {
        this.provinces = [];
      }
      this.store.districtId = null;
      this.store.provinceId = null;
    },
    "store.provinceId"(newVal) {
      if (newVal) {
        this.districts = this.ubigeos.filter((o) => o.parentId === newVal);
      } else {
        this.districts = [];
      }
      this.store.districtId = null;
    },
    "store.address"(newVal) {
      if (newVal && this.store.districtId) {
        const district = this.ubigeos.find(
          (o) => o.key === this.store.districtId
        ).label;
        this.loadMarker(`${district} ${newVal}`);
      }
    },
    "store.districtId"(newVal) {
      if (newVal && this.store.address) {
        const district = this.ubigeos.find((o) => o.key === newVal).label;
        this.loadMarker(`${district} ${this.store.address}`);
      }
    },
    "store.showMap"(newVal) {
      if (newVal) {
        if (this.store.coordinates) {
          this.fitBounds(this.store.coordinates);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "app/isLoading",
    }),
  },
  methods: {
    loadMarker: debounce(function (address) {
      if (!this.addressLoaded) {
        this.addressLoaded = true;
        return;
      }
      const self = this;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address, componentRestrictions: { country: "pe" } },
        function (results, status) {
          if (status === "OK") {
            if (results[0]) {
              const result = results[0];
              const lat = result.geometry.location.lat();
              const lng = result.geometry.location.lng();
              self.store.coordinates = { lat, lng };
              self.fitBounds(self.store.coordinates);
            }
          }
        }
      );
    }, 1000),
    async onChange(files) {
      if (!files.length) return;

      try {
        let filesId = await this.uploadFiles(files);
        filesId = filesId.map((item) => item.id);

        this.store.assetIds.push(...filesId);
      } catch (error) {
        Alert.error(error);
      }
    },
    removeImage(i) {
      this.store.assetIds.splice(i, 1);
    },
    async save() {
      const self = this;

      this.errorOpeningHours = false;

      // Verificar horarios de atención
      let validOpeningHours = this.store.openingHours.filter(
        (item) => !item.days.length || !item.endHour || !item.startHour
      );

      // Si no existen elementos en el array de horarios, se muestra el error
      if (validOpeningHours.length) {
        this.errorOpeningHours = true;
      }

      self.$v.$touch();
      if (self.$v.$error || this.errorOpeningHours) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: this.errorOpeningHours ? -200 : -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);

      let data = { ...this.store };
      if (data.coordinates) {
        data.coordinates = `${this.store.coordinates.lat},${this.store.coordinates.lng}`;
      }
      data.district = this.ubigeos.find((o) => o.key === data.districtId).label;
      data.province = this.ubigeos.find((o) => o.key === data.provinceId).label;
      data.department = this.ubigeos.find(
        (o) => o.key === data.departmentId
      ).label;

      try {
        let response;
        if (!this.editing) response = await BranchService.save(data);
        else response = await BranchService.update(this.$route.params.id, data);

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-store-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        } else {
          this.load(this.$route.params.id);
        }
      } catch (ex) {
        Alert.error(ex.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await BranchService.get(id);
        let data = { ...response.payload };
        this.store = Object.assign({}, this.store, data);

        // Verificar array en horario de envíos
        if (!this.store.openingHours) {
          this.addOpeningHoursItem();
        }

        this.provinces = await UbigeoService.getChildren(
          this.ubigeos,
          data.departmentId
        );
        this.store.provinceId = data.provinceId;
        this.districts = await UbigeoService.getChildren(
          this.ubigeos,
          data.provinceId
        );
        this.store.districtId = data.districtId;

        if (data.coordinates && this.store.showMap) {
          const coordinates = data.coordinates.split(",");
          this.store.coordinates = {
            lat: Number(coordinates[0]),
            lng: Number(coordinates[1]),
          };
          this.fitBounds(this.store.coordinates);
        }

        // La api devuelve las imágenes en una propiedad llamada "images", pero al actualizar una tienda,
        // se tiene que enviar el id de las imágenes en una propiedad "assetIds"
        this.store.assetIds = data.images.map((item) => item.id);
        this.editing = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    updateCenter(center) {
      this.store.coordinates = { lat: center.lat(), lng: center.lng() };
    },
    selectedDays(list, index) {
      this.store.openingHours[index].days = [...list];
    },
    setTimeRange(value, index, type) {
      let list = [...this.store.openingHours];

      if (type === "startHour") {
        list[index].startHour = value.label;
      } else {
        list[index].endHour = value.label;
      }

      this.store.openingHours = [...list];
    },
    addOpeningHoursItem() {
      let item = {
        days: [],
        startHour: null,
        endHour: null,
      };

      if (!this.store.openingHours) {
        this.store.openingHours = [item];
      } else {
        this.store.openingHours.push(item);
      }
    },
    removeItem(i) {
      if (i !== 0) {
        this.store.openingHours.splice(i, 1);
      }
    },
    fitBounds(coordinates) {
      const self = this;
      setTimeout(() => {
        self.$refs.mapRef.$mapPromise.then((map) => {
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(coordinates);
          map.fitBounds(bounds);
          setTimeout(() => {
            map.setZoom(2);
          }, 1000);
        });
      }, 400);
    },
  },
  async created() {
    // Generar horarios a mostrar en select
    this.timeRangeOptions = Helpers.generateHours();
    // --

    this.ubigeos = await UbigeoService.getUbigeosMap();
    this.departments = await UbigeoService.getChildren(this.ubigeos, null);
    if (this.$route.params.id) {
      await this.load(this.$route.params.id);
    } else {
      this.addOpeningHoursItem();
    }
  },
};
</script>

<style lang="stylus">
@import '../Styles/store-editor.styl';
</style>
