<template>
  <form
    class="
      general-configuration
      custom-form
      biolink-config
      h-100
      d-flex
      flex-column
      animate__animated animate__fadeIn
    "
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Bloques</h2>

    <section class="biolink-config-container">
      <div class="form-container border-bottom-0">
        <div
          @dragover.prevent
          @dragenter.prevent
          @drop="($event)=>{}"
          @mouseleave="resetDragAndDropItems()"
        >
          <div
            class="biolink-card-container cursor-pointer"
            draggable
            @dragstart="startDrag($event, index)"
            @drop="onDropItem($event, index)"
            @dragover="addHighlightedToDropElement(index)"
            @dragleave="item.drop = false"
            v-for="(block, index) in list"
            :key="index"
            :class="{ drag: block.drag, drop: block.drop }"
          >
            <BiolinkCard
              :info="block"
              :index="index"
              @edit="openModalEdit($event, index)"
              @delete="openModalDelete"
            />
          </div>
        </div>

        <div
          class="
            add-block-button-container
            d-flex
            justify-content-center
            position-relative
          "
        >
          <button
            type="button"
            class="add-block-button"
            @click="showPopup = !showPopup"
          >
            <PlusIcon class="fill-gray-4" /> Añadir Bloque
          </button>

          <div class="popup-add-block" v-if="showPopup">
            <ul class="popup-add-block-list list-unstyled mb-0">
              <li
                v-for="(type, index) in blockTypes"
                :key="index"
                @click="showPopupAction(type.key)"
              >
                <CardWithImageIcon class="fill-gray mr-1" /> {{ type.label }}
              </li>
            </ul>

            <div class="popup-overlay" @click="showPopup = false"></div>
          </div>
        </div>
      </div>
    </section>

    <div class="form-group-buttons text-right mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>

    <AddBlockModal
      :show="showModal"
      @hide="showModal = false"
      :blockType="blockType"
      :selectedOption="selectedOption"
      @save="addBlock"
      @update="update"
      @clearSelectedOption="selectedOption = null"
    />

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmDeletion"
    />
  </form>
</template>

<script>
import { BiolinkBlocksService } from "@/core/services";

import {
  Alert,
  PlusIcon,
  ConfirmationModal,
  ConfirmationModalMixin,
  CardWithImageIcon
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import AddBlockModal from "../Components/AddBlockModal";
import BiolinkCard from "../Components/BiolinkCard";

export default {
  mixins: [ConfirmationModalMixin],
  data() {
    return {
      showPopup: false,
      showModal: false,
      list: [],
      blockTypes: LocalConstants.BiolinkBlockTypes,
      blockType: null,
      selectedOption: null,
    };
  },
  mounted() {
    this.loadData();
  },
  components: {
    PlusIcon,
    CardWithImageIcon,
    AddBlockModal,
    BiolinkCard,
    ConfirmationModal,
  },
  methods: {
    startDrag(evt, index) {
      let list = [...this.list];
      list[index].drag = true;
      this.list = list;

      evt.dataTransfer.setData("dragIndex", index);
    },
    onDropItem(evt, index) {
      let indexInt = parseInt(index);
      let dragIndex = parseInt(evt.dataTransfer.getData("dragIndex"));

      let list = [...this.list];

      let dropItemCopy = { ...list[indexInt] };

      list[indexInt] = list[dragIndex];
      list[dragIndex] = dropItemCopy;

      list.forEach((item) => {
        item.drag = false;
        item.drop = false
      });

      this.list = list;
    },
    resetDragAndDropItems() {
      this.list.forEach((item) => {
        item.drag = false;
        item.drop = false;
      });
    },
    addHighlightedToDropElement(index) {
      let list = [...this.list];

      list.forEach((item) => {
        item.drop = false;

        list[index].drop = true
      });

      this.list = list;
    },
    showPopupAction(type) {
      this.blockType = type;

      this.showModal = true;

      this.showPopup = false;
    },
    addBlock(item) {
      this.list.push(item);
    },
    update(data) {
      let index = data.index;
      this.list[index] = data;
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await BiolinkBlocksService.list();
        this.list = response.payload;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openModalDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Eliminar bloque",
        description: "¿Está seguro que desea eliminar el bloque?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openModalEdit(item, index) {
      this.selectedOption = {
        ...item,
        index,
      };

      this.showModal = true;
    },
    confirmDeletion() {
      let index = this.selectedOption;
      this.list.splice(index, 1);

      this.closeConfirmationModal();
    },
    async save() {
      try {
        this.$store.commit("app/setLoading", true);

        let list = this.list;

        list.forEach((item, i) => {
          item.position = i + 1;
        });

        const resp = await BiolinkBlocksService.save(this.list);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
@import '../Styles/biolink-config.styl';
</style>
