<template>
  <div class="payment-details">
    <img src="@/core/assets/images/payments/niubiz-lg.png" alt="Logo Niubiz" class="logo rounded-circle" />

    <HelpCollapse>
      <template slot="content">
        <div class="help-collapse-content">
          <p>
            Para empezar a recibir pagos a través de Niubiz, por favor completa el siguiente formulario.
          </p>
          <p>
            Por motivos de seguridad, cuando vuelvas a ingresar a este formulario, no verás cargada la información
            sensible y deberás actualizarla nuevamente en caso haya cambiado.
          </p>
        </div>
      </template>
    </HelpCollapse>

    <form action="" class="custom-form mt-4" @submit.prevent="save()">
      <div class="form-group">
        <label for="merchantId">Código de Comercio *</label>
        <FormInput id="merchantId" type="text" placeholder="Ingresar Código de Comercio"
          :showError="$v.form.merchantId.$error && !$v.form.merchantId.required" v-model="form.merchantId" />
        <form-error message="Código de Comercio es requerido"
          v-if="$v.form.merchantId.$error && !$v.form.merchantId.required"></form-error>
      </div>
      <div class="form-group">
        <label for="username">Usuario *</label>
        <FormInput id="username" type="text" placeholder="Ingresar usuario" v-model="form.username"
          autocomplete="one-time-code" :showError="$v.form.username.$error && !$v.form.username.required" />
        <form-error message="Usuario es requerido"
          v-if="$v.form.username.$error && !$v.form.username.required"></form-error>
      </div>
      <div class="form-group">
        <label for="password">Password *</label>
        <FormInput id="password" type="text" placeholder="Ingresar password" v-model="form.password"
          :showError="$v.form.password.$error && !$v.form.password.required" autocomplete="one-time-code" />
        <form-error message="Password es requerido"
          v-if="$v.form.password.$error && !$v.form.password.required"></form-error>
      </div>

      <div class="form-group">
        <label for="environment">Entorno *</label>
        <div class="d-flex">
          <b-form-radio v-model="form.env" name="environment" value="PRODUCTION">Producción
          </b-form-radio>
          <b-form-radio v-model="form.env" name="environment" value="INTEGRATION" class="ml-2">Integración
          </b-form-radio>
        </div>
      </div>

      <div class="form-group-buttons text-right">
        <router-link :to="{ name: 'config-payment-details', params: { slug: slug } }" class="button button-light">
          Volver
        </router-link>
        <button class="button button-primary" type="submit">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { FormError, FormInput } from "wize-admin";
import HelpCollapse from "./HelpCollapse.vue";
import PaymentMethodEntityMixin from "@/core/mixins/PaymentMethodEntityMixin"

export default {
  name: "NiubizForm",
  mixins: [PaymentMethodEntityMixin],
  data() {
    return {
      slug: this.$route.params.slug,
      form: {
        id: "NIUBIZ",
        merchantId: null,
        username: null,
        password: null,
        isActive: false,
        env: "INTEGRATION",
      },
    };
  },
  validations: {
    form: {
      merchantId: { required },
      username: { required },
      password: { required },
      env: { required },
    },
  },
  components: {
    FormInput,
    FormError,
    HelpCollapse
  },
  async mounted() {
    await this.load();
    this.form.id = "NIUBIZ";

  },
};
</script>

<style lang="stylus" scoped>
@import "../../Styles/payment-details.styl"
</style>
