import { Alert } from 'wize-admin';

import { PaymentService } from "@/core/services";

export default {
  methods: {
    async load() {
      try {
        this.$store.dispatch("app/loading", true);
        const paymentMethod = (await PaymentService.get(this.form.id)).payload;
        this.form = { ...this.form, ...paymentMethod.publicData };
        this.form.isActive = paymentMethod.isActive;
      } catch (e) {
        if (this.$route.query && !!this.$route.query.activate) {
          this.form.isActive = this.$route.query.activate
        }
        console.error(e)
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }
      try {
        this.$store.commit("app/setLoading", true);

        let data = {
          ...this.form,
        };

        const resp = await PaymentService.save(data);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  }
}
