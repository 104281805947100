import Helpers from '@/core/utils/helpers'

let banksOptions = [
  {
    "key": "banks-business",
    "name": "Empresas bancarias",
    "banks": [
      {
        "name": "Banco de Crédito del Perú",
        "isEnabled": false,
        "logo": "bcp.svg"
      },
      {
        "name": "Banco Internacional del Perú",
        "isEnabled": false,
        "logo": "interbank.svg"
      },
      {
        "name": "BBVA",
        "isEnabled": false,
        "logo": "BBVA.svg"
      },
      {
        "name": "Scotiabank Perú",
        "isEnabled": false,
        "logo": "scotiabank.svg"
      },
      {
        "name": "Banco Falabella",
        "isEnabled": false,
        "logo": "Banco-Falabella.svg"
      },
      {
        "name": "Banco Ripley",
        "isEnabled": false,
        "logo": "Banco-Ripley.svg"
      },
      {
        "name": "Banco Interamericano de Finanzas",
        "isEnabled": false,
        "logo": "banbif.svg"
      },
      {
        "name": "Banco Pichincha",
        "isEnabled": false,
        "logo": "banco-pichincha.svg"
      },
      {
        "name": "Banco de Comercio",
        "isEnabled": false,
        "logo": "bancom.svg"
      },
      {
        "name": "Citibank Perú",
        "isEnabled": false,
        "logo": "citibank.svg"
      },
      {
        "name": "MiBanco",
        "isEnabled": false,
        "logo": "MiBanco.svg"
      },
      {
        "name": "Banco GNB Perú",
        "isEnabled": false,
        "logo": "Banco-GNB.svg"
      },
      {
        "name": "Banco Santander Perú",
        "isEnabled": false,
        "logo": "Banco-Santander-Peru.svg"
      },
      {
        "name": "Alfin Banco",
        "isEnabled": false,
        "logo": "Alfin-Banco.svg"
      },
      {
        "name": "Bank of China",
        "isEnabled": false,
        "logo": "Bank-of-China.svg"
      },
      {
        "name": "Banco Bci",
        "isEnabled": false,
        "logo": "Bci-Peru.svg"
      },
      {
        "name": "ICBC PERU BANK",
        "isEnabled": false,
        "logo": "ICBC-PERU-BANK.svg"
      }
    ]
  },
  {
    "key": "financial-companies",
    "name": "Empresas financieras",
    "banks": [
      {
        "name": "Crediscotia",
        "isEnabled": false,
        "logo": "Crediscotia.svg"
      },
      {
        "name": "Confianza",
        "isEnabled": false,
        "logo": "Confianza.svg"
      },
      {
        "name": "Compartamos",
        "isEnabled": false,
        "logo": "Compartamos.svg"
      },
      {
        "name": "Credinka",
        "isEnabled": false,
        "logo": "Credinka.svg"
      },
      {
        "name": "Efectiva",
        "isEnabled": false,
        "logo": "Efectiva.svg"
      },
      {
        "name": "Proempresa",
        "isEnabled": false,
        "logo": "Proempresa.svg"
      },
      {
        "name": "Mitsui Auto Finance",
        "isEnabled": false,
        "logo": "Mitsui-Auto-Finance.svg"
      },
      {
        "name": "Financiera Oh!",
        "isEnabled": false,
        "logo": "Oh.svg"
      },
      {
        "name": "Qapaq",
        "isEnabled": false,
        "logo": "Qapaq.svg"
      }
    ]
  },
  {
    "key": "state-financial-entities",
    "name": "Entidades Financieras Estatales",
    "banks": [
      {
        "name": "Agrobanco",
        "isEnabled": false,
        "logo": "agrobanco.svg"
      },
      {
        "name": "Banco de la Nación",
        "isEnabled": false,
        "logo": "bdelanacion.svg"
      },
      {
        "name": "COFIDE",
        "isEnabled": false,
        "logo": "cofide.svg"
      },
      {
        "name": "Fondo MiVivienda",
        "isEnabled": false,
        "logo": "mivivienda.svg"
      }
    ]
  },
  {
    "key": "cmac",
    "name": "Cajas Municipales de Ahorro y Crédito (CMAC)",
    "banks": [
      {
        "name": "Arequipa",
        "isEnabled": false,
        "logo": "caja-arequipa.svg"
      },
      {
        "name": "Cusco",
        "isEnabled": false,
        "logo": "caja-cuzco.svg"
      },
      {
        "name": "Del Santa",
        "isEnabled": false,
        "logo": "caja-del-santa.svg"
      },
      {
        "name": "Trujillo",
        "isEnabled": false,
        "logo": "caja-trujillo.svg"
      },
      {
        "name": "Huancayo",
        "isEnabled": false,
        "logo": "caja-huancayo.svg"
      },
      {
        "name": "Ica",
        "isEnabled": false,
        "logo": "caja-ica.svg"
      },
      {
        "name": "Maynas",
        "isEnabled": false,
        "logo": "caja-maynas.svg"
      },
      {
        "name": "Paita",
        "isEnabled": false,
        "logo": "caja-paita.svg"
      },
      {
        "name": "Piura",
        "isEnabled": false,
        "logo": "caja-piura.svg"
      },
      {
        "name": "Sullana",
        "isEnabled": false,
        "logo": "caja-sullana.svg"
      },
      {
        "name": "Tacna",
        "isEnabled": false,
        "logo": "caja-tacna.svg"
      }
    ]
  },
  {
    "key": "cmcp",
    "name": "Cajas Municipales de Crédito y Popular (CMCP)",
    "banks": [
      {
        "name": "Caja Metropolitana de Lima",
        "isEnabled": false,
        "logo": "caja-municipal.svg"
      }
    ]
  }
]

let list = []

banksOptions.forEach(item => {
  item.banks.forEach(bank => bank.slug = Helpers.sluglify(bank.name))
  list.push(...item.banks)
})

// Asignar Id
list.forEach((item, index) => item.id = index + 1)

export default banksOptions
