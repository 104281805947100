<template>
  <form
    class="
      general-configuration
      header-config
      custom-form
      h-100
      d-flex
      flex-column
      animate__animated animate__fadeIn
    "
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Header</h2>

    <div class="form-container header-types">

      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container mb-2 mb-md-0">
          <label for="name" class="header-types-label">Estilo de cabecera</label>
        </div>

        <div class="header-types-container">
          <div class="text-center cursor-pointer" v-for="(item, index) in headerTypes" :key="index">
            <img :src="require(`@/core/assets/images/config/header/${item.image}`)" :alt="item.type" :class="{'header-active': item.status}">
            <span class="header-label d-block mt-1">{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>

    <section class="form-container border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Logo</label>
          <p class="label-description">Sube tu logo en {{ logoMaxWidth }}px x {{ logoMaxHeight }}px en formato .png o .jpg</p>
        </div>

        <div class="form-field">
          <UploadImages
            :maxWidth="logoMaxWidth"
            :maxHeight="logoMaxHeight"
            :maxFiles="1"
            :files="form[0].value ? [{ url: form[0].value }] : []"
            @onChange="onChange($event, 'LOGO_IMAGE')"
            @remove="removeImage('LOGO_IMAGE')"
          />

          <!-- <form-error
            message="Logo web es requerido"
            v-if="$v.form.logo.$error && !$v.form.logo.required"
          ></form-error> -->

          <!-- <form-error
            message="Las dimensiones de la imagen son incorrectas"
            v-if="form.logoErrorDimensions"
          ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Favicon</label>
          <p class="label-description">Sube tu logo en {{ faviconMaxWidth }}px x {{ faviconMaxHeight }}px en formato .png</p>
        </div>

        <div class="form-field">
          <UploadImages
            :maxFiles="1"
            :maxWidth="faviconMaxWidth"
            :maxHeight="faviconMaxHeight"
            :acceptFile="['.png']"
            :files="form[3].value ? [{ url: form[3].value }] : []"
            @onChange="onChange($event, 'FAVICON')"
            @remove="removeImage('FAVICON')"
          />

          <!-- <form-error
            message="Logo web es requerido"
            v-if="$v.form.logo.$error && !$v.form.logo.required"
          ></form-error> -->

          <!-- <form-error
            message="Las dimensiones de la imagen son incorrectas"
            v-if="form.logoErrorDimensions"
          ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="businessName">Logo texto</label>
        </div>

        <div class="form-field">
          <FormInput
            id="businessName"
            type="text"
            placeholder="Ingresar logo texto"
            v-model="form[1].value"
          />

          <!-- <form-error
            message="Logo texto es requerido"
            v-if="$v.form.name.$error && !$v.form.name.required"
          ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Logo atributo alt</label>
          <p class="label-description">
            Atributo Alt para el logo. Este es el texto alternativo si no se
            puede mostrar el logotipo. Es útil para SEO y generalmente es el
            nombre del sitio.
          </p>
        </div>

        <div class="form-field">
          <FormInput
            id="logoAlt"
            type="text"
            placeholder="Ingresar logo atributo alt"
            v-model="form[2].value"
          />
        </div>
      </div>
    </section>

    <div class="form-group-buttons text-right align-self-end pt-0 mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { FormInput, Alert, UploadImages } from "wize-admin";

import { ConfigurationService } from "@/core/services";

import UploadImagesMixin from '@/core/mixins/UploadImagesMixin'

// import { required } from "vuelidate/lib/validators";

export default {
  mixins: [UploadImagesMixin],
  data() {
    return {
      logoMaxWidth: 272, // Especificar en px
      logoMaxHeight: 90,
      faviconMaxWidth: 512,
      faviconMaxHeight: 512,
      form: [
        {
          identifier: "LOGO_IMAGE",
          value: null,
        },
        {
          identifier: "LOGO_TEXT",
          value: null,
        },
        {
          identifier: "LOGO_ALT",
          value: null,
        },
        {
          identifier: "FAVICON",
          value: null,
        }
      ],
      headerTypes: [
        {
          type: 'DEFAULT',
          text: 'Estilo 1',
          image: 'default.svg',
          status: true
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  components: {
    FormInput,
    // FormError,
    UploadImages,
  },
  // validations: {
  //   form: {
  //     logo: { required },
  //     name: { required }
  //   },
  // },
  methods: {
    async save() {
      // this.$v.$touch();
      // if (this.$v.$error) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            identifier: item.identifier,
            value: item.value ? item.value : "",
          };
        });

        const resp = await ConfigurationService.save(params);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    // async onChange(files) {
    //   try {
    //     let uploadedFiles = await this.uploadFiles(files)

    //     this.form[0].value = uploadedFiles[0].url
    //   } catch (error) {
    //     Alert.error(error)
    //   }
    // },
    async onChange(files, type) {
      try {
        if(!files.length) return

        let uploadedFiles = await this.uploadFiles(files)

        if(!uploadedFiles.length) return

        if (type === "LOGO_IMAGE") {
          this.form[0].value = uploadedFiles[0].url;
        } else if (type === "FAVICON") {
          this.form[3].value = uploadedFiles[0].url;
        }
      } catch (error) {
        console.error(error);
        Alert.error(error)
      }
    },
    removeImage(type) {
      type == 'LOGO_IMAGE' ? this.form[0].value = null : this.form[3].value = null
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await ConfigurationService.list();
        let data = response.payload;
        this.form.forEach((item) => {
          let field = data.find((d) => d.identifier == item.identifier);
          if (field) {
            item.value = field.value;
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    oldFiles: function() {
      return this.form[0].value ? [{url: this.form[0].value}] : []
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
</style>
