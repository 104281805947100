<template>
  <div class="login d-flex justify-content-center align-items-center">
    <div class="container animate__animated animate__fadeIn">
      <section class="row justify-content-center">
        <div class="col-11 col-md-6 text-center">
          <b-card class="login-card mx-auto border-0 py-4">
            <LogoWizeBlue/>
            <h1 class="title">¡Cambia tu contraseña!</h1>
            <FormResetPassword/>
          </b-card>

          <section class="text-center new-account">
            <p>© {{ currentYear }} Forjado por <a href="https://wize.pe" target="_blank">Wize</a></p>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FormResetPassword from '../Components/FormResetPassword'
import {LogoWizeBlue} from 'wize-admin'

export default {
  name: "ResetPasswordView",
  components: {
    FormResetPassword,
    LogoWizeBlue
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showFormReset: false
    };
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/login-page";
</style>
