import { Alert } from 'wize-admin'

import { ConfigurationService } from "@/core/services"

export default {
  methods: {
    async save() {
      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            identifier: item.identifier,
            value: item.value ? item.value : "",
          };
        });

        const resp = await ConfigurationService.save(params);
        let data = resp.payload

        this.$store.commit('app/setStoreConfig', data)

        this.loadData()

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  }
}
