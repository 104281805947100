<template>
  <div class="page-container banner-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar banner" : "Nuevo banner" }}
      </h1>
    </div>

    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{ editing ? "Datos del banner" : "Datos del nuevo banner" }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle">Información general</h2>
                  <p class="custom-card-description">
                    Ingresa los datos
                    {{ editing ? "del banner" : "del nuevo banner" }}
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="position">Orden *</label>

                        <FormInput
                          id="position"
                          type="number"
                          :min="0"
                          placeholder="Ingresar orden"
                          :show-error="
                            $v.banner.position.$error &&
                            !$v.banner.position.required
                          "
                          v-model="banner.position"
                        />

                        <form-error
                          message="Orden es requerido"
                          v-if="
                            $v.banner.position.$error &&
                            !$v.banner.position.required
                          "
                        ></form-error>
                      </div>

                      <div class="form-group">
                        <label for="title">Título *</label>

                        <FormInput
                          id="title"
                          type="text"
                          placeholder="Ingresar título"
                          :show-error="
                            $v.banner.title.$error && !$v.banner.title.required
                          "
                          v-model="banner.title"
                        />

                        <form-error
                          message="Título es requerido"
                          v-if="
                            $v.banner.title.$error && !$v.banner.title.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="form-group"
                        :class="{ 'mb-0': !banner.redirectType }"
                      >
                        <label for="redirectType">Redireccionar a</label>

                        <FormSelect
                          id="redirectType"
                          v-model="banner.redirectType"
                          :items="redirectTypes"
                          defaultOption="Elegir redirección"
                          @change="banner.redirectUrl = null"
                        />
                      </div>

                      <div
                        class="form-group mb-0"
                        v-if="
                          banner.redirectType ===
                          LocalConstants.BannersRedirectTypes.CATEGORY.key
                        "
                      >
                        <label for="redirectType">Categoría</label>

                        <FormSelect
                          id="redirectType"
                          v-model="banner.redirectUrl"
                          :items="categories"
                          defaultOption="Elegir categoría"
                          :show-error="
                            $v.banner.redirectUrl.$error &&
                            !$v.banner.redirectUrl.required
                          "
                        />

                        <form-error
                          message="Categoría es requerido"
                          v-if="
                            $v.banner.redirectUrl.$error &&
                            !$v.banner.redirectUrl.required
                          "
                        ></form-error>
                      </div>

                      <div
                        class="form-group mb-0"
                        v-if="
                          banner.redirectType ===
                          LocalConstants.BannersRedirectTypes.PRODUCT.key
                        "
                      >
                        <label for="redirectType">Producto</label>

                        <FormSelect
                          id="redirectType"
                          v-model="banner.redirectUrl"
                          :items="products"
                          defaultOption="Elegir producto"
                          :show-error="
                            $v.banner.redirectUrl.$error &&
                            !$v.banner.redirectUrl.required
                          "
                        />

                        <form-error
                          message="Producto es requerido"
                          v-if="
                            $v.banner.redirectUrl.$error &&
                            !$v.banner.redirectUrl.required
                          "
                        ></form-error>
                      </div>

                      <div
                        class="form-group mb-0"
                        v-if="
                          banner.redirectType ===
                          LocalConstants.BannersRedirectTypes.OTHER.key
                        "
                      >
                        <label for="redirectType">Otra URL</label>

                        <FormInput
                          type="text"
                          placeholder="Ingresar URL"
                          v-model="banner.redirectUrl"
                          :show-error="
                            $v.banner.redirectUrl.$error &&
                            !$v.banner.redirectUrl.required
                          "
                        />

                        <form-error
                          message="URL es requerido"
                          v-if="
                            $v.banner.redirectUrl.$error &&
                            !$v.banner.redirectUrl.required
                          "
                        ></form-error>

                        <b-form-checkbox
                          v-model="banner.openNewTab"
                          class="mt-2"
                        >
                          <span class="custom-checkbox-label"
                            >Abrir en una ventana nueva</span
                          >
                        </b-form-checkbox>
                      </div>
                    </div>

                    <div class="col-12"></div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Fotos de banners</h2>

                  <p class="custom-card-description">
                    Agregue fotos de los banners en los siguiente tamaños:
                  </p>

                  <div class="form-group d-flex flex-column flex-md-row">
                    <div class="label-container d-flex flex-column">
                      <label for="desktopImageId" class="mb-0"
                        >Imagen desktop *</label
                      >
                      <p class="label-description">
                        Sube tu imagen en tamaño {{ desktopImageMaxWidth }}px x
                        {{ desktopImageMaxHeight }}px en formato .png o .jpg
                      </p>
                    </div>

                    <div>
                      <UploadImages
                        :exactDimensions="true"
                        :maxWidth="desktopImageMaxWidth"
                        :maxHeight="desktopImageMaxHeight"
                        :maxFiles="1"
                        :files="desktopOldImage"
                        @onChange="onChange($event, 'Desktop')"
                        @remove="removeImage($event, 'Desktop')"
                      />

                      <form-error
                        message="Imagen desktop es requerido"
                        v-if="
                          $v.banner.desktopImageId.$error &&
                          !$v.banner.desktopImageId.required
                        "
                      ></form-error>
                    </div>
                  </div>

                  <div class="form-group d-flex flex-column flex-md-row">
                    <div class="label-container d-flex flex-column">
                      <label for="mobileImageId" class="mb-0"
                        >Imagen mobile *</label
                      >
                      <p class="label-description">
                        Sube tu imagen en tamaño {{ mobileImageMaxWidth }}px x
                        {{ mobileImageMaxHeight }}px en formato .png o .jpg
                      </p>
                    </div>

                    <div>
                      <UploadImages
                        :exactDimensions="true"
                        :maxWidth="mobileImageMaxWidth"
                        :maxHeight="mobileImageMaxHeight"
                        :maxFiles="1"
                        :files="mobileOldImage"
                        @onChange="onChange($event, 'Mobile')"
                        @remove="removeImage($event, 'Mobile')"
                      />

                      <form-error
                        message="Imagen mobile es requerido"
                        v-if="
                          $v.banner.mobileImageId.$error &&
                          !$v.banner.mobileImageId.required
                        "
                      ></form-error>
                    </div>
                  </div>

                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'config-banners-list' }"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  FormError,
  FormInput,
  FormSelect,
  UploadImages,
} from "wize-admin";

import {
  BannerService,
  ProductService,
  ConfigurationService,
} from "@/core/services";

import { required, requiredIf } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

import UploadImagesMixin from "@/core/mixins/UploadImagesMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [UploadImagesMixin],
  data() {
    return {
      LocalConstants,
      redirectTypes: [
        LocalConstants.BannersRedirectTypes.CATEGORY,
        LocalConstants.BannersRedirectTypes.PRODUCT,
        LocalConstants.BannersRedirectTypes.OTHER,
      ],
      districts: [
        { key: "Miraflores", label: "Miraflores" },
        { key: "Jesús María", label: "Jesús María" },
      ],
      showMap: false,
      previewDesktop: null,
      previewMobile: null,
      search: null,
      categories: [],
      products: [],
      banner: {
        title: null,
        redirectType: null,
        desktopImageId: null,
        mobileImageId: null,
        openNewTab: false,
        position: null,
        redirectUrl: null,
      },
      editing: false,
      storeConfig: []
    };
  },
  components: {
    FormError,
    FormInput,
    FormSelect,
    UploadImages,
  },
  validations: {
    banner: {
      title: { required },
      position: { required },
      desktopImageId: { required },
      mobileImageId: { required },
      redirectUrl: {
        required: requiredIf((model) => {
          return model.redirectType;
        }),
      },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    initialize() {
      this.categories = this.categoriesList.map(c=> ({key: c.slug, label: c.name, slug: c.slug}));
    },
    async getProducts() {
      try {
        const response = await ProductService.listAll();
        this.products = response.payload.map((item) => {
          return {
            key: item.id,
            label: item.name,
            slug: item.slug,
          };
        });
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async getIdByTypeUrl() {
      let type = this.banner.redirectType;
      let list = [];

      if (type == this.LocalConstants.BannersRedirectTypes.CATEGORY.key) {
        // Buscar el slug en categorías
        list = this.categories;
      } else if (type == this.LocalConstants.BannersRedirectTypes.PRODUCT.key) {
        // Buscar el slug en productos
        list = this.products;
      }

      let item = list.find((item) => item.slug == this.banner.redirectUrl);
      if (item) this.banner.redirectUrl = item.key;
    },
    async onChange(files, type) {
      if (!files.length) return;

      try {
        let filesId = await this.uploadFiles(files);
        filesId = filesId.map((item) => item.id);

        if (type === "Desktop") {
          this.banner.desktopImageId = filesId[0];
        } else if (type === "Mobile") {
          this.banner.mobileImageId = filesId[0];
        }
      } catch (error) {
        Alert.error(error);
      }
    },
    removeImage(event, type) {
      if (type === "Mobile") {
        this.banner.mobileImage = null;
        this.banner.mobileImageId = null;
      } else if (type === "Desktop") {
        this.banner.desktopImage = null;
        this.banner.desktopImageId = null;
      }
    },
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);

      // Asignar URL según tipo de redirección
      this.setUrl();

      let data = { ...this.banner };
      data.mobileImageId = data.mobileImageId
        ? data.mobileImageId
        : data.mobileImage
        ? data.mobileImage.id
        : null;
      data.desktopImageId = data.desktopImageId
        ? data.desktopImageId
        : data.desktopImage
        ? data.desktopImage.id
        : null;

      try {
        let response;
        if (!this.editing) response = await BannerService.save(data);
        else response = await BannerService.update(this.$route.params.id, data);

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-banners-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        } else {
          this.getIdByTypeUrl();
        }
      } catch (ex) {
        Alert.ex(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await BannerService.get(id);
        this.banner = JSON.parse(JSON.stringify(response.payload));

        if (this.banner.desktopImage)
          this.banner.desktopImageId = this.banner.desktopImage.id;
        if (this.banner.mobileImage)
          this.banner.mobileImageId = this.banner.mobileImage.id;

        this.getIdByTypeUrl();

        this.editing = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async getstoreConfig() {
      try {
        this.$store.dispatch("app/loading", true)

        const response = await ConfigurationService.list();
        let data = JSON.parse(JSON.stringify(response.payload));
        this.storeConfig = [
          ...data
        ];
      } catch (error) {
        Alert.error(error);
      }
    },
    setUrl() {
      let type = this.banner.redirectType;
      let list = [];

      if (type == this.LocalConstants.BannersRedirectTypes.CATEGORY.key) {
        // Buscar el slug en categorías
        list = this.categories;
      } else if (type == this.LocalConstants.BannersRedirectTypes.PRODUCT.key) {
        // Buscar el slug en productos
        list = this.products;
      }

      let item = list.find((item) => item.key == this.banner.redirectUrl);
      if (item) this.banner.redirectUrl = item.slug;
    },
  },
  computed: {
    ...mapGetters({
      categoriesList: "app/getCategories",
    }),
    desktopOldImage: function () {
      return this.banner.desktopImage ? [this.banner.desktopImage] : [];
    },
    mobileOldImage: function () {
      return this.banner.mobileImage ? [this.banner.mobileImage] : [];
    },
    bannerConfig: function() {
      return this.storeConfig.find(item => item.identifier == 'BANNER_TYPE') || {}
    },
    desktopImageMaxWidth() {
      return this.bannerConfig.value == LocalConstants.BannerTypes.TIPO_1.key ? 1000 : 1368;
    },
    desktopImageMaxHeight() {
      return this.bannerConfig.value == LocalConstants.BannerTypes.TIPO_1.key ? 483 : 483;
    },
    mobileImageMaxWidth() {
      return this.bannerConfig.value == LocalConstants.BannerTypes.TIPO_1.key ? 545 : 545;
    },
    mobileImageMaxHeight() {
      return this.bannerConfig.value == LocalConstants.BannerTypes.TIPO_1.key ? 670 : 670;
    },
  },
  async created() {
    await this.getstoreConfig();
    await this.$store.dispatch("app/loadCategories");
    this.initialize();
    await this.getProducts();

    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/banner-editor.styl';
</style>
