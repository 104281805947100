import {http} from '@/http'

export default {
  list(params) {
    return http.get('/api/v1/admin/delivery_config', {params});
  },
  save(payload) {
    return http.put("/api/v1/admin/delivery_config", payload)
  },
  downloadList(params) {
    return http.get('/api/v1/admin/delivery_config/xlsx', {
      responseType: 'blob',
      params
    })
  }
}