<template>
  <form
    class="general-configuration banner-types custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Banner</h2>

    <template v-show="!isLoading">
      <div class="form-container header-types">
        <div class="form-group d-flex flex-column flex-md-row mb-0">
          <div class="label-container mb-2 mb-md-0">
            <label for="name" class="header-types-label">Estilo banner</label>
          </div>

          <div class="header-types-container">
            <div
              class="text-center cursor-pointer"
              v-for="(item, index) in bannerTypes"
              :key="index"
              @click="selectBannerType(item)"
            >
              <img
                :src="
                  require(`@/core/assets/images/config/banner/${item.image}`)
                "
                :alt="item.id"
                :class="{ 'header-active': item.status }"
              />
              <span class="header-label d-block mt-1">{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>

      <section
        class="form-container border-bottom-0 animate__animated animate__fadeIn"
        v-show="currentBannerType.id == LocalConstants.BannerTypes.TIPO_1.key"
      >
        <div class="form-group d-flex flex-column flex-md-row">
          <div class="label-container mb-2 mb-md-0">
            <label>Card 1</label>
          </div>

          <div class="form-field">
            <div class="d-flex flex-column flex-md-row">
              <div class="label-container d-inline-block">
                <label for="name">Imagen</label>
                <p class="label-description">
                  Sube tu imagen en tamaño {{ card1MaxWidth }}px x
                  {{ card1MaxHeight }}px en formato .png o .jpg
                </p>
              </div>

              <UploadImages
                :maxWidth="card1MaxWidth"
                :maxHeight="card1MaxHeight"
                :maxFiles="1"
                :files="form[0].value ? [{ url: form[0].value }] : []"
                @onChange="onChange($event, 'T_CARD_IMAGE_1')"
                @remove="removeImage('T_CARD_IMAGE_1')"
                class="mt-4 mt-md-0"
              />
            </div>

            <div class="d-flex flex-column flex-md-row mt-4">
              <div class="label-container d-inline-block">
                <label for="redirectType1">Redireccionar a</label>
              </div>

              <FormSelect
                id="redirectType1"
                class="configuration-select"
                v-model="form[4].value"
                :items="redirectTypes"
                defaultOption="Elegir redirección"
                @change="form[2].value = null"
              />
            </div>

            <div
              class="d-flex flex-column flex-md-row mt-4"
              v-if="form[4].value"
            >
              <div class="label-container d-inline-block">
                <label for="redirectUrl">{{ sectionTitle1 }}</label>
              </div>

              <div
                class="mb-0"
                v-if="
                  form[4].value ==
                  LocalConstants.BannersRedirectTypes.CATEGORY.key
                "
              >
                <FormSelect
                  id="redirectType"
                  class="configuration-select"
                  v-model="form[2].value"
                  :items="categories"
                  defaultOption="Elegir categoría"
                />
              </div>

              <div
                v-if="
                  form[4].value ==
                  LocalConstants.BannersRedirectTypes.PRODUCT.key
                "
              >
                <FormSelect
                  id="redirectType"
                  class="configuration-select"
                  v-model="form[2].value"
                  :items="products"
                  defaultOption="Elegir producto"
                />
              </div>

              <div
                v-if="
                  form[4].value == LocalConstants.BannersRedirectTypes.OTHER.key
                "
              >
                <FormInput
                  type="text"
                  class="configuration-select"
                  placeholder="Ingresar URL"
                  v-model="form[2].value"
                />

                <b-form-checkbox v-model="form[6].value" class="mt-2">
                  <span class="custom-checkbox-label"
                    >Abrir en una ventana nueva</span
                  >
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group d-flex flex-column flex-md-row">
          <div class="label-container mb-2 mb-md-0">
            <label>Card 2</label>
          </div>

          <div class="form-field">
            <div class="d-flex flex-column flex-md-row">
              <div class="label-container d-inline-block">
                <label for="name">Imagen</label>
                <p class="label-description">
                  Sube tu imagen en tamaño {{ card2MaxWidth }}px x
                  {{ card2MaxHeight }}px en formato .png o .jpg
                </p>
              </div>

              <UploadImages
                :maxWidth="card2MaxWidth"
                :maxHeight="card2MaxHeight"
                :maxFiles="1"
                :files="form[1].value ? [{ url: form[1].value }] : []"
                @onChange="onChange($event, 'T_CARD_IMAGE_2')"
                @remove="removeImage('T_CARD_IMAGE_2')"
                class="mt-4 mt-md-0"
              />
            </div>

            <div class="d-flex flex-column flex-md-row mt-4">
              <div class="label-container d-inline-block">
                <label for="redirectType2">Redireccionar a</label>
              </div>

              <FormSelect
                id="redirectType2"
                class="configuration-select"
                v-model="form[5].value"
                :items="redirectTypes"
                defaultOption="Elegir redirección"
                @change="form[3].value = null"
              />
            </div>

            <div
              class="d-flex flex-column flex-md-row mt-4"
              v-if="form[5].value"
            >
              <div class="label-container d-inline-block">
                <label for="redirectUrl">{{ sectionTitle2 }}</label>
              </div>

              <div
                class="mb-0"
                v-if="
                  form[5].value ==
                  LocalConstants.BannersRedirectTypes.CATEGORY.key
                "
              >
                <FormSelect
                  id="redirectType"
                  class="configuration-select"
                  v-model="form[3].value"
                  :items="categories"
                  defaultOption="Elegir categoría"
                />
              </div>

              <div
                v-if="
                  form[5].value ==
                  LocalConstants.BannersRedirectTypes.PRODUCT.key
                "
              >
                <FormSelect
                  id="redirectType"
                  class="configuration-select"
                  v-model="form[3].value"
                  :items="products"
                  defaultOption="Elegir producto"
                />
              </div>

              <div
                v-if="
                  form[5].value == LocalConstants.BannersRedirectTypes.OTHER.key
                "
              >
                <FormInput
                  type="text"
                  class="configuration-select"
                  placeholder="Ingresar URL"
                  v-model="form[3].value"
                />

                <b-form-checkbox v-model="form[7].value" class="mt-2">
                  <span class="custom-checkbox-label"
                    >Abrir en una ventana nueva</span
                  >
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="form-group-buttons text-right align-self-end pt-0 mt-auto mb-0"
      >
        <button class="button button-primary" type="submit">Guardar</button>
      </div>
    </template>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { FormInput, Alert, UploadImages, FormSelect } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import {
  ProductService,
} from "@/core/services";

import UploadImagesMixin from "@/core/mixins/UploadImagesMixin";
import ConfigurationMixin from "@/core/mixins/ConfigurationMixin";

export default {
  mixins: [UploadImagesMixin, ConfigurationMixin],
  data() {
    return {
      card1MaxWidth: 680,
      card1MaxHeight: 353,
      card2MaxWidth: 680,
      card2MaxHeight: 353,
      LocalConstants,
      form: [
        {
          identifier: "T_CARD_IMAGE_1",
          value: null,
        },
        {
          identifier: "T_CARD_IMAGE_2",
          value: null,
        },
        {
          identifier: "T_CARD_LINK_1",
          value: null,
        },
        {
          identifier: "T_CARD_LINK_2",
          value: null,
        },
        {
          identifier: "T_CARD_REDIRECT_TYPE_1",
          value: null,
        },
        {
          identifier: "T_CARD_REDIRECT_TYPE_2",
          value: null,
        },
        {
          identifier: "T_CARD_OPEN_NEW_TAB_1",
          value: null,
        },
        {
          identifier: "T_CARD_OPEN_NEW_TAB_2",
          value: null,
        },
        {
          identifier: "BANNER_TYPE",
          value: null,
        },
      ],
      bannerTypes: [
        {
          id: 1,
          text: "Estilo 1",
          image: "default.svg",
          status: true,
        },
        {
          id: 2,
          text: "Estilo 2",
          image: "style-2.svg",
          status: false,
        },
      ],
      redirectTypes: [
        LocalConstants.BannersRedirectTypes.CATEGORY,
        LocalConstants.BannersRedirectTypes.PRODUCT,
        LocalConstants.BannersRedirectTypes.OTHER,
      ],
      categories: [],
      products: [],
    };
  },
  components: {
    FormInput,
    UploadImages,
    FormSelect,
  },
  methods: {
    initialize() {
      this.categories = this.categoriesList.map(c=> ({key: c.slug, label: c.name}));
    },
    async getProducts() {
      try {
        const params = {};

        const response = await ProductService.list(params);
        this.products = response.payload.content.map((item) => {
          return {
            key: item.slug,
            label: item.name,
            slug: item.slug,
          };
        });
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async onChange(files, type) {
      if (!files.length) return;

      try {
        let uploadedFiles = await this.uploadFiles(files);

        if (!uploadedFiles.length) return;

        if (type === "T_CARD_IMAGE_1") {
          this.form[0].value = uploadedFiles[0].url;
        } else if (type === "T_CARD_IMAGE_2") {
          this.form[1].value = uploadedFiles[0].url;
        }
      } catch (error) {
        Alert.error(error);
      }
    },
    removeImage(type) {
      type == "T_CARD_IMAGE_1"
        ? (this.form[0].value = null)
        : (this.form[1].value = null);
    },
    async loadData() {
      let data = [...this.storeConfig]

      this.form.forEach((item) => {
        let field = data.find((d) => d.identifier == item.identifier);
        if (field) {
          item.value = field.value || null;
        }
      });

      let bannerTypeOption = this.form.find(
        (obj) => obj.identifier == "BANNER_TYPE"
      );

      if (bannerTypeOption) {
        this.bannerTypes.forEach((item) => {
          item.status = false;

          if (item.id == bannerTypeOption.value) {
            item.status = true;
          }
        });
      }
    },
    selectBannerType(item) {
      this.bannerTypes.forEach((obj) => (obj.status = false));
      item.status = true;

      let form = this.form.find((o) => o.identifier == "BANNER_TYPE");
      form.value = item.id;
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "app/isLoading",
      storeConfig: "app/getStoreConfig",
      categoriesList: "app/getCategories",
    }),
    sectionTitle1: function () {
      let redirectTypes = this.LocalConstants.BannersRedirectTypes;
      return this.form[4].value == redirectTypes.CATEGORY.key ||
        this.form[4].value == redirectTypes.PRODUCT.key
        ? "Seleccione"
        : "Ingrese ruta";
    },
    sectionTitle2: function () {
      let redirectTypes = this.LocalConstants.BannersRedirectTypes;
      return this.form[5].value == redirectTypes.CATEGORY.key ||
        this.form[5].value == redirectTypes.PRODUCT.key
        ? "Seleccione"
        : "Ingrese ruta";
    },
    currentBannerType() {
      return (
        this.bannerTypes.find((item) => item.status) || this.bannerTypes[0]
      );
    },
  },
  async mounted() {
    await this.$store.dispatch("app/loadCategories");
    this.initialize();
    await this.getProducts();

    this.loadData();
  },
};
</script>

<style lang="stylus">
@import '../Styles/general-configuration.styl';
</style>
