<template>
  <form class="general-configuration custom-form h-100 d-flex flex-column animate__animated animate__fadeIn" @submit.prevent="save" novalidate>
    <h2 class="section-subtitle">Color de tema</h2>

    <div class="form-container border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Color principal</label>
          <p class="label-description">Selecciona un color o ingresa un código HEX.</p>
        </div>

        <div class="form-field">
          <FormColorPicker v-model="form[0].value" />
          <!-- <form-error
          message="Color principal es requerido"
          v-if="$v.form.primaryColor.$error && !$v.form.primaryColor.required"
        ></form-error> -->

          <form-error message="Se requiere un valor exadecimal" v-if="!isHexadecimal(form[0].value)"></form-error>
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Color secundario</label>
          <p class="label-description">Selecciona un color o ingresa un código HEX.</p>
        </div>

        <div class="form-field">
          <FormColorPicker v-model="form[1].value" />
          <!-- <form-error
          message="Color principal es requerido"
          v-if="
            $v.form.secondaryColor.$error && !$v.form.secondaryColor.required
          "
        ></form-error> -->

          <form-error message="Se requiere un valor exadecimal" v-if="!isHexadecimal(form[1].value)"></form-error>
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Color etiquetas</label>
          <p class="label-description">Selecciona un color o ingresa un código HEX.</p>
        </div>

        <div class="form-field">
          <FormColorPicker v-model="form[2].value" />
          <!-- <form-error
          message="Color principal es requerido"
          v-if="$v.form.tertiaryColor.$error && !$v.form.tertiaryColor.required"
        ></form-error> -->

          <form-error message="Se requiere un valor exadecimal" v-if="!isHexadecimal(form[2].value)"></form-error>
        </div>
      </div>
    </div>

    <div class="form-group-buttons text-right mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { ConfigurationService } from "@/core/services";

// import { required } from "vuelidate/lib/validators";

import { FormColorPicker, FormError, Alert } from "wize-admin";

export default {
  data() {
    return {
      form: [
        {
          identifier: "COLOR_PRIMARY",
          value: null,
        },
        {
          identifier: "COLOR_SECONDARY",
          value: null,
        },
        {
          identifier: "COLOR_TAGS",
          value: null,
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  components: {
    FormError,
    FormColorPicker,
  },
  methods: {
    async save() {
      if (!this.isHexadecimal(this.form[0].value) || !this.isHexadecimal(this.form[1].value) || !this.isHexadecimal(this.form[2].value)) {
        setTimeout(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        }, 300);
        return;
      }

      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            identifier: item.identifier,
            value: item.value ? item.value : "",
          };
        });

        const resp = await ConfigurationService.save(params);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    isHexadecimal: function (value) {
      if (value) {
        const pattern = /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
        return pattern.test(value);
      } else {
        return true;
      }
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await ConfigurationService.list();
        let data = response.payload;
        this.form.forEach((item) => {
          let field = data.find((d) => d.identifier == item.identifier);
          if (field) {
            item.value = field.value;
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
</style>
