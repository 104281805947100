<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    dialog-class="gallery-modal"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
  >
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="bg-transparent p-0 border-0"
        @click.prevent="close()"
      >
        <CloseModalIcon class="fill-primary" />
      </button>
    </div>

    <section class="image-content d-flex justify-content-center align-items-center">
      <img :src="image" alt="Vista previa de imagen" class="gallery-modal-img">
    </section>
  </b-modal>
</template>


<script>
import {
  ModalMixin,
  CloseModalIcon,
} from "wize-admin";

export default {
  name: "ImageModal",
  mixins: [ModalMixin],
  data() {
    return {
    };
  },
  props: {
    image: String
  },
  components: {
    CloseModalIcon
  },
  methods: {
    close() {
      this.hide();
    }
  },
};
</script>
<style lang="stylus">
@import "../../styles/components/gallery-modal.styl"
</style>