<template>
  <form
    class="general-configuration custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Pedidos</h2>

    <div class="form-container">
      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Correos para recepción de pedidos</label>
          <p class="label-description">
            Ingresa máximo 3 correos electrónicos separados por comas
          </p>
        </div>

        <div class="flex-grow-1">
          <FormInput
            id="order-value"
            type="text"
            placeholder="correo@dominio.pe, correo2@dominio.com, correo3@dominio.com"
            class="w-100"
            v-model="form[6].value"
            :show-error="$v.form[6].value.$error && !$v.form[6].value.maxEmails"
          />

          <form-error
            message="Debe ingresar máximo 3 correos electrónicos"
            v-if="!$v.form[6].value.maxEmails"
          ></form-error>

          <form-error
            message="Por favor verifique que los correos electrónicos sean correctos."
            v-if="!$v.form[6].value.validEmails"
          ></form-error>
        </div>
      </div>
    </div>

    <div class="form-container">
      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container">
          <label for="min">Pedido mínimo</label>
        </div>

        <div class="form-field">
          <b-form-checkbox v-model="form[0].value" switch> </b-form-checkbox>

          <div class="mt-4" v-if="form[0].value">
            <FormInput
              id="order-value"
              type="number"
              placeholder="Ingresar pedido mínimo"
              v-model="form[1].value"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-container">
      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container">
          <label for="header">Delivery gratis</label>
        </div>

        <div class="form-field">
          <b-form-checkbox v-model="form[2].value" switch> </b-form-checkbox>

          <div class="mt-4" v-if="form[2].value">
            <FormInput
              id="delivery-amount"
              type="number"
              placeholder="Ingresar monto para delivery gratuito"
              v-model="form[3].value"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-container">
      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container">
          <label for="header">Hora máxima para pedidos</label>
        </div>

        <div class="form-field">
          <b-form-checkbox v-model="form[4].value" switch> </b-form-checkbox>

          <div class="form-datepicker mt-4" v-if="form[4].value">
            <b-form-timepicker
              v-model="form[5].value"
              locale="en"
              no-close-button
              hide-header
              placeholder="Seleccione hora máxima para pedidos"
            ></b-form-timepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="form-container  border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Notas</label>
          <p class="label-description">
            Este texto aparecerá al finalizar un pedido y también se incluirá en el correo que se envía al cliente tras realizar una compra.
          </p>
        </div>

        <div class="form-field">
          <global-trumbowyg v-model="form[7].value"/>
        </div>
      </div>
    </div>
    <div class="form-group-buttons text-right mt-auto pt-0 mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { Alert, FormInput, FormError } from "wize-admin";
import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";
import { ConfigurationService } from "@/core/services";

import { VueMaskDirective } from "v-mask";

const maxEmails = (value) => {
  if (!value || typeof value !== 'string') {
    return true;
  }

  const emailsArray = value.split(',').map(email => email.trim()).filter(email => email !== '');
  const emailsCount = emailsArray.length;

  return emailsCount <= 3;
}

const validEmails = (value) => {
  if (!value || typeof value !== 'string') {
    return true;
  }

  const emailsArray = value.split(',').map(email => email.trim());
  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const areValidEmails = emailsArray.every(email => email === '' || email.match(emailRegExp));

  return areValidEmails;
}

export default {
  data() {
    return {
      form: [
        {
          identifier: "MIN_ORDER_FLAG",
          value: false,
        },
        {
          identifier: "MIN_ORDER_VALUE",
          value: 0,
        },
        {
          identifier: "FREE_DELIVERY_FLAG",
          value: false,
        },
        {
          identifier: "FREE_DELIVERY_MIN_AMOUNT",
          value: 0,
        },
        {
          identifier: "MAX_HOUR_DELIVERY_FLAG",
          value: false,
        },
        {
          identifier: "MAX_HOUR_DELIVERY",
          value: "",
        },
        {
          identifier: "OTHER_EMAILS",
          value: null,
        },
        {
          identifier: "NEW_ORDER_NOTES",
          value: null,
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  components: {
    FormInput,
    FormError,
    GlobalTrumbowyg
  },
  directives: {
    mask: VueMaskDirective,
  },
  validations: {
    form: {
      [6]: {
        value: { maxEmails, validEmails },
      },
    }
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -100
          });
        })

        return
      }

      try {
        this.$store.commit("app/setLoading", true);

        this.form[6].value = this.form[6].value ? this.form[6].value.replace(/\s/g, "") : "";

        const params = this.form;

        const resp = await ConfigurationService.save(params);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await ConfigurationService.list();
        let data = JSON.parse(JSON.stringify(response.payload));
        this.form.forEach((item) => {
          let field = data.find((d) => d.identifier == item.identifier);

          if (field) {
            if (
              item.identifier === "MAX_HOUR_DELIVERY_FLAG" ||
              item.identifier === "MIN_ORDER_FLAG" ||
              item.identifier == "FREE_DELIVERY_FLAG"
            ) {
              field.value == "false"
                ? (item.value = false)
                : (item.value = true);
            } else {
              item.value = field.value;
            }
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
</style>
