import banksOptions from '@/core/data/banks.js';

const CustomerStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const CustomerHasBoughtValues = {
  YES: {
    key: true,
    label: "SI",
  },
  NOT: {
    key: false,
    label: "No",
  },
};

const ProductsStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const StockProductsValues = {
  YES: {
    key: true,
    label: "SI",
  },
  NOT: {
    key: false,
    label: "No",
  },
};

const ImageProductsValues = {
  YES: {
    key: true,
    label: "SI",
  },
  NOT: {
    key: false,
    label: "No",
  },
};

const UsersStatus = {
  ACTIVE: {
    key: true,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: false,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const CategoriesStatus = {
  ACTIVE: {
    key: true,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: false,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const UsersProfileValues = {
  ADMINISTRATOR: {
    key: "ADMINISTRATOR",
    label: "ADMINISTRADOR",
  },
  SALES: {
    key: "SALES",
    label: "VENTAS",
  },
  WAREHOUSE: {
    key: "WAREHOUSE",
    label: "ALMACÉN",
  },
};

const StoreStatus = {
  ACTIVE: {
    key: true,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: false,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const StoreTypes = {
  BOUTIQUE: {
    key: 1,
    label: "Boutique",
  },
  MINIMARKET: {
    key: 2,
    label: "Minimarket",
  },
};

const BooleanValues = {
  YES: {
    key: true,
    label: "SI",
  },
  NOT: {
    key: false,
    label: "NO",
  },
};

const PaymentMethods = {
  WIRE_TRANSFER: {
    key: "WIRE_TRANSFER",
    label: "Depósito bancario",
  },
  IZIPAY: {
    key: "IZIPAY",
    label: "Izipay",
    logo: "izipay.png",
  },
  NIUBIZ: {
    key: "NIUBIZ",
    label: "Niubiz",
    logo: "niubiz.png",
  },
  CULQI: {
    key: "CULQI",
    label: "Culqi",
    logo: "culqi.png",
  },
  UPON_DELIVERY: {
    key: "UPON_DELIVERY",
    label: "Contra entrega con efectivo",
  },
  UPON_DELIVERY_POS: {
    key: "UPON_DELIVERY_POS",
    label: "Contra entrega con POS",
  },
  LINK: {
    key: "LINK",
    label: "Link de pago",
  },
  YAPE: {
    key: "YAPE",
    label: "Yape",
    logo: "yape.png",
  },
  PLIN: {
    key: "PLIN",
    label: "Plin",
    logo: "plin.png",
  },
  PAGO_EFECTIVO: {
    key: "PAGO_EFECTIVO",
    label: "PagoEfectivo",
    logo: "pago-efectivo.png",
  }
}

const BankAccountTypes = {
  SAVINGS_ACCOUNT: {
    key: 1,
    label: "Cuenta de Ahorros Soles"
  },
  CURRENT_ACCOUNT: {
    key: 2,
    label: "Cuenta Corrientes Soles"
  }
}

const BanksOptions = banksOptions

const ProductsAttributes = {
  LIST: {
    key: 1,
    label: "Lista desplegable",
  },
  COLOR: {
    key: 2,
    label: "Color",
  },
  RADIO: {
    key: 3,
    label: "Radio buttons",
  },
  TEXTURE: {
    key: 4,
    label: "Texture",
  },
};

const ProductsAttributesStatus = {
  ACTIVE: {
    key: true,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: false,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const ProviderStatus = {
  ACTIVE: {
    key: true,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: false,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const BannersRedirectTypes = {
  CATEGORY: {
    key: 1,
    label: "Categoría",
  },
  PRODUCT: {
    key: 2,
    label: "Producto",
  },
  OTHER: {
    key: 3,
    label: "Otro",
  },
};

const BannersStatus = {
  ACTIVE: {
    key: true,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: false,
    label: "SUSPENDIDO",
    color: "badge-yellow",
    action: "Activar",
  },
};

const BannerTypes = {
  TIPO_1: {
    key: 1
  },
  TIPO_2: {
    key: 2
  }
}

const OrdersStatus = {
  PENDING: {
    key: "PENDING",
    label: "PENDIENTE",
    color: "badge-gray",
  },
  DELIVERED: {
    key: "DELIVERED",
    label: "ENTREGADO",
    color: "badge-green",
  },
  CANCELLED: {
    key: "CANCELLED",
    label: "ANULADO",
    color: "badge-red",
  },
  REJECTED: {
    key: "REJECTED",
    label: "RECHAZADO",
    color: "badge-red",
  },
  PAYMENT_CONFIRMED: {
    key: "PAYMENT_CONFIRMED",
    label: "PAGO CONFIRMADO",
    color: "badge-yellow",
  },
};

const BiolinkBlockTypes = [
  {
    key: "BUTTON_OR_CARD",
    label: "Card con imagen",
  },
];

const TimeRangeOptions = [
  {
    key: "00:00",
    label: "00:00",
  },
  {
    key: "00:30",
    label: "00:30",
  },
  {
    key: "01:00",
    label: "01:00",
  },
  {
    key: "01:30",
    label: "01:30",
  },
  {
    key: "02:00",
    label: "02:00",
  },
  {
    key: "02:30",
    label: "02:30",
  },
  {
    key: "03:00",
    label: "03:00",
  },
  {
    key: "03:30",
    label: "03:30",
  },
  {
    key: "04:00",
    label: "04:00",
  },
  {
    key: 7,
    label: "07:00",
  },
  {
    key: 9,
    label: "09:00",
  },
];

const addressTypes = [
  { key: 1, label: "Casa" },
  { key: 2, label: "Departamento" },
  { key: 3, label: "Condominio" },
  { key: 4, label: "Residencial" },
  { key: 5, label: "Oficina" },
  { key: 6, label: "Galería" },
  { key: 7, label: "Otro" },
];

const Plans = [
  { key: 'BASIC', label: "Plan Gratis" },
  { key: 'PRO', label: "Plan Impulso" },
  { key: 'BUSINESS', label: "Plan Ascenso" },
  { key: 'ENTERPRISE', label: "Plan Cumbre" }
];


const Genders = {
  MALE: { key: 1, label: 'HOMBRE' },
  FEMALE: { key: 2, label: 'MUJER' },
  OTHER: { key: 3, label: 'OTRO' },
  RATHER_NOT_SAY: { key: 4, label: 'PREFIERO NO DECIRLO' }
};

const ShippingTypes = {
  INDEPENDENT: {
    key: 1,
    label: 'Independiente'
  },
  COURIER: {
    key: 2,
    label: 'Courier'
  }
}

const DeliveryDaysOptions = {
  FIXED_DAYS: {
    key: 1,
    label: 'Días fijos'
  },
  BUSINESS_DAYS: {
    key: 2,
    label: 'Días hábiles'
  }
}

const ContactOptions = {
  CALL: { key: 1, label: 'Por llamada' },
  WHATSAPP: { key: 2, label: 'Por whatsapp' },
  EMAIL: { key: 3, label: 'Por email' }
}

const QuoteStatus = {
  DRAFT: {
    key: 'DRAFT',
    label: "BORRADOR",
    color: "badge-blue"
  },
  GENERATED: {
    key: 'GENERATED',
    label: "GENERADO",
    color: "badge-green"
  },
};

const RedirectionsOptions = {
  MOVED_PERMANENTLY: {
    key: 'MOVED_PERMANENTLY',
    label: '301 - Movido permanentemente'
  },
  FOUND: {
    key: 'FOUND',
    label: '302 - Encontrado'
  },
  TEMPORARY_REDIRECT: {
    key: 'TEMPORARY_REDIRECT',
    label: '307 - Redirección temporal'
  },
  GONE: {
    key: 'GONE',
    label: '410 - Contenido eliminado'
  },
  UNAVAILABLE_FOR_LEGAL_REASONS: {
    key: 'UNAVAILABLE_FOR_LEGAL_REASONS',
    label: '451 - No disponible por motivos legales'
  }
}

export default {
  CustomerStatus,
  CustomerHasBoughtValues,
  ProductsStatus,
  StockProductsValues,
  ImageProductsValues,
  UsersStatus,
  UsersProfileValues,
  StoreStatus,
  StoreTypes,
  BooleanValues,
  CategoriesStatus,
  ProductsAttributes,
  ProductsAttributesStatus,
  ProviderStatus,
  BannersRedirectTypes,
  BannersStatus,
  BannerTypes,
  OrdersStatus,
  BiolinkBlockTypes,
  TimeRangeOptions,
  addressTypes,
  Genders,
  ShippingTypes,
  DeliveryDaysOptions,
  PaymentMethods,
  BankAccountTypes,
  BanksOptions,
  ContactOptions,
  QuoteStatus,
  RedirectionsOptions,
  Plans
};
