<template>
  <div class="payment-details">
    <img src="@/core/assets/images/payments/izipay-lg.png" alt="Logo Izipay" class="logo rounded-circle" />


    <HelpCollapse>
      <template slot="content">
        <div class="help-collapse-content">
          <p>
            Para empezar a recibir pagos a través de Izipay, por favor completa el siguiente formulario.
          </p>
          <p>
            Por motivos de seguridad, cuando vuelvas a ingresar a este formulario, no verás cargada la información sensible y deberás actualizarla nuevamente en caso haya cambiado.
          </p>
        </div>
      </template>
    </HelpCollapse>

    <form action="" class="custom-form mt-4" @submit.prevent="save()">
      <div class="form-group">
        <label for="izipay-test">Usuario *</label>
        <FormInput id="izipay-test" type="text" placeholder="Ingresar usuario"
          :showError="$v.form.user.$error && !$v.form.user.required" v-model="form.user" autocomplete="one-time-code"/>
        <form-error message="Usuario es requerido" v-if="$v.form.user.$error && !$v.form.user.required"></form-error>
      </div>

      <div class="form-group">
        <label for="izipay-pas">Contraseña *</label>
        <FormInput id="izipay-pas" type="password" placeholder="Ingresar contraseña" autocomplete="one-time-code"
          :showError="$v.form.password.$error && !$v.form.password.required" v-model="form.password" />
        <form-error message="Contraseña es requerida"
          v-if="$v.form.password.$error && !$v.form.password.required"></form-error>
      </div>

      <div class="form-group">
        <label for="publicKey">Clave Pública JavaScript *</label>
        <FormInput id="publicKey" type="text" placeholder="Ingresar clave pública JavaScript"
          :showError="$v.form.publicKey.$error && !$v.form.publicKey.required" v-model="form.publicKey" />
        <form-error message="Usuario es requerido"
          v-if="$v.form.publicKey.$error && !$v.form.publicKey.required"></form-error>
      </div>

      <div class="form-group">
        <label for="secretKey">Clave HMAC SHA 256 *</label>
        <FormInput id="secretKey" type="text" placeholder="Ingresar clave HMAC SHA 256"
          :showError="$v.form.secretKey.$error && !$v.form.secretKey.required" v-model="form.secretKey" />
        <form-error message="Clave HMAC SHA 256 es requerido"
          v-if="$v.form.secretKey.$error && !$v.form.secretKey.required"></form-error>
      </div>

      <div class="form-group">
        <label for="env">Entorno *</label>
        <div class="d-flex">
          <b-form-radio v-model="form.env" name="env" value="PRODUCTION">Producción</b-form-radio>
          <b-form-radio v-model="form.env" name="env" value="INTEGRATION" class="ml-2">Integración</b-form-radio>
        </div>
      </div>

      <div class="form-group-buttons text-right">
        <router-link :to="{ name: 'config-payment-details', params: { slug: slug } }" class="button button-light">
          Volver
        </router-link>
        <button class="button button-primary" type="submit">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { FormError, FormInput } from "wize-admin";

import HelpCollapse from "./HelpCollapse.vue";
import PaymentMethodEntityMixin from "@/core/mixins/PaymentMethodEntityMixin"

export default {
  mixins: [PaymentMethodEntityMixin],
  data() {
    return {
      slug: this.$route.params.slug,
      form: {
        id: "IZIPAY",
        user: null,
        password: null,
        publicKey: null,
        secretKey: null,
        isActive: false,
        env: "INTEGRATION",
      },
    };
  },
  async mounted() {
    await this.load();
    this.form.id = "IZIPAY";
  },
  validations: {
    form: {
      user: { required },
      password: { required },
      publicKey: { required },
      secretKey: { required }
    },
  },
  components: {
    FormInput,
    FormError,
    HelpCollapse
  }
};
</script>

<style lang="stylus" scoped>
@import "../../Styles/payment-details.styl"
</style>
