<template>
  <div class="page-container menu-settings">
    <div class="page-title-container">
      <h1 class="page-title">Configuración de menú</h1>
    </div>

    <section class="page-content">
      <aside>
        <b-card class="custom-card">
          <div class="custom-card-header position-relative">
            <h3>Categorías</h3>
          </div>

          <div class="custom-card-body p-4">
            <b-form-checkbox :id="`checkbox-${i}`" v-model="item.selected" :name="`checkbox-${i}`"
              v-for="(item, i) in mainList" :key="i" class="custom-checkbox mb-3">
              {{ item.name }}
            </b-form-checkbox>

            <div class="custom-form text-right mt-3 p-0" v-if="mainList.length">
              <button type="button" class="button button-light" @click="addCategories">
                Agregar al menú
              </button>
            </div>
          </div>
        </b-card>
      </aside>

      <div class="mt-4 mt-lg-0">
        <b-card class="custom-card">
          <b-card-body class="p-4">
            <h4 class="small font-weight-normal my-0">
              Coloca los elementos en el orden que prefieras.
            </h4>

            <div class="custom-card-body list py-4 px-0 d-flex flex-column justify-content-between">
              <div @dragover.prevent @dragenter.prevent @drop="($event) => { }" @mouseleave="resetDragAndDropItems()">
                <CategoryItem :category="item" draggable @dragstart="startDrag($event, index)"
                  @drop="onDropItem($event, index)" @dragover="addHighlightedToDropElement(index)"
                  @dragleave="item.drop = false" v-for="(item, index) in visibleCategoriesList" :key="item.id"
                  @remove="removeCategory($event)" :class="{ drag: item.drag, drop: item.drop }" />
              </div>

              <div class="text-right" v-if="visibleCategoriesList.length">
                <button class="button button-primary" type="button" @click="updateVisibleCategories()">
                  Guardar
                </button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </section>
  </div>
</template>

<script>
import {
  Alert
} from "wize-admin";

import { CategoryService } from "@/core/services";
import { mapGetters } from "vuex";
import CategoryItem from "../Components/CategoryItem";

export default {
  data() {
    return {
      mainList: [],
      mainListCopy: [],
      visibleCategoriesList: [],
      tooltipType: null,
      selectedOption: null,
    };
  },
  components: {
    CategoryItem,
  },
  computed: {
    ...mapGetters({
      categories: "app/getCategories",
    }),
  },
  methods: {
    async loadData() {
      this.mainList = this.categories.filter((item) => !item.parent).map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      this.mainListCopy = [...this.mainList];
    },
    async getVisibleCategories() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await CategoryService.listVisibleCategories();
        this.visibleCategoriesList = response.payload.map((item) => {
          return {
            ...item,
            selected: false,
            drag: false,
            drop: false
          };
        });

        // Eliminar categorías visibles, antes de mostrar lista principal
        this.visibleCategoriesList.forEach((item) => {
          let i = this.mainList.findIndex((c) => item.id == c.id);
          this.mainList.splice(i, 1);
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async addCategories() {
      let list = this.mainList.filter((item) => item.selected);
      this.visibleCategoriesList.push(...list);

      list.forEach((item) => {
        let i = this.mainList.indexOf(item);
        this.mainList.splice(i, 1);
      });
    },
    removeCategory(id) {
      // Eliminar de categorías visibles
      let index = this.visibleCategoriesList.findIndex((item) => item.id == id);
      this.visibleCategoriesList.splice(index, 1);

      // Agregar a lista principal
      let item = this.mainListCopy.find((item) => item.id == id);
      this.mainList.push({
        ...item,
        selected: false,
      });
    },
    async updateVisibleCategories() {
      this.$store.dispatch("app/loading", true);

      try {
        let categories = this.visibleCategoriesList.map((c, i) => {
          return {
            id: c.id,
            position: i,
          };
        });

        const response = await CategoryService.updateVisibleCategories({
          categories,
        });

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    startDrag(evt, index) {
      let list = [...this.visibleCategoriesList];
      list[index].drag = true;
      this.visibleCategoriesList = list;

      evt.dataTransfer.setData("dragIndex", index);
    },
    onDropItem(evt, index) {
      let indexInt = parseInt(index);
      let dragIndex = parseInt(evt.dataTransfer.getData("dragIndex"));

      let list = [...this.visibleCategoriesList];

      let dropItemCopy = { ...list[indexInt] };

      list[indexInt] = list[dragIndex];
      list[dragIndex] = dropItemCopy;

      list.forEach((item) => {
        item.drag = false;
        item.drop = false
      });

      this.visibleCategoriesList = list;
    },
    addHighlightedToDropElement(index) {
      let list = [...this.visibleCategoriesList];

      list.forEach((item) => {
        item.drop = false;

        list[index].drop = true
      });

      this.visibleCategoriesList = list;
    },
    resetDragAndDropItems() {
      this.visibleCategoriesList.forEach((item) => {
        item.drag = false;
        item.drop = false;
      });
    }
  },
  async mounted() {
    await this.$store.dispatch("app/loadCategories", { sort: "name,asc", });
    await this.loadData();
    await this.getVisibleCategories();
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/menu-settings.styl"
</style>
