import AppearanceView from "../AppearanceView.vue";
import MenuSettings from "../Pages/MenuSettings.vue";

const routes = [
  {
    path: '/apariencia',
    component: AppearanceView,
    plan: ['ALL'],
    name: 'appearance',
    children: [
      {
        path: 'menus',
        name: 'appearance-menus',
        plan: ['ALL'],
        component: MenuSettings
      },
    ]
  },
]

export default routes
