<template>
  <div class="form-section">
    <h2 class="custom-card-subtitle">Precio al por mayor</h2>
    <p class="custom-card-description">
      Esta opción es exclusiva para tiendas tipo Minimarket.
    </p>

    <section v-if="storeType == minimarketValue">
      <div class="row product-conbination-row mb-0 pb-0" v-for="(item, i) in itemsLocal" :key="i">
        <div class="col-md-5 mb-0 pb-0">
          <div class="form-group">
            <label for="product-combination-price">Desde</label>
            <FormInput id="product-combination-price" type="number" min="0" placeholder="Ingresar cantidad"
              maxlength="9" v-model="item.min" />
          </div>
        </div>
        <div class="col-md-5 mb-0 pb-0">
          <div class="form-group">
            <label for="product-combination-price">Precio unitario</label>
            <FormInput id="product-combination-price" type="number" min="0" placeholder="Ingresar precio" maxlength="9"
              v-model="item.price" />
          </div>
        </div>

        <div class="col-md-2 d-flex justify-content-center align-items-center">
          <div>
            <button type="button"
              class="remove-item bg-danger rounded-circle border-0 d-flex justify-content-center align-items-center"
              @click="deleteItem(i)">
              <TrashIcon class="fill-white trash-icon" />
            </button>
          </div>
        </div>
      </div>
      <p class="mb-0" v-if="storeType == minimarketValue">
        <PlusIcon class="fill-primary" />
        <a href="" class="add-link font-weight-bold ml-2" @click.prevent="addItem()">Agregar</a>
      </p>
    </section>
  </div>
</template>

<script>
import {
  FormInput,
  PlusIcon,
  TrashIcon
} from "wize-admin";
import { Constants as LocalConstants } from "@/core/utils";
import { mapGetters } from "vuex";

export default {
  name: "PriceVolumeEditor",
  data() {
    return {
      itemsLocal: [],
      minimarketValue: LocalConstants.StoreTypes.MINIMARKET.key,
    };
  },
  components: {
    FormInput,
    PlusIcon,
    TrashIcon
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapGetters({
      getConfValue: "app/getConfValue",
    }),
    storeType() {
      const storeType = this.getConfValue("STORE_TYPE")
      return storeType ? storeType : null;
    },
  },
  watch: {
    value(value) {
      this.itemsLocal = value;
    },
  },
  methods: {
    changeValue() {
      this.$emit("input", this.itemsLocal);
    },
    addItem() {
      this.itemsLocal.push({
        min: null,
        price: null,
      });
      this.$emit("input", this.itemsLocal);
    },
    deleteItem(index) {
      this.itemsLocal.splice(index, 1);
      this.$emit("input", this.itemsLocal);
    }
  },
  created() {
    if (this.value !== null) {
      this.itemsLocal = this.value;
    }
  }
};
</script>

<style lang="stylus">

</style>
