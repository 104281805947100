import ClientsView from "../ClientsView.vue";
import ClientsList from "../Pages/ClientsList.vue";
import ClientsEditor from "../Pages/ClientsEditor.vue";
import DiscountsList from "../Pages/DiscountsList.vue";
import ClientDetails from '../Pages/ClientDetails.vue'

const routes = [
  {
    path: '/clientes',
    component: ClientsView,
    name: 'clients',
    plan: ['ALL'],
    children: [
      {
        path: '',
        name: 'clients-list',
        plan: ['ALL'],
        component: ClientsList
      },
      {
        path: 'crear',
        name: 'clients-create',
        plan: ['ALL'],
        component: ClientsEditor
      },
      {
        path: ':id/editar',
        name: 'clients-edit',
        plan: ['ALL'],
        component: ClientsEditor
      },
      {
        path: ':id',
        name: 'clients-details',
        plan: ['ALL'],
        component: ClientDetails
      },
      {
        path: 'descuentos',
        name: 'clients-discounts',
        plan: ['ALL'],
        component: DiscountsList
      },
    ]
  },
]

export default routes
