import {http} from '@/http'

export default {
  getUbigeos() {
    return http.get('/api/v1/admin/ubigeo');
  },
  async getUbigeosMap() {
    const response = (await http.get('/api/v1/admin/ubigeo')).payload;
    return response.map((o) => {
      return {key: o.id, label: o.description, parentId: o.parentId};
    });
  },
  async getChildren(items, parentId) {
    return await items.reduce(function(filtered, option) {
      if (option.parentId === parentId) {
        filtered.push(option);
      }
      return filtered;
    }, []);
  }
}
