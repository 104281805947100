import { http } from "@/http";

export default {
  list() {
    return http.get("/api/v1/admin/payment_method")
  },
  get(id) {
    return http.get(`/api/v1/admin/payment_method/${id}`);
  },
  save(payload) {
    return http.post('/api/v1/admin/payment_method', payload);
  },
  updatePaymentMethodActive(id, isActive) {
    return http.put(`/api/v1/admin/payment_method/${id}/active`, null, {
      params: {
        isActive,
      },
    });
  }
};
