<template>
  <div class="page-container redirections-list">
    <div class="page-title-container">
      <h1 class="page-title">Búsquedas en el sitio</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar búsquedas</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="download()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar"
                @click="download()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por palabra de búsqueda"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Palabra de búsqueda</span>
            </div>
            <div>
              <span>Total de búsquedas únicas</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid text-center">
                <div>
                  <span>{{ o.query }}</span>
                </div>

                <div>
                  <span>{{ o.quantity }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th class="pl-md-3">Palabra de búsqueda</th>
              <th class="pl-md-4">Total de búsquedas únicas</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="pl-4">
                {{ o.query }}
              </td>
              <td class="text-center">{{ o.quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  PaginationMixin,
  FilterMixin,
  SearchBar,
  // FilterMenu,
  ConfirmationModalMixin,
  ConfirmationModal,
} from "wize-admin";

import { RedirectService } from "@/core/services";

import FilterMenu from "@/FilterMenu.vue";

export default {
  components: {
    SearchBar,
    FilterMenu,
    ConfirmationModal,
  },
  mixins: [PaginationMixin, FilterMixin, ConfirmationModalMixin],
  data() {
    return {
      list: [
        {
          id: 1,
          query: "Venta de productos al por mayor",
          quantity: 158,
        },
        {
          id: 2,
          query: "Ofertas",
          quantity: 400,
        },
        {
          id: 3,
          query: "Arroz",
          quantity: 500,
        },
      ],
      selectedOption: null,
      filterOptions: [
        {
          label: "Intervalo de fechas",
          key: "date",
          type: Constants.TypeFilters.SINGLE,
          options: [
            {
              key: 1,
              label: "Últimos 7 días",
            },
            {
              key: 2,
              label: "Éste mes",
            },
            {
              key: 3,
              label: "Mes pasado",
            },
            {
              key: 4,
              label: "Últimos 3 meses",
            },
            {
              key: 5,
              label: "Últimos 6 meses",
            },
          ],
          defaultOptions: [
            {
              key: 1,
            },
          ],
        }
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          search: this.search,
          ...this.filters,
        };
        const response = await RedirectService.list(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    }
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    // await this.loadData();
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/redirections-list.styl';
</style>
