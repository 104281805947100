var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"page-sidebar-mobile-container"},[_c('div',{staticClass:"page-sidebar-mobile-overlay",class:{ active: _vm.show },on:{"click":function($event){return _vm.toggleMenu(false)}}}),_c('div',{staticClass:"page-sidebar-mobile d-flex flex-column d-lg-none",class:{ active: _vm.show }},[_c('div',{staticClass:"header d-flex align-items-center"},[_c('b-avatar',{staticClass:"avatar",attrs:{"src":"https://assets.admin.wize.pe/images/dropdown-user-avatar.svg"}}),_c('div',{staticClass:"d-flex flex-column pl-3"},[_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.username))]),_c('span',{staticClass:"email d-inline-block mt-1"},[_vm._v(_vm._s(_vm.user.email))])])],1),(_vm.$can('manage', 'general-configuration'))?_c('router-link',{staticClass:"menu-option d-flex align-items-center",class:{ active: _vm.$route.name == 'general-configuration' },attrs:{"to":{ name: 'general-configuration' }}},[_c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-global-line"})]),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Visitar sitio web")])]):_vm._e(),_c('div',{staticClass:"grid mt-2 px-2"},[_c('router-link',{staticClass:"link d-flex flex-column justify-content-center align-items-center text-white text-center",class:{ active: _vm.$route.name == 'dashboard' },attrs:{"to":{ name: 'dashboard' }}},[_c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-home-5-line"})]),_vm._v(" Portada ")]),(_vm.$can('manage', 'orders'))?_c('router-link',{staticClass:"link d-flex flex-column justify-content-center align-items-center text-white text-center",class:{
          active: _vm.$route.name == 'orders-list',
        },attrs:{"to":{ name: 'orders-list' }}},[_c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-shopping-cart-line"})]),_vm._v(" Pedidos ")]):_vm._e(),(_vm.$can('manage', 'clients-list'))?_c('button',{staticClass:"link d-flex flex-column justify-content-center align-items-center text-white text-center",class:{
          active:
            _vm.selection == 'clients-list' || _vm.$route.name == 'clients-list',
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSelection('clients-list')}}},[_vm._m(0),_vm._v(" Clientes ")]):_vm._e(),(_vm.$can('manage', 'products'))?_c('button',{staticClass:"link d-flex flex-column justify-content-center align-items-center text-white text-center",class:{
          active: _vm.isProductsRoute,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSelection('products')}}},[_vm._m(1),_vm._v(" Productos ")]):_vm._e(),(_vm.$can('manage', 'config'))?_c('button',{staticClass:"link d-flex flex-column justify-content-center align-items-center text-white text-center",class:{ active: _vm.isConfigRoute },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSelection('config')}}},[_vm._m(2),_vm._v(" Mantenimiento ")]):_vm._e(),(_vm.$can('manage', 'appearance'))?_c('button',{staticClass:"link d-flex flex-column justify-content-center align-items-center text-white text-center",class:{
          active: _vm.isAppearanceRoute,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSelection('appearance')}}},[_vm._m(3),_vm._v(" Apariencia ")]):_vm._e(),(_vm.$can('manage', 'tools'))?_c('button',{staticClass:"link d-flex flex-column justify-content-center align-items-center text-white text-center",class:{ active: _vm.isToolsRoute },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSelection('tools')}}},[_vm._m(4),_vm._v(" Herramientas ")]):_vm._e()],1),_c('div',{staticClass:"menu-list-container"},[(_vm.selection)?_c('ul',{staticClass:"menu-list list-unstyled"},_vm._l((_vm.getMenuItems),function(item){return _c('li',{key:item.text},[(_vm.$can('manage', item.manage))?_c('router-link',{staticClass:"menu-subitem text-decoration-none",attrs:{"to":item.route,"exact-active-class":"active"}},[_c('BulletIcon',{staticClass:"fill-white mr-3"}),_vm._v(_vm._s(item.text))],1):_vm._e()],1)}),0):_vm._e()]),(_vm.$can('manage', 'general-configuration'))?_c('router-link',{staticClass:"menu-option d-flex align-items-center",class:{ active: _vm.$route.name == 'general-configuration' },attrs:{"to":{ name: 'general-configuration' }}},[_c('ConfigIcon',{staticClass:"fill-white mr-4"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Configuración general")])],1):_vm._e(),_c('div',{staticClass:"menu-option classic plan d-flex align-items-center"},[_c('span',{staticClass:"sidebar-item-text"},[_vm._v(_vm._s(_vm.getPlan().label))])]),_c('button',{staticClass:"menu-option d-flex align-items-center",attrs:{"type":"button"},on:{"click":function($event){return _vm.logout()}}},[_c('LogoutIcon',{staticClass:"fill-white mr-4"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Cerrar sesión")])],1),_c('div',{staticClass:"menu-option classic version d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"text"},[_vm._v("V"+_vm._s(_vm.version)+" "+_vm._s(_vm.year))]),_vm._m(5)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-team-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-shopping-bag-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-edit-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-paint-brush-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"ri-hammer-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"my-0"},[_vm._v(" Forjado por "),_c('a',{attrs:{"href":"https://wize.pe","target":"_blank"}},[_vm._v("Wize")])])}]

export { render, staticRenderFns }