import GeneralConfigurationView from "../GeneralConfigurationView.vue";
import GeneralConfigurationPage from "../Pages/GeneralConfigurationPage.vue";

const routes = [
  {
    path: '/configuracion',
    component: GeneralConfigurationView,
    children: [
      {
        path: '',
        name: 'general-configuration',
        component: GeneralConfigurationPage,
        plan: ['ALL'],
      },
    ]
  }
]

export default routes
