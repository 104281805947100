<template>
  <div class="payment-details">
    <HelpCollapse class="d-none">
      <template slot="content">
        <div class="help-collapse-content">
          <p>
            Te ofrecemos la posibilidad de solicitar
            <span class="font-weight-bold">depósitos bancarios y transferencias interbancarias</span>. Al activar esta
            forma de pago el carrito de compras generará una
            orden de pedido indicando los datos de la cuenta donde debe
            realizarse el abono. No tenemos forma automática de registrar estos
            pagos en nuestro sistema, por lo que deberás registrarlos
            manualmente.
          </p>

          <p>
            Por favor completa el siguiente formulario para comenzar a acepar
            depósitos bancarios y transferencias.
          </p>
        </div>
      </template>
    </HelpCollapse>

    <form action="" class="custom-form" @submit.prevent="save()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="bank">Banco</label>
            <FormInput id="bank" type="text" placeholder="Ingresar banco" v-model="bankInfo.name" disabled />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="bank">Tipo de cuenta * </label>
            <FormSelect id="accountType" v-model="form.accountType" :items="bankAccountTypes"
              defaultOption="Elegir tipo de cuenta" :showError="$v.form.accountType.$error && !$v.form.accountType.required" />
            <form-error message="Tipo de cuenta es requerido"
              v-if="$v.form.accountType.$error && !$v.form.accountType.required"></form-error>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="bank">Tipo de documento *</label>
            <FormSelect id="identifierType" v-model="form.identifierType" :items="identifierTypes"
              defaultOption="Elegir tipo de documento" :showError="$v.form.identifierType.$error &&
      !$v.form.identifierType.required
      " />

            <form-error message="Tipo de documento es requerido" v-if="$v.form.identifierType.$error &&
      !$v.form.identifierType.required
      "></form-error>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="identifier">Número de documento *</label>
            <FormInput id="identifier" type="text" placeholder="Ingresar número de documento" :showError="$v.form.identifier.$error && !$v.form.identifier.required
      " v-model="form.identifier" />
            <form-error message="Número de documento es requerido"
              v-if="$v.form.identifier.$error && !$v.form.identifier.required"></form-error>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="bank">Titular de la cuenta *</label>
            <FormInput id="businessName" type="text" placeholder="Ingresar titular de la cuenta" :showError="$v.form.businessName.$error && !$v.form.businessName.required
      " v-model="form.businessName" />
            <form-error message="Titular de la cuenta" v-if="$v.form.businessName.$error && !$v.form.businessName.required
      "></form-error>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="accountNumber">N° de cuenta *</label>
            <FormInput id="accountNumber" type="text" placeholder="Ingresar número de cuenta " :showError="$v.form.accountNumber.$error && !$v.form.accountNumber.required
      " v-model="form.accountNumber" />
            <form-error message="N° de cuenta es requerido" v-if="$v.form.accountNumber.$error && !$v.form.accountNumber.required
      "></form-error>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="interAccountNumber">N° código interbancario (CCI) *</label>
            <FormInput id="interAccountNumber" type="text" placeholder="Ingresar código interbancario (CCI)" :showError="$v.form.interAccountNumber.$error &&
      !$v.form.interAccountNumber.required
      " v-model="form.interAccountNumber" />
            <form-error message="N° código interbancario (CCI) es requerido" v-if="$v.form.interAccountNumber.$error &&
      !$v.form.interAccountNumber.required
      "></form-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" :class="{
      'texteditor-error':
        $v.form.instructions.$error && !$v.form.instructions.required,
    }">
            <label for="instructions">Instrucciones para el cliente *</label>
            <global-trumbowyg v-model="form.instructions"/>
            <form-error message="Instrucciones para el cliente son requeridas"
              v-if="$v.form.instructions.$error && !$v.form.instructions.required"></form-error>
          </div>
        </div>
      </div>
      <div class="form-group-buttons text-right">
        <router-link :to="{ name: 'config-payment-details', params: { slug } }" class="button button-light">
          Volver
        </router-link>
        <button class="button button-primary" type="submit">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Constants, FormInput, FormSelect, FormError, Alert } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";

import { PaymentService } from "@/core/services";

import HelpCollapse from "./HelpCollapse.vue";

export default {
  data() {
    return {
      slug: this.$route.params.slug,
      form: {
        // id: "WIRE_TRANSFER",
        id: null,
        bankId: null,
        businessName: null,
        accountType: null,
        identifierType: null,
        identifier: null,
        bank: null,
        interAccountNumber: null,
        accountNumber: null,
        instructions: null,
        isEnabled: false,
      },
      identifierTypes: [
        Constants.IdentifierType.DNI,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.PASSPORT,
        {
          key: 5,
          label: "RUC",
        },
      ],
      bankAccountTypes: [
        LocalConstants.BankAccountTypes.SAVINGS_ACCOUNT,
        LocalConstants.BankAccountTypes.CURRENT_ACCOUNT,
      ],
      wireTransferInfo: null,
      bankInfo: {},
    };
  },
  mounted() {
    this.load();
  },
  validations: {
    form: {
      businessName: { required },
      accountType: { required },
      identifierType: { required },
      identifier: { required },
      accountNumber: { required },
      interAccountNumber: { required },
      instructions: { required },
    },
  },
  components: {
    HelpCollapse,
    FormInput,
    FormSelect,
    FormError,
    GlobalTrumbowyg
  },
  methods: {
    getBankInfo() {
      // Obtener información local en base a slug de entidad
      let banks = [];
      LocalConstants.BanksOptions.forEach((item) => banks.push(...item.banks));

      this.bankInfo =
        banks.find((item) => item.slug == this.$route.params.entitySlug) || {};
    },
    async load() {
      try {
        this.$store.dispatch("app/loading", true);

        this.getBankInfo();

        const response = await PaymentService.get(this.slug);
        this.wireTransferInfo = response.payload;

        if (!this.wireTransferInfo.publicData) {
          this.wireTransferInfo.publicData = [];
        }

        if (this.wireTransferInfo.publicData.length > 0) {
          let existsItem = this.wireTransferInfo.publicData.find(
            (item) => item.bankId == this.bankInfo.id
          );

          if (existsItem) {
            this.form = existsItem;
          }
        }

        this.editing = true;
      } catch (e) {
        console.error(e);
        if (!this.wireTransferInfo) {
          this.wireTransferInfo = {publicData: []};
        }
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async save() {
      const self = this;
      self.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      try {
        this.$store.commit("app/setLoading", true);

        let list = this.wireTransferInfo.publicData;
        const exists = list.find((item) => item.bankId == this.bankInfo.id);

        const item = {
          ...this.form,
          bank: this.bankInfo.name,
          bankId: this.bankInfo.id,
        };

        if (this.$route.query && !!this.$route.query.activate) {
          item.isEnabled = this.$route.query.activate
        }

        if (!exists) {
          list.push(item);
        } else {
          let index = list.findIndex((item) => item.bankId == this.bankInfo.id);
          list[index] = item;
        }

        let data = {
          id: this.$route.params.slug,
          banks: list,
          isActive: true,
        };

        const resp = await PaymentService.save(data);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus">
@import '../../Styles/payment-details.styl';
</style>
