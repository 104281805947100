import moment from "moment"
import "moment/locale/es";
moment.locale("es");

import { Util, Constants } from 'wize-admin'

// Generar horas con intervalos de 30 min
const generateHours = () => {
  let schedules = [];
  let hour = moment().startOf('day'); // Establecer la hora en 00:00 del día actual

  const minutesInterval = 30;

  while (hour.isSame(moment(), 'day')) {
    let formattedTime = hour.format('HH:mm');

    schedules.push({
      key: formattedTime,
      label: formattedTime,
    });

    hour.add(minutesInterval, 'minutes');
  }

  return schedules;
};

function sluglify(text) {
  // Reemplazar caracteres especiales y tildes
  const normalizedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return normalizedText
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars (including _)
    .replace(/-+/g, "-") // Replace multiple - with single -
    .replace(/^-+|-+$/g, ""); // Trim - from start and end of text
}

function sliceText(text, length) {
  if (!text) return "-";

  if (text.length > length) return text.slice(0, length) + "...";

  return text;
}

function formatPrice(value) {
  if(!value) return 0

  let price = Util.formatNumber(value)
  const currency = Constants.Currencies['PEN']

  return `${currency} ${price}`
}

function localDate(value, format) {
  return moment(value).format(format)
}

function capitalizeName(name) {
  if(!name) return '';
  return name.toLowerCase().replace(/\b\w/g, function(char) {
    return char.toUpperCase();
  });
}

export default {
  generateHours,
  sluglify,
  sliceText,
  formatPrice,
  localDate,
  capitalizeName
};
