import { Util } from "wize-admin";

import { AssetService } from "@/core/services";

export default {
  methods: {
    async uploadFiles(files) {
      try {
        let filesToUpload = files.filter(
          (item) => !item.uploaded && item.validatedDimensions
        );

        if (filesToUpload.length) {
          const data = Util.getFormData({});

          filesToUpload.forEach(async (item) => {
            data.append("assets", item.file);
            item.loading = true;
            item.uploaded = true;
          });

          let response = await AssetService.save(data);

          filesToUpload.forEach((item) => (item.loading = false));

          return response.payload;
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error al subir imágenes");
        console.error(error);
      }
    },
  },
};
