import Vue from "vue";
import {Constants, Util} from 'wize-admin'
import * as moment from 'moment';


function decimalFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    const formattedValue = Util.formatNumber(binding.value);
    if (binding.arg) {
      const currency = Constants.Currencies[binding.arg.toUpperCase()]
      el.innerHTML = `${currency} ${formattedValue}`;
    } else
      el.innerHTML = formattedValue
  } else {
    el.innerHTML = null
  }
}

function intFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    el.innerHTML = binding.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    el.innerHTML = null
  }
}

function localDateFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    el.innerHTML = moment(binding.value).format(binding.arg !== 'hour' ? "DD/MM/YYYY" : "DD/MM/YYYY hh:mm A");
  } else {
    el.innerHTML = null
  }
}

function percentageFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    el.innerHTML = `${binding.value}%`
  } else {
    el.innerHTML = null
  }
}

Vue.directive('int', {inserted: intFun, update: intFun});
Vue.directive('localDate', {inserted: localDateFun, update: localDateFun});
Vue.directive('decimal', {inserted: decimalFun, update: decimalFun});
Vue.directive('percentage', {inserted: percentageFun, update: percentageFun});
