import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/admin/category", { params });
  },
  listVisibleCategories() {
    return http.get('/api/v1/admin/category/visible')
  },
  get(id) {
    return http.get(`/api/v1/admin/category/${id}`);
  },
  save(payload) {
    return http.post("/api/v1/admin/category", payload);
  },
  update(id, payload) {
    return http.put(`/api/v1/admin/category/${id}`, payload);
  },
  toggleStatus(id, isActive ) {
    return http.put(`/api/v1/admin/category/${id}/status`, null, {
      params: {
        isActive
      }
    });
  },
  delete(id) {
    return http.delete(`/api/v1/admin/category/${id}`);
  },
  updateVisibleCategories(payload) {
    return http.put('/api/v1/admin/category/visible', payload)
  }
};
