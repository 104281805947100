var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container form-editor"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar redirección" : "Nuevo redirección")+" ")])]),_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos de la redirección" : "Datos de la nueva redirección")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body px-0 pb-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section py-0 border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipo de redirección *")]),_c('FormSelect',{attrs:{"id":"type","items":_vm.redirectTypes,"defaultOption":"Elegir tipo de redirección","showError":_vm.$v.form.type.$error},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(
                          _vm.$v.form.type.$error &&
                          !_vm.$v.form.type.required
                        )?_c('form-error',{attrs:{"message":"Tipo de redirección es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"from"}},[_vm._v("URL antigua *")]),_c('FormInput',{attrs:{"id":"from","type":"text","placeholder":"Ingresar url antigua","maxlength":"150","show-error":(_vm.$v.form.from.$error &&
                            !_vm.$v.form.from.required) ||
                          (_vm.$v.form.from.$error &&
                            !_vm.$v.form.from.maxLength)},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}}),(
                          _vm.$v.form.from.$error && !_vm.$v.form.from.required
                        )?_c('form-error',{attrs:{"message":"URL antigua es requerida"}}):_vm._e()],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"to"}},[_vm._v("URL nueva "+_vm._s(_vm.disableTo ? '' : '*')+" ")]),_c('FormInput',{attrs:{"id":"to","maxlength":"250","type":"text","disabled":_vm.disableTo,"placeholder":"Ingresar URL nueva","show-error":_vm.$v.form.to.$error && !_vm.$v.form.to.required},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}}),(
                          _vm.$v.form.to.$error && !_vm.$v.form.to.required
                        )?_c('form-error',{attrs:{"message":"URL nueva es requerida"}}):_vm._e()],1)])])]),_c('div',{staticClass:"form-section border-0 pt-0"},[_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'tools-redirections-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }