<template>
  <section>
    <Loader v-if="loading"/>

    <b-form class="form-login custom-form text-left" @submit.prevent="submit">
      <div class="form-group">
        <label for="email">Correo electrónico</label>

        <FormInput
          id="email"
          type="email"
          maxlength="100"
          placeholder="Ingresa tu correo electrónico"
          :show-error="$v.form.username.$error && !$v.form.username.required"
          v-model="form.username"
        />

        <form-error
          message="Correo electrónico es requerido"
          v-if="$v.form.username.$error && !$v.form.username.required"
        ></form-error>
      </div>

      <div class="form-group">
        <label for="password">Contraseña</label>

        <FormInput
          id="password"
          type="password"
          placeholder="Ingresa tu contraseña"
          :show-error="$v.form.password.$error && !$v.form.password.required"
          v-model="form.password"
        />

        <form-error
          message="Contraseña es requerida"
          v-if="$v.form.password.$error && !$v.form.password.required"
        ></form-error>

        <form-error :message="error" v-if="error"></form-error>
      </div>

      <div class="form-group">
        <b-form-checkbox id="clear-all-check" v-model="form.remember">
          <span class="font-weight-medium custom-checkbox-label"
          >Recordar datos</span
          >
        </b-form-checkbox>
      </div>

      <b-button
        class="button button-blue button-login"
        type="submit"
        variant="none"
        block
      >Entrar
      </b-button>

      <div class="d-flex justify-content-center align-items-center w-100 text-center mt-3">
        <PadlockIcon class="padlock-icon" iconColor="#5396EE"/>

        <a
          href=""
          class="font-weight-light ml-2"
          @click.prevent="$emit('reset')"
        >¿Olvidaste tu contraseña?</a
        >
      </div>
    </b-form>
  </section>
</template>

<script>
import {FormError, FormInput, Loader, PadlockIcon} from "wize-admin";
import {required} from "vuelidate/lib/validators";

import {SecurityService} from "@/core/services";

export default {
  components: {
    FormError,
    Loader,
    FormInput,
    PadlockIcon,
  },
  data() {
    return {
      form: {
        username: null,
        password: null,
        remember: false,
      },
      loading: false,
      error: null,
    };
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')

      try {
        this.error = null;
        this.loading = true;
        const resp = await SecurityService.login({ ...this.form, recaptcha: token });
        this.$store.dispatch("auth/setAccessToken", resp.token);
        await this.$store.dispatch("auth/setUser");
        await this.$store.dispatch("app/loadStoreConfig");
        this.loading = false;
        await this.$router.push({ name: "dashboard" });
      } catch (e) {
        console.error(e);
        if (e.errors && e.errors.message) this.error = e.errors.message;
        else this.error = "Hubo un error al intentar autenticar";
      } finally {
        this.loading = false;
      }
    },
    showRecaptchaBadge() {
      if (this.$recaptchaInstance) {
        this.$recaptchaInstance.showBadge();
      } else {
        setTimeout(() => {
          this.showRecaptchaBadge();
        }, 500)
      }
    },
  },
  mounted() {
    this.showRecaptchaBadge();
  }
};
</script>

<style lang="stylus">
@import '../Styles/form-login';
</style>
