<template>
  <div class="test-card-info position-relative">
    <p class="description mb-0">
      TARJETA DE PRUEBA (Válido solo para ventas en entorno de Integración.)
    </p>

    <ul class="mb-0">
      <li>
        <span class="label d-inline-block mr-2">Número de tarjeta:</span>
        {{ data.number }}
      </li>
      <li>
        <span class="label d-inline-block mr-2">MM/AA:</span> {{ data.date }}
      </li>
      <li>
        <span class="label d-inline-block mr-2">CVV:</span> {{ data.cvv }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus" scoped>
@import "../../Styles/components/test-card-info.styl"
</style>
