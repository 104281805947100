<template>
  <div class="dashboard-page payment-details" id="main">
    <section class="dashboard-banner payment-methods">
      <h1 class="dashboard-title">
        Métodos de pago: {{ paymentMethod().label }}
      </h1>

      <div class="dashboard-container">
        <PayOnlineList v-if="slug === 'PAY_ONLINE'" />
        <WireTransferList v-if="slug === 'WIRE_TRANSFER'" />
        <DeliveryPayment type="UPON_DELIVERY" v-if="slug === 'UPON_DELIVERY'" />
        <DeliveryPayment
          type="UPON_DELIVERY_POS"
          v-if="slug === 'UPON_DELIVERY_POS'"
        />
        <DeliveryPayment type="LINK" v-if="slug === 'LINK'" />
        <YapeForm v-if="slug === 'YAPE'" />
        <PlinForm v-if="slug === 'PLIN'" />
        <PagoEfectivoForm v-if="slug === 'PAGO_EFECTIVO'" />
      </div>
    </section>
  </div>
</template>

<script>
import { ConfirmationModalMixin, Constants } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import DeliveryPayment from "../Components/payments/DeliveryPayment";
import PagoEfectivoForm from "../Components/payments/PagoEfectivoForm";
import PayOnlineList from "../Components/payments/PayOnlineList";
import PlinForm from "../Components/payments/PlinForm";
import WireTransferList from "../Components/payments/WireTransferList";
import YapeForm from "../Components/payments/YapeForm";

export default {
  components: {
    PagoEfectivoForm,
    DeliveryPayment,
    YapeForm,
    PlinForm,
    WireTransferList,
    PayOnlineList,
  },
  mixins: [ConfirmationModalMixin],
  data() {
    return {
      slug: this.$route.params.slug,
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "type",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.CategoriesStatus.ACTIVE,
            LocalConstants.CategoriesStatus.INACTIVE,
          ],
        },
      ],
    };
  },
  methods: {
    paymentMethod() {
      return (
        LocalConstants.PaymentMethods[this.slug] ?? {
          key: "PAY_ONLINE",
          label: "Pago en línea con tarjeta",
        }
      );
    },
    add() {
      alert("Add");
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.$smoothScroll({
        scrollTo: document.querySelector("#main"),
        updateHistory: false,
        offset: -50,
      });
    });
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/payment-details.styl"
</style>
