<template>
  <div class="income-list dashboard-container d-xl-flex" v-if="!isLoading">
    <div class="dashboard-card left">
      <div>
        <h3 class="dashboard-card-title">Ingresos estimados</h3>

        <section class="income-list-items">
          <div>
            <h4 class="income-list-title my-0">Hoy</h4>
            <span
              class="income-list-value d-inline-block my-2"
              v-decimal:pen="getInfo('TODAY').earnings"
            ></span>

            <span class="d-block small-text"
              >{{ getInfo("TODAY").orderCount }} pedido(s)</span
            >
          </div>

          <div>
            <h4 class="income-list-title my-0">Ayer</h4>
            <span
              class="income-list-value d-inline-block my-2"
              v-decimal:pen="getInfo('YESTERDAY').earnings"
            ></span>

            <span class="d-block small-text"
              >{{ getInfo("YESTERDAY").orderCount }} pedido(s)</span
            >
          </div>

          <div>
            <h4 class="income-list-title my-0">Últimos 7 días</h4>
            <span
              class="income-list-value d-inline-block my-2"
              v-decimal:pen="getInfo('LAST_WEEK').earnings"
            ></span>

            <span class="d-block small-text"
              >{{ getInfo("LAST_WEEK").orderCount }} pedido(s)</span
            >
          </div>

          <div>
            <h4 class="income-list-title my-0">Este mes</h4>
            <span
              class="income-list-value d-inline-block my-2"
              v-decimal:pen="getInfo('CURRENT_MONTH').earnings"
            ></span>

            <span class="d-block small-text"
              >{{ getInfo("CURRENT_MONTH").orderCount }} pedido(s)</span
            >
          </div>
        </section>
      </div>
    </div>
    <div class="dashboard-card right mt-4 mt-xl-0">
      <h3 class="dashboard-card-title">Venta cerrada</h3>

      <section>
        <div>
          <h4 class="income-list-title my-0">Mes anterior</h4>
          <span
            class="income-list-value d-inline-block my-2"
            v-decimal:pen="getInfo('LAST_MONTH').earnings"
          ></span>

          <span class="d-block small-text"
            >{{ getInfo("LAST_MONTH").orderCount }} pedido(s)</span
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Alert } from "wize-admin";
import { mapGetters } from "vuex";

import { OrderService } from "@/core/services";

export default {
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      orderSummary: [],
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await OrderService.getOrderSummary();
        this.orderSummary = response.payload.items;
      } catch (error) {
        Alert.error(error);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    getInfo(key) {
      return this.orderSummary.find((item) => item.identifier == key) || {};
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "app/isLoading",
    }),
  },
};
</script>

<style lang="stylus">
@import "../Styles/income-list.styl"
</style>
