<template>
  <div class="table-responsive">
    <table class="custom-table w-100">
      <thead>
        <tr>
          <th class="pl-3">Línea</th>
          <th>Mensaje</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="o in items" :key="o.id">
          <td class="text-center">
            <span :class="textColor(o.type)">{{ o.row }}</span>
          </td>
          <td class="pl-md-5">
            <span class="d-inline-block w-75" :class="textColor(o.type)">{{
              o.message
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  methods: {
    textColor(type) {
      let value = "";

      switch (type) {
        case "ERROR":
          value = "text-danger";
          break;
        case "SUCCESS":
          value = "text-success";
          break;
        case "WARNING":
          value = "text-warning";
          break;
      }

      return value;
    },
  },
};
</script>

<style></style>
