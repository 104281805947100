<template>
  <vue-trumbowyg :config="config"  v-model="inputVal"></vue-trumbowyg>
</template>

<script>
import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

export default {
  components: {
    VueTrumbowyg
  },
  props: ['value'],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {
      config: {
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['link'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
          ['fullscreen']
        ],
        autogrow: true
      }
    };
  }
}
</script>
<style lang="stylus">
.trumbowyg-box
  height: 350px !important;
.trumbowyg-fullscreen
  margin-left 250px !important
  padding-right 250px !important
  height: 100% !important
</style>