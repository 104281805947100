import { http } from "@/http";

export default {
  list(params) {
    return http.get("api/v1/admin/supplier", { params });
  },
  get(id) {
    return http.get(`/api/v1/admin/supplier/${id}`);
  },
  save(payload) {
    return http.post("/api/v1/admin/supplier", payload);
  },
  update(id, payload) {
    return http.put(`/api/v1/admin/supplier/${id}`, payload);
  },
  toggleStatus(id, isActive) {
    return http.put(`/api/v1/admin/supplier/${id}/status`, null, {
      params: {
        isActive,
      },
    });
  },
  delete(id) {
    return http.delete(`api/v1/admin/supplier/${id}`);
  },
};
