<template>
  <section>
    <div class="dashboard-card payment-methods-card">
      <div class="payment-methods-card-header d-flex justify-content-between align-items-center">
        <h2 class="subtitle">{{ banksBusiness.name }}</h2>

        <div class="options">
          <h4 class="subtitle-2">Habilitar</h4>
          <h4 class="subtitle-2">Editar</h4>
        </div>
      </div>

      <div class="payment-item text-decoration-none d-flex justify-content-between align-items-center"
        :class="{ last: index === banksBusiness.banks.length - 1 }" v-for="(item, index) in banksBusiness.banks"
        :key="item.id">
        <div class="image-container d-md-flex">
          <img :src="require(`@/core/assets/images/payment-methods/${item.logo}`)" :alt="item.name" class="img-fluid" />

          <span class="d-inline-block ml-5">
            {{ item.name }}
          </span>
        </div>

        <div class="options">
          <b-form-checkbox class="ml-2" v-model="item.isEnabled" :disabled="item.disabled" switch
            @change="updatePaymentMethodActive($event, item)">
          </b-form-checkbox>

          <div class="text-right">
            <router-link :to="{ name: 'config-payment-details-entity', params: { slug, entitySlug: item.slug } }"
              class="button button-primary text-white">
              Editar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-card payment-methods-card mt-4">
      <div class="payment-methods-card-header d-flex justify-content-between align-items-center">
        <h2 class="subtitle">{{ financialCompanies.name }}</h2>

        <div class="options">
          <h4 class="subtitle-2">Habilitar</h4>
          <h4 class="subtitle-2 text-right">Editar</h4>
        </div>
      </div>

      <div class="payment-item text-decoration-none d-flex justify-content-between align-items-center"
        :class="{ last: index === financialCompanies.banks.length - 1 }"
        v-for="(item, index) in financialCompanies.banks" :key="item.id">
        <div class="image-container d-md-flex">
          <img :src="require(`@/core/assets/images/payment-methods/${item.logo}`)" :alt="item.name" class="img-fluid" />

          <span class="d-inline-block ml-5">
            {{ item.name }}
          </span>
        </div>

        <div class="options">
          <b-form-checkbox v-model="item.isEnabled" :disabled="item.disabled" switch
            @change="updatePaymentMethodActive($event, item)">
          </b-form-checkbox>

          <div class="text-right">
            <router-link :to="{ name: 'config-payment-details-entity', params: { slug, entitySlug: item.slug } }"
              class="button button-primary text-white">
              Editar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-card payment-methods-card mt-4">
      <div class="payment-methods-card-header d-flex justify-content-between align-items-center">
        <h2 class="subtitle">{{ stateFinancialEntities.name }}</h2>

        <div class="options">
          <h4 class="subtitle-2">Habilitar</h4>
          <h4 class="subtitle-2 text-right">Editar</h4>
        </div>
      </div>

      <div class="payment-item text-decoration-none d-flex justify-content-between align-items-center"
        :class="{ last: index === stateFinancialEntities.banks.length - 1 }"
        v-for="(item, index) in stateFinancialEntities.banks" :key="item.id">
        <div class="image-container d-md-flex">
          <img :src="require(`@/core/assets/images/payment-methods/${item.logo}`)" :alt="item.name" class="img-fluid" />

          <span class="d-inline-block ml-5">
            {{ item.name }}
          </span>
        </div>

        <div class="options">
          <b-form-checkbox v-model="item.isEnabled" :disabled="item.disabled" switch
            @change="updatePaymentMethodActive($event, item)">
          </b-form-checkbox>

          <div class="text-right">
            <router-link :to="{ name: 'config-payment-details-entity', params: { slug, entitySlug: item.slug } }"
              class="button button-primary text-white">
              Editar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-card payment-methods-card mt-4">
      <div class="payment-methods-card-header d-flex justify-content-between align-items-center">
        <h2 class="subtitle">{{ cmac.name }}</h2>

        <div class="options">
          <h4 class="subtitle-2">Habilitar</h4>
          <h4 class="subtitle-2 text-right">Editar</h4>
        </div>
      </div>

      <div class="payment-item text-decoration-none d-flex justify-content-between align-items-center"
        :class="{ last: index === cmac.banks.length - 1 }" v-for="(item, index) in cmac.banks" :key="item.id">
        <div class="image-container d-md-flex">
          <img :src="require(`@/core/assets/images/payment-methods/${item.logo}`)" :alt="item.name" class="img-fluid" />

          <span class="d-inline-block ml-5">
            {{ item.name }}
          </span>
        </div>

        <div class="options">
          <b-form-checkbox v-model="item.isEnabled" :disabled="item.disabled" switch
            @change="updatePaymentMethodActive($event, item)">
          </b-form-checkbox>

          <div class="text-right">
            <router-link :to="{ name: 'config-payment-details-entity', params: { slug, entitySlug: item.slug } }"
              class="button button-primary text-white">
              Editar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-card payment-methods-card mt-4">
      <div class="payment-methods-card-header d-flex justify-content-between align-items-center">
        <h2 class="subtitle">{{ cmcp.name }}</h2>

        <div class="options">
          <h4 class="subtitle-2">Habilitar</h4>
          <h4 class="subtitle-2">Editar</h4>
        </div>
      </div>

      <div class="payment-item text-decoration-none d-flex justify-content-between align-items-center"
        :class="{ last: index === cmcp.banks.length - 1 }" v-for="(item, index) in cmcp.banks" :key="item.id">
        <div class="image-container d-md-flex">
          <img :src="require(`@/core/assets/images/payment-methods/${item.logo}`)" :alt="item.name" class="img-fluid" />

          <span class="d-inline-block ml-5">
            {{ item.name }}
          </span>
        </div>

        <div class="options">
          <b-form-checkbox v-model="item.isEnabled" :disabled="item.disabled" switch
            @change="updatePaymentMethodActive($event, item)">
          </b-form-checkbox>

          <div class="text-right">
            <router-link :to="{ name: 'config-payment-details-entity', params: { slug, entitySlug: item.slug } }"
              class="button button-primary text-white">
              Editar
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-card payment-methods-card-bottom mt-4">
      <div class="payment-bottom text-decoration-none d-flex justify-content-end">
        <router-link :to="{ name: 'config-payment-methods' }" class="button button-light">
          Volver
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { Alert } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { PaymentService } from "@/core/services";

export default {
  name: "WireTransferList",
  data() {
    return {
      slug: this.$route.params.slug,
      banksBusiness: {},
      financialCompanies: {},
      stateFinancialEntities: {},
      cmac: {},
      cmcp: {},
      wireTransferInfo: null,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.$store.commit("app/setLoading", true);
        const response = await PaymentService.get(this.slug);
        this.wireTransferInfo = response.payload;
        if (!this.wireTransferInfo.publicData) {
          this.wireTransferInfo.publicData = [];
        }
      } catch (e) {
        console.error(e);
        if (!this.wireTransferInfo) {
          this.wireTransferInfo = { publicData: [] };
        }
      } finally {
        this.$store.dispatch("app/loading", false);
      }

      this.banksBusiness = LocalConstants.BanksOptions.find(
        (item) => item.key == "banks-business"
      );
      this.checkIfAdded(this.banksBusiness.banks);

      this.financialCompanies = LocalConstants.BanksOptions.find(
        (item) => item.key == "financial-companies"
      );
      this.checkIfAdded(this.financialCompanies.banks);

      this.stateFinancialEntities = LocalConstants.BanksOptions.find(
        (item) => item.key == "state-financial-entities"
      );
      this.checkIfAdded(this.stateFinancialEntities.banks);

      this.cmac = LocalConstants.BanksOptions.find(
        (item) => item.key == "cmac"
      );
      this.checkIfAdded(this.cmac.banks);

      this.cmcp = LocalConstants.BanksOptions.find(
        (item) => item.key == "cmcp"
      );
      this.checkIfAdded(this.cmcp.banks);
    },
    checkIfAdded(list) {
      list.forEach((item) => {
        let exists = this.wireTransferInfo.publicData.find(
          (b) => b.bankId == item.id
        );

        if (exists) {
          item.added = true;
          item.isEnabled = exists.isEnabled;
        }
      });
    },
    async updatePaymentMethodActive(value, item) {
      try {
        this.$store.dispatch("app/loading", true);

        if (!item.added) {
          this.$router.push({
            name: 'config-payment-details-entity',
            params: { slug: this.slug, entitySlug: item.slug }, query: { activate: true }
          })
          return;
        }

        const exists = this.wireTransferInfo.publicData.find(
          (o) => o.bankId == item.id
        );

        if (exists) {
          const index = this.wireTransferInfo.publicData.findIndex(
            (o) => o.bankId == item.id
          );

          this.wireTransferInfo.publicData[index] = {
            ...exists,
            isEnabled: value,
          };
        }

        const data = {
          id: this.$route.params.slug,
          banks: [...this.wireTransferInfo.publicData],
        };

        const resp = await PaymentService.save(data);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
          await this.loadData();
        }
      } catch (ex) {
        console.error(ex);
        Alert.error("Ocurrió un error, por favor inténtelo nuevamente.");
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async save() {
      const self = this;
      self.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      try {
        this.$store.commit("app/setLoading", true);

        let list = this.wireTransferInfo.publicData;
        let exists = list.find((item) => item.bankId == this.bankInfo.id);

        let item = {
          ...this.form,
          bank: this.bankInfo.name,
          bankId: this.bankInfo.id,
        };

        if (!exists) {
          list.push(item);
        } else {
          let index = list.findIndex((item) => item.bankId == this.bankInfo.id);
          list[index] = item;
        }

        let data = {
          id: this.$route.params.slug,
          banks: list,
        };

        const resp = await PaymentService.save(data);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../Styles/payment-methods.styl';
</style>
