<template>
  <div class="page-container provider-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar proveedor" : "Nuevo proveedor" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing ? "Datos del proveedor" : "Datos del nuevo proveedor"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle">Información general</h2>
                  <p class="custom-card-description">
                    Ingresa los datos
                    {{ editing ? "del proveedor" : "del nuevo proveedor" }}
                  </p>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="redirectTo">Tipo de documento *</label>

                        <FormSelect
                          id="redirectTo"
                          :show-error="
                            $v.provider.identifierType.$error &&
                            !$v.provider.identifierType.required
                          "
                          v-model="provider.identifierType"
                          :items="identifierTypes"
                          defaultOption="Elegir tipo de documento"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.provider.identifierType.$error &&
                            !$v.provider.identifierType.required
                          "
                        ></form-error>
                      </div>

                      <div class="form-group">
                        <label for="identifier"
                          >Número de documento *</label
                        >

                        <FormInput
                          id="identifier"
                          type="number"
                          maxlength="20"
                          placeholder="Ingresar número de documento"
                          :show-error="
                            $v.provider.identifier.$error &&
                            !$v.provider.identifier.required
                          "
                          v-model="provider.identifier"
                        />

                        <form-error
                          message="Número de documento es requerido"
                          v-if="
                            $v.provider.identifier.$error &&
                            !$v.provider.identifier.required
                          "
                        ></form-error>
                      </div>

                      <div class="form-group">
                        <label for="name">Nombre / Razón social *</label>

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar Nombre / Razón social"
                          :show-error="
                            $v.provider.name.$error &&
                            !$v.provider.name.required
                          "
                          v-model="provider.name"
                        />

                        <form-error
                          message="Nombre / Razón social es requerido"
                          v-if="
                            $v.provider.name.$error &&
                            !$v.provider.name.required
                          "
                        ></form-error>
                      </div>

                      <div class="form-group">
                        <label for="address">Dirección fiscal * </label>

                        <FormInput
                          id="address"
                          type="text"
                          placeholder="Ingresar dirección fiscal * "
                          :show-error="
                            $v.provider.address.$error &&
                            !$v.provider.address.required
                          "
                          v-model="provider.address"
                        />

                        <form-error
                          message="Dirección fiscal es requerido"
                          v-if="
                            $v.provider.address.$error &&
                            !$v.provider.address.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="form-group-buttons text-right">
                      <router-link
                        :to="{ name: 'config-providers-list' }"
                        class="button button-light"
                      >
                        {{ editing ? "Volver" : "Cancelar" }}
                      </router-link>
                      <button class="button button-primary" type="submit">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  FormError,
  FormInput,
  FormSelect,
  Constants,
} from "wize-admin";

import { SupplierService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      identifierTypes: [
      Constants.IdentifierType.DNI,
        Constants.IdentifierType.RUC,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.PASSPORT
      ],
      showMap: false,
      previewDesktop: null,
      previewMobile: null,
      provider: {
        identifierType: null,
        identifier: null,
        name: null,
        address: null,
      },
      editing: false,
    };
  },
  components: {
    FormError,
    FormInput,
    FormSelect,
  },
  validations: {
    provider: {
      identifierType: { required },
      identifier: { required },
      name: { required },
      address: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  computed: {},
  methods: {
    removeImage(i) {
      this.provider.images.splice(i, 1);
    },
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true)

      const data = { ...this.provider };

      try {
        let response;
        if (!this.editing) response = await SupplierService.save(data);
        else
          response = await SupplierService.update(
            this.$route.params.id,
            data
          );

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-providers-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
        this.$store.commit('app/setSuppliers', null)
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false)
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await SupplierService.get(id);
        this.provider = { ...response.payload };
        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado") message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({ name: "config-providers-list" }));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
</style>
