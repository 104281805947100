<template>
  <div class="payment-details">
    <img
      src="@/core/assets/images/payments/mercado-pago-lg.png"
      alt="Logo Izipay"
      class="logo rounded-circle"
    />

    <HelpCollapse>
      <template slot="content">
        <div class="help-collapse-content">
          <p>
            Mercado Pago es la solución de pagos de MercadoLibre que permite a
            vendedores recibir pagos de forma simple y rápida.
          </p>

          <p>
            La comisión por transacción exitosa es de 3.99% + S/1,00 + IGV. Si
            solicitas el dinero en 14 días la comisión es de 3,79% + S/ 1,00 +
            IGV.
          </p>

          <p>
            Para mayor información visita
            <a href="https://www.mercadopago.com.pe/" target="_blank"
              >https://www.mercadopago.com.pe/</a
            >
          </p>

          <p>
            <span class="font-weight-bold"
              >Para obtener tus credenciales debes ir a:</span
            >
            <a href="https://www.mercadopago.com.pe/mpe/account/credentials" target="_blank" class="d-d-inline-block ml-1">https://www.mercadopago.com.pe/mpe/account/credentials</a> Una vez que
            tengas tus credenciales de integración, sólo deberás integrarlas en
            el formulario de abajo para empezar a recebir pagos con esta
            pasarela.
          </p>
        </div>
      </template>
    </HelpCollapse>

    <TestCardInfo :data="testCardData" />

    <form action="" class="custom-form mt-4" @submit.prevent="save()">
      <div class="form-group">
        <label for="publicKey">Public key</label>
        <FormInput
          id="publicKey"
          type="text"
          placeholder="Ingresar public key"
          v-model="form.publicKey"
        />
        <!-- <form-error
          message="Usuario es requerido"
          v-if="$v.form.user.$error && !$v.form.user.required"
        ></form-error> -->
      </div>

      <div class="form-group">
        <label for="accessToken">Acces token</label>
        <FormInput
          id="accessToken"
          type="text"
          placeholder="Ingresar contraseña"
          v-model="form.accessToken"
        />
        <!-- <form-error
          message="Contraseña es requerida"
          v-if="$v.form.password.$error && !$v.form.password.required"
        ></form-error> -->
      </div>

      <div class="form-group">
        <label for="acceptCash">Aceptar pagos en efectivo</label>

        <b-form-checkbox v-model="form.acceptCash" name="check-button" switch>
        </b-form-checkbox>
      </div>

      <div class="form-group">
        <label for="environment">Entorno</label>
        <div class="d-flex">
          <b-form-radio
            v-model="form.environment"
            name="enviroment"
            value="PRODUCTION"
            >Producción</b-form-radio
          >
          <b-form-radio
            v-model="form.environment"
            name="enviroment"
            value="INTEGRATION"
            class="ml-2"
            >Integración</b-form-radio
          >
        </div>
        <!-- <form-error
          message="Usuario es requerido"
          v-if="$v.form.jsKey.$error && !$v.form.jsKey.required"
        ></form-error> -->
      </div>

      <div class="form-group-buttons text-right">
        <router-link
          :to="{ name: 'config-payment-methods' }"
          class="button button-light"
        >
          Volver
        </router-link>
        <button class="button button-primary" type="submit">Guardar</button>
      </div>
    </form>
  </div>
</template>

<script>
// import { required } from "vuelidate/lib/validators";

import { FormInput } from "wize-admin";

import HelpCollapse from "./HelpCollapse.vue";
import TestCardInfo from "./TestCardInfo.vue";

export default {
  data() {
    return {
      testCardData: {
        number: "4970 3000 0000 0012",
        date: "05/20",
        cvv: "587",
      },
      form: {
        publicKey: null,
        password: null,
        acceptCash: false,
        environment: "PRODUCTION",
      },
    };
  },
  //   validations: {
  //     form: {
  //       user: { required },
  //       password: { required },
  //     },
  //   },
  components: {
    HelpCollapse,
    TestCardInfo,
    FormInput,
  },
  methods: {
    save() {
      //   this.$v.$touch();
      //   if (this.$v.$error) return;

      alert("Submit");
    },
  },
};
</script>

<style lang="stylus" scoped>
@import "../../Styles/payment-details.styl"
</style>
