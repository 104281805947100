import {
  http
} from '@/http'

export default {
  productTemplate() {
    return http.get('/api/v1/admin/batch/product/template', {
      responseType: 'blob'
    })
  },
  uploadProducts(data) {
    return http.post('/api/v1/admin/batch/product', data)
  },
  stockTemplate() {
    return http.get('/api/v1/admin/batch/stock/template', {
      responseType: 'blob'
    })
  },
  uploadStock(data) {
    return http.post('/api/v1/admin/batch/stock', data)
  },
  priceTemplate() {
    return http.get('/api/v1/admin/batch/price/template', {
      responseType: 'blob'
    })
  },
  uploadPrices(data) {
    return http.post('/api/v1/admin/batch/price', data)
  }
}
