import ConfigView from "../ConfigView.vue";
import UsersList from "../Pages/UsersList.vue";
import UsersEditor from "../Pages/UsersEditor.vue";
import CategoriesList from '../Pages/CategoriesList'
import CategoryEditor from '../Pages/CategoryEditor'
import AttributeList from '../Pages/AttributeList'
import AttributeDetails from '../Pages/AttributeDetails'
import AttributeEditor from '../Pages/AttributeEditor'
import AttributeValueEditor from '../Pages/AttributeValueEditor'
import ProvidersList from '../Pages/ProvidersList'
import ProviderEditor from '../Pages/ProviderEditor'
import StoreList from "../Pages/StoreList.vue";
import StoreEditor from "../Pages/StoreEditor.vue";
import BannersList from "../Pages/BannersList.vue";
import BannerEditor from "../Pages/BannerEditor.vue";
import PaymentMethods from "../Pages/PaymentMethods.vue";
import PaymentDetails from "../Pages/PaymentDetails.vue";
import DeliveryList from "../Pages/DeliveryList.vue";
import PagesList from "../Pages/PagesList.vue";
import PagesEditor from "../Pages/PagesEditor.vue";
import PaymentDetailsEntity from '../Pages/PaymentDetailsEntity.vue'

const routes = [
  {
    path: '/mantenimiento',
    component: ConfigView,
    plan: ['ALL'],
    name: 'config',
    children: [
      {
        path: 'usuarios',
        name: 'config-users-list',
        plan: ['BUSINESS', 'ENTERPRISE'],
        component: UsersList
      },
      {
        path: 'usuarios/crear',
        name: 'config-users-create',
        plan: ['BUSINESS', 'ENTERPRISE'],
        component: UsersEditor
      },
      {
        path: 'usuarios/:id/editar',
        name: 'config-users-edit',
        plan: ['BUSINESS', 'ENTERPRISE'],
        component: UsersEditor
      },
      {
        path: 'categorias',
        name: 'config-categories-list',
        plan: ['ALL'],
        component: CategoriesList
      },
      {
        path: 'categorias/crear',
        name: 'config-categories-create',
        plan: ['ALL'],
        component: CategoryEditor
      },
      {
        path: 'categorias/:id/editar',
        name: 'config-categories-edit',
        plan: ['ALL'],
        component: CategoryEditor
      },
      {
        path: 'atributos',
        name: 'config-attributes-list',
        plan: ['ALL'],
        component: AttributeList
      },
      {
        path: 'atributos/crear',
        name: 'config-attributes-create',
        plan: ['ALL'],
        component: AttributeEditor
      },
      {
        path: 'atributos/:id/editar',
        name: 'config-attributes-edit',
        plan: ['ALL'],
        component: AttributeEditor
      },
      {
        path: 'atributos/:attributeId',
        name: 'config-attributes-details',
        plan: ['ALL'],
        component: AttributeDetails
      },
      {
        path: 'atributos/:attributeId/valores/crear',
        name: 'config-attributes-create-value',
        plan: ['ALL'],
        component: AttributeValueEditor
      },
      {
        path: 'atributos/:attributeId/valores/:valueId/editar',
        name: 'config-attributes-edit-value',
        plan: ['ALL'],
        component: AttributeValueEditor
      },
      {
        path: 'proveedores',
        name: 'config-providers-list',
        plan: ['ALL'],
        component: ProvidersList
      },
      {
        path: 'proveedores/crear',
        name: 'config-providers-create',
        plan: ['ALL'],
        component: ProviderEditor
      },
      {
        path: 'proveedores/:id/editar',
        name: 'config-providers-edit',
        plan: ['ALL'],
        component: ProviderEditor
      },
      {
        path: 'locales',
        name: 'config-store-list',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: StoreList
      },
      {
        path: 'locales/crear',
        name: 'config-store-create',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: StoreEditor
      },
      {
        path: 'locales/:id/editar',
        name: 'config-store-edit',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: StoreEditor
      },
      {
        path: 'banners',
        name: 'config-banners-list',
        plan: ['ALL'],
        component: BannersList
      },
      {
        path: 'banners/crear',
        name: 'config-banners-create',
        plan: ['ALL'],
        component: BannerEditor
      },
      {
        path: 'banners/:id/editar',
        name: 'config-banners-edit',
        plan: ['ALL'],
        component: BannerEditor
      },
      {
        path: 'metodos-de-pago',
        name: 'config-payment-methods',
        plan: ['ALL'],
        component: PaymentMethods
      },
      {
        path: 'metodos-de-pago/:slug',
        name: 'config-payment-details',
        plan: ['ALL'],
        component: PaymentDetails
      },
      {
        path: 'metodos-de-pago/:slug/:entitySlug/editar',
        name: 'config-payment-details-entity',
        plan: ['ALL'],
        component: PaymentDetailsEntity
      },
      {
        path: 'entregas-y-envios',
        name: 'config-delivery-list',
        plan: ['ALL'],
        component: DeliveryList
      },
      {
        path: 'paginas',
        name: 'config-pages-list',
        plan: ['ALL'],
        component: PagesList
      },
      {
        path: 'paginas/:id/editar',
        name: 'config-pages-edit',
        plan: ['ALL'],
        component: PagesEditor
      }
    ]
  },
]

export default routes
