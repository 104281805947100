import { Alert } from 'wize-admin';

import { PaymentService } from "@/core/services";

export default {
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await PaymentService.list();
        this.serverList = JSON.parse(JSON.stringify(response.payload));
        this.serverList.forEach((item) => {
          const method = this.paymentMethodsList.find((item2) => item2.key === item.id);
          if (method) {
            method.isActive = item.isActive
            method.added = true
            method.publicData = item.publicData
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async updatePaymentMethodActive(value, item) {
      try {
        this.$store.dispatch("app/loading", true);

        if (!item.added) {
          if(this.innerList){
            this.$router.push({
              name: 'config-payment-details-entity',
              params: {slug: this.$route.params.slug, entitySlug: item.key }, query: { activate: true }
            })
          }else{
            this.$router.push({
              name: 'config-payment-details',
              params: { slug: item.key }, query: { activate: true }
            })
          }
          
          return
        }

        await PaymentService.updatePaymentMethodActive(
          item.key.toUpperCase(),
          value
        );

        await this.loadData()

        Alert.success("Estado actualizado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error("Ocurrió un error, por favor inténtelo nuevamente.")
        item.isActive = !item.isActive;
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async savePaymentMethod() {
      this.$v.$touch();
      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }
      try {
        this.$store.commit("app/setLoading", true);

        let data = {
          ...this.form,
        };

        const resp = await PaymentService.save(data);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  }
}
