import Vue from "vue";
import VueRouter from "vue-router";
import LoginRoutes from "@/modules/Login/Routes";
import DashboardRoutes from "@/modules/Dashboard/Routes";
import ConfigRoutes from "@/modules/Config/Routes";
import ClientsRoutes from "@/modules/Clients/Routes";
import ProductsRoutes from "@/modules/Products/Routes";
import GeneralConfigurationRoutes from "@/modules/GeneralConfiguration/Routes";
import OrdersRoutes from "@/modules/Orders/Routes";
import AppearanceRoutes from "@/modules/Appearance/Routes";
import ToolsRoutes from "@/modules/Tools/Routes";
import store from "../store";
import abilities from "@/core/plugins/abilities";


Vue.use(VueRouter);

let routes = [];

routes = routes.concat(
  LoginRoutes,
  DashboardRoutes,
  ConfigRoutes,
  ClientsRoutes,
  ProductsRoutes,
  GeneralConfigurationRoutes,
  OrdersRoutes,
  AppearanceRoutes,
  ToolsRoutes
);

// Asignar redirección cuando una página no exista
let notFound = {path: "/:pathMatch(.*)*", name: "NotFound", redirect: {name: "dashboard"}}
routes.push(notFound)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const canNavigate = (to) => {
  return abilities.ability.can('manage', to.name) || to.meta.excludeAuth || to.path === '/404';
};

router.beforeEach(async (to, from, next) => {
  if (to.meta.excludeAuth) {
    if (!store.getters["auth/isAuthenticated"]) {
      next();
    } else {
      next({name: "dashboard"});
    }
  } else {
    if (store.getters["auth/isAuthenticated"]) {
      if (store.getters["auth/isUserLoaded"]) {
        if (canNavigate(to)) {
          next();
        } else {
          next({name: "login"});
        }
      } else {
        await store.dispatch("auth/setUser");
        store.dispatch("app/loadStoreConfig");
        if (canNavigate(to)) {
          next();
        } else {
          next({name: "login"});
        }
      }
    } else {
      next({name: "login"});
    }
  }
});

export default router;
