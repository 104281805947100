<template>
  <div class="dashboard-card">
    <div class="payment-details">
      <HelpCollapse>
        <template slot="content">
          <div class="help-collapse-content">
            <p>
              Por favor completa el siguiente formulario para comenzar a aceptar pagos
            </p>
          </div>
        </template>
      </HelpCollapse>

      <form action="" class="custom-form mt-4" @submit.prevent="save()">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{
              'texteditor-error':
                $v.form.instructions.$error && !$v.form.instructions.required,
            }">
              <label for="instructions">Instrucciones para el cliente *</label>
              <global-trumbowyg v-model="form.instructions" />

              <form-error message="Instrucciones para el cliente son requeridas"
                v-if="$v.form.instructions.$error && !$v.form.instructions.required"></form-error>
            </div>
          </div>
        </div>
        <div class="form-group-buttons text-right">
          <router-link :to="{ name: 'config-payment-methods' }" class="button button-light">
            Volver
          </router-link>
          <button class="button button-primary" type="submit">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { FormError } from "wize-admin";

import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";

import PaymentMethodEntityMixin from '@/core/mixins/PaymentMethodEntityMixin';
import HelpCollapse from "./HelpCollapse.vue";

export default {
  mixins: [PaymentMethodEntityMixin],
  props: {
    type: String
  },
  data() {
    return {
      form: {
        id: this.type,
        instructions: null,
        isActive: false
      }
    };
  },
  components: {
    FormError,
    HelpCollapse,
    GlobalTrumbowyg
  },
  validations: {
    form: {
      instructions: { required },
    },
  },
  async mounted() {
    await this.load();
    this.form.id = this.type;
  },
};
</script>

<style lang="stylus">
@import '../../Styles/payment-details.styl';
</style>
