import Vue from "vue";
import {Ability, AbilityBuilder} from '@casl/ability'
import {abilitiesPlugin} from "@casl/vue"
import router from '@/router'

const ability = new Ability();
Vue.use(abilitiesPlugin, ability);

function defineAbilitiesFor(profile) {
  const routes = router.options.routes.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue);
      if(currentValue.children){
        accumulator = accumulator.concat(currentValue.children);
      }
      return accumulator;
    },
    []
  );
  const {rules, can} = AbilityBuilder.extract();
  routes.forEach(route => {
    if (route.name && route.roles && route.roles.includes(profile)) {
      can('manage', route.name);
    }
  })
  ability.update(rules);
}

function defineAbilitiesForPlan(plan) {
  const routes = router.options.routes.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue);
      if(currentValue.children){
        accumulator = accumulator.concat(currentValue.children);
      }
      return accumulator;
    },
    []
  );
  const {rules, can} = AbilityBuilder.extract();
  
  routes.forEach(route => {
    if (route.name && (route.plan && (route.plan.includes(plan) || route.plan.includes('ALL')))) {
      can('manage', route.name);
    }
  })
  ability.update(rules);
}

export default {
  defineAbilitiesFor, defineAbilitiesForPlan, ability
}
