<template>
  <div class="dashboard-banner payment-methods">
    <h1 class="dashboard-title">Métodos de pago: {{ getEntitieName }}</h1>

    <section>
      <div class="dashboard-card">
        <WireTransferForm v-if="slug === 'WIRE_TRANSFER'" />
        <IzipayForm v-if="entitySlug === 'IZIPAY'" />
        <MercadoPagoForm v-if="entitySlug === 'MERCADO_PAGO'" />
        <NiubizForm v-if="entitySlug === 'NIUBIZ'" />
        <CulqiForm v-if="entitySlug === 'CULQI'" />
        <DeliveryPayment v-if="entitySlug === 'UPON_DELIVERY'" />
      </div>
    </section>
  </div>
</template>

<script>
import { Constants as LocalConstants } from "@/core/utils";

import CulqiForm from "../Components/payments/CulqiForm";
import IzipayForm from "../Components/payments/IzipayForm";
import MercadoPagoForm from "../Components/payments/MercadoPagoForm";
import NiubizForm from "../Components/payments/NiubizForm";
import WireTransferForm from "../Components/payments/WireTransferForm.vue";

export default {
  name: "PaymentDetailsEntity",
  data() {
    return {
      slug: this.$route.params.slug,
      entitySlug: this.$route.params.entitySlug,
    };
  },
  components: {
    WireTransferForm,
    IzipayForm,
    MercadoPagoForm,
    NiubizForm,
    CulqiForm,
  },
  computed: {
    getEntitieName() {
      const paymentMethod = LocalConstants.PaymentMethods[this.entitySlug];
      if (paymentMethod) {
        return paymentMethod.label;

      }
      const banks = [];
      LocalConstants.BanksOptions.forEach((item) => banks.push(...item.banks));
      const bank = banks.find((item) => item.slug == this.entitySlug);
      if (bank) {
        return bank.name;
      }
      return "";
    },
  },
};
</script>

<style lang="stylus">
@import '../Styles/payment-methods.styl';
</style>
