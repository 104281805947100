import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/admin/order", { params });
  },
  get(id) {
    return http.get(`/api/v1/admin/order/${id}`);
  },
  toggleStatus(id, status) {
    return http.put(`/api/v1/admin/order/${id}/status`, {
      status
    });
  },
  getOrderSummary() {
    return http.get("/api/v1/admin/order/summary")
  },
  downloadOrderList(params) {
    return http.get('/api/v1/admin/order/xlsx', {
      responseType: 'blob',
      params
    })
  }
};
