var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container banner-editor"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar banner" : "Nuevo banner")+" ")])]),_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos del banner" : "Datos del nuevo banner")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body px-0 pb-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section pt-0"},[_c('h2',{staticClass:"custom-card-subtitle"},[_vm._v("Información general")]),_c('p',{staticClass:"custom-card-description"},[_vm._v(" Ingresa los datos "+_vm._s(_vm.editing ? "del banner" : "del nuevo banner")+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"position"}},[_vm._v("Orden *")]),_c('FormInput',{attrs:{"id":"position","type":"number","min":0,"placeholder":"Ingresar orden","show-error":_vm.$v.banner.position.$error &&
                          !_vm.$v.banner.position.required},model:{value:(_vm.banner.position),callback:function ($$v) {_vm.$set(_vm.banner, "position", $$v)},expression:"banner.position"}}),(
                          _vm.$v.banner.position.$error &&
                          !_vm.$v.banner.position.required
                        )?_c('form-error',{attrs:{"message":"Orden es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Título *")]),_c('FormInput',{attrs:{"id":"title","type":"text","placeholder":"Ingresar título","show-error":_vm.$v.banner.title.$error && !_vm.$v.banner.title.required},model:{value:(_vm.banner.title),callback:function ($$v) {_vm.$set(_vm.banner, "title", $$v)},expression:"banner.title"}}),(
                          _vm.$v.banner.title.$error && !_vm.$v.banner.title.required
                        )?_c('form-error',{attrs:{"message":"Título es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group",class:{ 'mb-0': !_vm.banner.redirectType }},[_c('label',{attrs:{"for":"redirectType"}},[_vm._v("Redireccionar a")]),_c('FormSelect',{attrs:{"id":"redirectType","items":_vm.redirectTypes,"defaultOption":"Elegir redirección"},on:{"change":function($event){_vm.banner.redirectUrl = null}},model:{value:(_vm.banner.redirectType),callback:function ($$v) {_vm.$set(_vm.banner, "redirectType", $$v)},expression:"banner.redirectType"}})],1),(
                        _vm.banner.redirectType ===
                        _vm.LocalConstants.BannersRedirectTypes.CATEGORY.key
                      )?_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"redirectType"}},[_vm._v("Categoría")]),_c('FormSelect',{attrs:{"id":"redirectType","items":_vm.categories,"defaultOption":"Elegir categoría","show-error":_vm.$v.banner.redirectUrl.$error &&
                          !_vm.$v.banner.redirectUrl.required},model:{value:(_vm.banner.redirectUrl),callback:function ($$v) {_vm.$set(_vm.banner, "redirectUrl", $$v)},expression:"banner.redirectUrl"}}),(
                          _vm.$v.banner.redirectUrl.$error &&
                          !_vm.$v.banner.redirectUrl.required
                        )?_c('form-error',{attrs:{"message":"Categoría es requerido"}}):_vm._e()],1):_vm._e(),(
                        _vm.banner.redirectType ===
                        _vm.LocalConstants.BannersRedirectTypes.PRODUCT.key
                      )?_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"redirectType"}},[_vm._v("Producto")]),_c('FormSelect',{attrs:{"id":"redirectType","items":_vm.products,"defaultOption":"Elegir producto","show-error":_vm.$v.banner.redirectUrl.$error &&
                          !_vm.$v.banner.redirectUrl.required},model:{value:(_vm.banner.redirectUrl),callback:function ($$v) {_vm.$set(_vm.banner, "redirectUrl", $$v)},expression:"banner.redirectUrl"}}),(
                          _vm.$v.banner.redirectUrl.$error &&
                          !_vm.$v.banner.redirectUrl.required
                        )?_c('form-error',{attrs:{"message":"Producto es requerido"}}):_vm._e()],1):_vm._e(),(
                        _vm.banner.redirectType ===
                        _vm.LocalConstants.BannersRedirectTypes.OTHER.key
                      )?_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"redirectType"}},[_vm._v("Otra URL")]),_c('FormInput',{attrs:{"type":"text","placeholder":"Ingresar URL","show-error":_vm.$v.banner.redirectUrl.$error &&
                          !_vm.$v.banner.redirectUrl.required},model:{value:(_vm.banner.redirectUrl),callback:function ($$v) {_vm.$set(_vm.banner, "redirectUrl", $$v)},expression:"banner.redirectUrl"}}),(
                          _vm.$v.banner.redirectUrl.$error &&
                          !_vm.$v.banner.redirectUrl.required
                        )?_c('form-error',{attrs:{"message":"URL es requerido"}}):_vm._e(),_c('b-form-checkbox',{staticClass:"mt-2",model:{value:(_vm.banner.openNewTab),callback:function ($$v) {_vm.$set(_vm.banner, "openNewTab", $$v)},expression:"banner.openNewTab"}},[_c('span',{staticClass:"custom-checkbox-label"},[_vm._v("Abrir en una ventana nueva")])])],1):_vm._e()]),_c('div',{staticClass:"col-12"})])]),_c('div',{staticClass:"form-section"},[_c('h2',{staticClass:"custom-card-subtitle"},[_vm._v("Fotos de banners")]),_c('p',{staticClass:"custom-card-description"},[_vm._v(" Agregue fotos de los banners en los siguiente tamaños: ")]),_c('div',{staticClass:"form-group d-flex flex-column flex-md-row"},[_c('div',{staticClass:"label-container d-flex flex-column"},[_c('label',{staticClass:"mb-0",attrs:{"for":"desktopImageId"}},[_vm._v("Imagen desktop *")]),_c('p',{staticClass:"label-description"},[_vm._v(" Sube tu imagen en tamaño "+_vm._s(_vm.desktopImageMaxWidth)+"px x "+_vm._s(_vm.desktopImageMaxHeight)+"px en formato .png o .jpg ")])]),_c('div',[_c('UploadImages',{attrs:{"exactDimensions":true,"maxWidth":_vm.desktopImageMaxWidth,"maxHeight":_vm.desktopImageMaxHeight,"maxFiles":1,"files":_vm.desktopOldImage},on:{"onChange":function($event){return _vm.onChange($event, 'Desktop')},"remove":function($event){return _vm.removeImage($event, 'Desktop')}}}),(
                        _vm.$v.banner.desktopImageId.$error &&
                        !_vm.$v.banner.desktopImageId.required
                      )?_c('form-error',{attrs:{"message":"Imagen desktop es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"form-group d-flex flex-column flex-md-row"},[_c('div',{staticClass:"label-container d-flex flex-column"},[_c('label',{staticClass:"mb-0",attrs:{"for":"mobileImageId"}},[_vm._v("Imagen mobile *")]),_c('p',{staticClass:"label-description"},[_vm._v(" Sube tu imagen en tamaño "+_vm._s(_vm.mobileImageMaxWidth)+"px x "+_vm._s(_vm.mobileImageMaxHeight)+"px en formato .png o .jpg ")])]),_c('div',[_c('UploadImages',{attrs:{"exactDimensions":true,"maxWidth":_vm.mobileImageMaxWidth,"maxHeight":_vm.mobileImageMaxHeight,"maxFiles":1,"files":_vm.mobileOldImage},on:{"onChange":function($event){return _vm.onChange($event, 'Mobile')},"remove":function($event){return _vm.removeImage($event, 'Mobile')}}}),(
                        _vm.$v.banner.mobileImageId.$error &&
                        !_vm.$v.banner.mobileImageId.required
                      )?_c('form-error',{attrs:{"message":"Imagen mobile es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'config-banners-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }