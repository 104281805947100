import ProductsView from "../ProductsView.vue";
import ProductsList from "../Pages/ProductsList.vue";
import LoadProducts from "../Pages/LoadProducts.vue";
import UpdateStock from "../Pages/UpdateStock.vue";
import UpdatePrices from "../Pages/UpdatePrices.vue";
import ProductEditor from "../Pages/ProductEditor.vue";

const routes = [
  {
    path: '/productos',
    component: ProductsView,
    name: 'products',
    plan: ['ALL'],
    children: [
      {
        path: '',
        name: 'products-list',
        plan: ['ALL'],
        component: ProductsList
      },
      {
        path: 'cargar',
        name: 'load-products',
        plan: ['BUSINESS', 'ENTERPRISE'],
        component: LoadProducts
      },
      {
        path: 'actualizar-stocks',
        name: 'update-stock',
        plan: ['BUSINESS', 'ENTERPRISE'],
        component: UpdateStock
      },
      {
        path: 'actualizar-precios',
        name: 'update-prices',
        plan: ['BUSINESS', 'ENTERPRISE'],
        component: UpdatePrices
      },
      {
        path: 'crear',
        name: 'products-create',
        plan: ['ALL'],
        component: ProductEditor
      },
      {
        path: ':id/editar',
        name: 'products-edit',
        plan: ['ALL'],
        component: ProductEditor
      }
    ]
  },
]

export default routes
