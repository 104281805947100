<template>
  <article class="biolink-card position-relative" v-if="info">
    <!-- v-bind:style="{ backgroundImage: 'url(' + info.image + ')' }" -->
    <div class="biolink-card-image" v-if="info.image">
      <img :src="info.image" :alt="info.title" class="img-fluid" />
    </div>
    <div class="biolink-card-content">
      <div class="biolink-card-title-container">
        <h3 class="biolink-card-title m-0">{{ info.title }}</h3>
      </div>
    </div>

    <div class="biolink-card-options d-flex">
      <button
        type="button"
        class="biolink-card-button edit mr-2"
        @click="$emit('edit', info)"
      >
        <PencilIcon class="fill-white" />
      </button>

      <button
        type="button"
        class="biolink-card-button delete"
        @click="$emit('delete', index)"
      >
        <TrashIcon class="fill-white" />
      </button>
    </div>
  </article>
</template>

<script>
import { TrashIcon, PencilIcon } from "wize-admin";

export default {
  components: {
    TrashIcon,
    PencilIcon,
  },
  props: {
    info: Object,
    index: Number,
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/biolink-card.styl';
</style>
