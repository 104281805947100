<template>
  <form
    class="general-configuration header-config custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Footer</h2>

    <section class="form-container border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Logo web</label>
          <p class="label-description">Sube tu logo en {{ maxWidth }}px x {{ maxHeight }}px en formato .png o .jpg</p>
        </div>

        <div class="form-field">
          <UploadImages
            :maxWidth="maxWidth"
            :maxHeight="maxHeight"
            :maxFiles="1"
            :files="oldFiles"
            @onChange="onChange"
            @remove="removeImage"
          />

          <!-- <form-error
            message="Logo web es requerido"
            v-if="$v.form.logo.$error && !$v.form.logo.required"
          ></form-error>

          <form-error
            message="Las dimensiones de la imagen son incorrectas"
            v-if="form.logoErrorDimensions"
          ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="businessName">Logo texto</label>
        </div>

        <div class="form-field">
          <FormInput
            id="businessName"
            type="text"
            placeholder="Ingresar logo texto"
            v-model="form[1].value"
          />

          <!-- <form-error
            message="Logo texto es requerido"
            v-if="$v.form.name.$error && !$v.form.name.required"
          ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Logo atributo alt</label>
          <p class="label-description">
            Atributo Alt para el logo. Este es el texto alternativo si no se
            puede mostrar el logotipo. Es útil para SEO y generalmente es el
            nombre del sitio.
          </p>
        </div>

        <div class="form-field">
          <FormInput
            id="logoAlt"
            type="text"
            placeholder="Ingresar logo atributo alt"
            v-model="form[2].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Libro de reclamaciones</label>
        </div>

        <div class="form-field">
          <b-form-checkbox id="dealers" v-model="form[3].value" switch>
          </b-form-checkbox>

          <FormInput
            id="logoAlt"
            type="text"
            placeholder="Ingresar URL"
            class="mt-2"
            v-model="form[4].value"
            v-if="form[3].value"
          />
        </div>
      </div>
    </section>

    <div class="form-group-buttons text-right align-self-end mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { FormInput, Alert, UploadImages } from "wize-admin";
import { required } from "vuelidate/lib/validators";

import { ConfigurationService } from "@/core/services";

import UploadImagesMixin from '@/core/mixins/UploadImagesMixin'

export default {
  mixins: [UploadImagesMixin],
  data() {
    return {
      maxWidth: 272,
      maxHeight: 90,
      form: [
        {
          identifier: "FOOTER_LOGO_IMAGE",
          value: null,
        },
        {
          identifier: "FOOTER_LOGO_TEXT",
          value: null,
        },
        {
          identifier: "FOOTER_LOGO_ALT",
          value: null,
        },
        {
          identifier: "COMPLAINTS_BOOK_ENABLED",
          value: null,
        },
        {
          identifier: "COMPLAINTS_BOOK_URL",
          value: null
        }
      ]
    };
  },
  mounted() {
    this.loadData()
  },
  components: {
    FormInput,
    UploadImages
  },
  validations: {
    form: {
      logo: { required },
      name: { required },
    },
  },
  methods: {
    async onChange(files) {
      if(!files.length) return

      try {
        let uploadedFiles = await this.uploadFiles(files)

        if(!uploadedFiles.length) return

        this.form[0].value = uploadedFiles[0].url
      } catch (error) {
        Alert.error(error)
      }
    },
    removeImage() {
      this.form[0].value = null
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await ConfigurationService.list();
        let data = response.payload;
        this.form.forEach((item) => {
          let field = data.find((d) => d.identifier == item.identifier);
          if (field) {
            item.value = field.value;
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async save() {
      // this.$v.$touch();
      // if (this.$v.$error) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            identifier: item.identifier,
            value: item.value ? item.value : "",
          };
        });

        const resp = await ConfigurationService.save(params);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  },
  computed: {
    oldFiles: function() {
      return this.form[0].value ? [{url: this.form[0].value}] : []
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/general-configuration.styl"
</style>
