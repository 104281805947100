<template>
  <form
    class="general-configuration custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Información general del negocio</h2>

    <div ref="formContainer" class="form-container border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="name">Logo</label>
          <p class="label-description">
            Sube tu logo en {{ maxWidth }}px x {{ maxHeight }}px en formato .png
            o .jpg
          </p>
        </div>

        <div class="form-field">
          <UploadImages
            :files="oldFiles"
            :maxWidth="maxWidth"
            :maxHeight="maxHeight"
            @onChange="onChange"
            @remove="removeImage"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="businessName">Nombre de negocio</label>
        </div>

        <div class="form-field">
          <FormInput
            id="businessName"
            type="text"
            maxlength="100"
            placeholder="Ingresar nombre de negocio"
            v-model="form[1].value"
          />

          <!-- <FormError
          v-if="$v.form.businessName.$error && !$v.form.businessName.required"
          message="R.U.C. es requerido"
        />
        <FormError
          v-if="$v.form.businessName.$error && !$v.form.businessName.numeric"
          message="No es un número de documento válido"
        /> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="link">Enlace sitio web</label>
        </div>

        <div class="form-field">
          <FormInput
            id="link"
            type="url"
            maxlength="100"
            placeholder="Ingresar enlace sitio web"
            v-model="form[2].value"
          />

          <!-- <form-error
          message="Razón social es requerido"
          v-if="$v.form.link.$error && !$v.form.link.required"
        ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="whatsapp">Número de WhatsApp</label>
        </div>

        <div class="form-field">
          <b-input-group
            prepend="+51"
            class="form-phone-group d-flex flex-nowrap rounded-0"
          >
            <FormInput
              id="whatsapp"
              type="text"
              maxlength="20"
              placeholder="Ingresar número de WhatsApp"
              @input="$v.form[3].value.$touch()"
              v-model="form[3].value"
            />
          </b-input-group>

          <FormError
            v-if="$v.form[3].value.$error && !$v.form[3].value.regex"
            message="Número de WhatsApp solo debe contener números"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="messenger">Enlace Messenger</label>
        </div>

        <div class="form-field">
          <FormInput
            id="messenger"
            type="url"
            maxlength="100"
            placeholder="Ingresar enlace messenger"
            v-model="form[4].value"
          />

          <!-- <FormError
          v-if="$v.form.messenger.$error && !$v.form.messenger.required"
          message="Celular es requerido"
        />
        <FormError
          v-if="$v.form.messenger.$error && !$v.form.messenger.numeric"
          message="Celular debe tener solo valores numéricos"
        /> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="email">Correo electrónico</label>
        </div>

        <div class="form-field">
          <FormInput
            id="email"
            type="email"
            maxlength="100"
            placeholder="Ingresar correo electrónico"
            v-model="form[5].value"
          />

          <!-- <form-error
          message="Correo electrónico es requerido"
          v-if="$v.form.email.$error && !$v.form.email.required"
        ></form-error>
        <form-error
          message="Ingrese un correo electrónico válido"
          v-if="$v.form.email.$error && !$v.form.email.email"
        ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="mobile">Número de celular</label>
        </div>

        <div class="form-field">
          <b-input-group
            prepend="+51"
            class="form-phone-group d-flex flex-nowrap rounded-0"
          >
            <FormInput
              id="mobile"
              type="text"
              maxlength="20"
              placeholder="Ingresar número de celular"
              @input="$v.form[6].value.$touch()"
              v-model="form[6].value"
            />
          </b-input-group>

          <FormError
            v-if="$v.form[6].value.$error && !$v.form[6].value.regex"
            message="Número de celular solo debe contener números"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container">
          <label for="dealers">Añadir marketplaces</label>
        </div>

        <div class="form-field">
          <b-form-checkbox id="dealers" v-model="form[7].value" switch>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group-buttons text-right mt-auto mb-0 pt-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { Alert, FormInput, FormError, UploadImages } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import { BiolinkConfigurationService } from "@/core/services";
import { helpers } from "vuelidate/lib/validators";

import UploadImagesMixin from "@/core/mixins/UploadImagesMixin";

export default {
  mixins: [UploadImagesMixin],
  data() {
    return {
      maxWidth: 300, // Especificar en px
      maxHeight: 300,
      storeTypes: [
        LocalConstants.StoreTypes.BOUTIQUE,
        LocalConstants.StoreTypes.MINIMARKET,
      ],
      form: [
        {
          id: "LOGO",
          value: null,
        },
        {
          id: "BUSINESS_NAME",
          value: null,
        },
        {
          id: "WEB_URL",
          value: null,
        },
        {
          id: "WHATSAPP_NUMBER",
          value: null,
        },
        {
          id: "MESSENGER_URL",
          value: null,
        },
        {
          id: "EMAIL",
          value: null,
        },
        {
          id: "MOBILE",
          value: null,
        },
        {
          id: "ADD_DEALERS",
          value: null,
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  props: {
    business: Object,
  },
  components: {
    FormInput,
    UploadImages,
    FormError,
  },
  validations: {
    form: {
      3: {
        value: {
          regex: helpers.regex("alpha", /^[0-9]*$/),
        },
      },
      6: {
        value: {
          regex: helpers.regex("alpha", /^[0-9]*$/),
        },
      },
    },
  },
  methods: {
    async onChange(files) {
      try {
        if (!files.length) return;

        let uploadedfiles = await this.uploadFiles(files);

        if (uploadedfiles.length) {
          this.form[0].value = uploadedfiles[0].url;
        }
      } catch (error) {
        console.error(error);
        Alert.error(error);
      }
    },
    removeImage() {
      this.form[0].value = null;
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            id: item.id,
            value: item.value ? item.value : "",
          };
        });

        const resp = await BiolinkConfigurationService.save(params);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await BiolinkConfigurationService.list();
        let data = JSON.parse(JSON.stringify(response.payload));
        this.form.forEach((item) => {
          let field = data.find((d) => d.id == item.id);
          if (field) {
            item.value = field.value || null;
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    oldFiles: function () {
      return this.form[0].value ? [{ url: this.form[0].value }] : [];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
</style>
