<template>
  <form
    class="
      general-configuration
      custom-form
      h-100
      d-flex
      flex-column
      animate__animated animate__fadeIn
    "
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Redes sociales</h2>

    <div class="form-container border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="facebook">Facebook</label>
        </div>

        <div class="form-field">
          <FormInput
            id="facebook"
            type="text"
            placeholder="Ingresar Facebook"
            v-model="form[0].value"
          />

          <!-- <form-error
          message="Facebook"
          v-if="$v.form.facebook.$error && !$v.form.facebook.required"
        ></form-error> -->
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="instagram">Instagram</label>
        </div>

        <div class="form-field">
          <FormInput
            id="instagram"
            type="text"
            placeholder="Ingresar instagram"
            v-model="form[1].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="twitter">Twitter</label>
        </div>

        <div class="form-field">
          <FormInput
            id="twitter"
            type="text"
            placeholder="Ingresar twitter"
            v-model="form[2].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="youtube">Youtube</label>
        </div>

        <div class="form-field">
          <FormInput
            id="youtube"
            type="text"
            placeholder="Ingresar youtube"
            v-model="form[3].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="pinterest">Pinterest</label>
        </div>

        <div class="form-field">
          <FormInput
            id="pinterest"
            type="text"
            placeholder="Ingresar pinterest"
            v-model="form[4].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="tiktok">Tiktok</label>
        </div>

        <div class="form-field">
          <FormInput
            id="tiktok"
            type="text"
            placeholder="Ingresar tiktok"
            v-model="form[5].value"
          />
        </div>
      </div>
    </div>

    <div class="form-group-buttons text-right mt-auto mb-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { ConfigurationService } from "@/core/services";
// import { required } from "vuelidate/lib/validators";

import { Alert, FormInput } from "wize-admin";

export default {
  data() {
    return {
      // form: {
      //   facebook: null,
      //   instagram: null,
      //   twitter: null,
      //   youtube: null,
      //   pinterest: null,
      //   tiktok: null
      // },
      form: [
        {
          identifier: "FACEBOOK",
          value: null,
        },
        {
          identifier: "INSTAGRAM",
          value: null,
        },
        {
          identifier: "TWITTER",
          value: null,
        },
        {
          identifier: "YOUTUBE",
          value: null,
        },
        {
          identifier: "PINTEREST",
          value: null,
        },
        {
          identifier: "TIKTOK",
          value: null,
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  components: {
    FormInput,
  },
  validations: {
    form: {
      // facebook: { required },
      // whatsapp: { required, numeric },
      // description: { required }
    },
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            identifier: item.identifier,
            value: item.value ? item.value : "",
          };
        });

        const resp = await ConfigurationService.save(params);

        if (resp.status === "OK") {
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await ConfigurationService.list();
        let data = response.payload;
        this.form.forEach((item) => {
          let field = data.find((d) => d.identifier == item.identifier);
          if (field) {
            item.value = field.value;
          }
        });
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/general-configuration.styl';
</style>
