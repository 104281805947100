<template>
  <div class="page-container category-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar categoría" : "Nueva categoría" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card tag="article" no-body style="max-width: 640px" class="custom-card">
            <b-card-header header-tag="section" class="custom-card-header d-flex align-items-center">
              <h3>
                {{
                  editing
                    ? "Datos de la categoría"
                    : "Datos de la nueva categoría"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body p-0">

              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section">
                  <h2 class="custom-card-subtitle">Información general</h2>
                  <p class="custom-card-description">
                    Ingresa los datos
                    {{ editing ? "de la categoría" : "de la nueva categoría" }}
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="name">Nombre de categoría *</label>

                        <FormInput id="name" type="text" placeholder="Ingresar nombre" :show-error="$v.category.name.$error &&
                          !$v.category.name.required
                          " v-model="category.name" />

                        <form-error message="Nombre es requerido" v-if="
                          $v.category.name.$error &&
                          !$v.category.name.required
                        "></form-error>
                      </div>

                      <div class="form-group">
                        <label for="slug">Slug *</label>

                        <FormInput id="slug" type="text" placeholder="Ingresar slug" :show-error="$v.category.slug.$error &&
                          !$v.category.slug.required
                          " v-model="category.slug" />

                        <form-error message="Slug es requerido" v-if="
                          $v.category.slug.$error &&
                          !$v.category.slug.required
                        "></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="parentId">Categoría superior</label>

                        <FormSelect id="parentId" v-model="category.parentId" :items="parentCategories"
                          defaultOption="Elegir categoría superior" />
                      </div>
                    </div>

                    <div class="col-12"></div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="shortDescription">Descripción corta</label>

                        <textarea id="shortDescription" placeholder="Escriba aquí" class="custom-form-control"
                          v-model="category.shortDescription"></textarea>
                      </div>

                      <div class="form-group">
                        <label for="description">Descripción</label>
                        <global-trumbowyg v-model="category.description" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Foto de la categoría</h2>

                  <p class="custom-card-description">
                    Agregue una foto de la categoría en el siguiente tamaño:
                  </p>

                  <UploadImages :maxFiles="1" :files="setOldFile" @onChange="onChange" @remove="removeImage" />
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Datos SEO</h2>

                  <p class="custom-card-description">
                    Ingresa los siguientes datos SEO
                  </p>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="seoTitle">Título SEO ({{ category.seoTitle ? category.seoTitle.length : 0 }})</label>
                        <FormInput id="seoTitle" type="text" placeholder="Ingresar título SEO"
                          v-model="category.seoTitle" />
                        <form-error
                        message="El título SEO puede tener hasta 70 caracteres"
                          v-if="$v.category.seoTitle.$error && !$v.category.seoTitle.maxLength"
                        ></form-error>
                      </div>

                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="seoKeywords">Palabras Clave</label>
                        <FormInput id="seoKeywords" type="text" placeholder="Ingresar palabras clave"
                          v-model="category.seoKeywords" />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group mb-0">
                        <label for="seoMeta">Meta description ({{ category.seoMeta ? category.seoMeta.length : 0 }})</label>
                        <textarea id="seoMeta" placeholder="Escriba aquí" class="custom-form-control"
                          v-model="category.seoMeta"></textarea>
                        <form-error
                          message="El meta description puede tener hasta 320 caracteres"
                          v-if="$v.category.seoMeta.$error && !$v.category.seoMeta.maxLength"
                        ></form-error>
                      </div>
                    </div>

                    <div class="form-group-buttons text-right">
                      <router-link :to="{ name: 'config-categories-list' }" class="button button-light">
                        {{ editing ? "Volver" : "Cancelar" }}
                      </router-link>
                      <button class="button button-primary" type="submit">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  FormError,
  FormInput,
  FormSelect,
  StringUtil,
  UploadImages
} from "wize-admin";

import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";
import UploadImagesMixin from '@/core/mixins/UploadImagesMixin';
import { CategoryService } from "@/core/services";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  mixins: [UploadImagesMixin],
  data() {
    return {
      parentCategories: [],
      showMap: false,
      category: {
        name: null,
        slug: null,
        assetId: null,
        preview: null,
        shortDescription: null,
        description: null,
        parentId: null,
        seoTitle: null,
        seoKeywords: null,
        seoMeta: null,
      },
      editing: false,
    };
  },
  components: {
    FormError,
    FormInput,
    FormSelect,
    UploadImages,
    GlobalTrumbowyg
  },
  validations: {
    category: {
      name: { required },
      slug: { required },
      seoTitle: {maxLength: maxLength(70)},
      seoMeta: {maxLength: maxLength(320)}
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
    "category.name": function (val) {
      if (val && !this.editing) {
        this.category.slug = StringUtil.toSlug(val);
      }
    },
  },
  methods: {
    async onChange(files) {
      try {
        let uploadedfiles = await this.uploadFiles(files)

        this.category.assetId = uploadedfiles[0].id;
      } catch (error) {
        alert('Error')
        console.error(error);
      }
    },
    removeImage() {
      this.category.image = null
      this.category.assetId = null
    },
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true)

      let category = { ...this.category };
      category.seoMeta = category.seoMeta ? category.seoMeta.trim() : "";
      category.seoTitle = category.seoTitle ? category.seoTitle.trim() : "";

      if (category.image) {
        category.assetId = category.image.id
      }

      delete category.preview;

      const data = { ...category }

      try {
        let response;
        if (!this.editing) response = await CategoryService.save(data);
        else
          response = await CategoryService.update(this.$route.params.id, data);

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-categories-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
        this.$store.commit('app/setCategories', null)
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false)
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await CategoryService.get(id);
        this.category = { ...response.payload };
        this.category.parentId = response.payload.parent
          ? response.payload.parent.id
          : null;

        // Eliminar categoría actual de opciones padre
        let categoryIndex = this.parentCategories.findIndex(item => item.key == this.category.id)
        this.parentCategories.splice(categoryIndex, 1)

        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado") message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({ name: "config-categories-list" }));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: "app/getCategories"
    }),
    setOldFile: function () {
      if (this.category.id && this.category.image) {
        return [this.category.image]
      } else {
        return []
      }
    }
  },
  async created() {
    await this.$store.dispatch("app/loadCategories");
    this.parentCategories = this.categories.map(c => ({ key: c.id, label: c.name }));
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus">
@import "../Styles/category-editor.styl"
</style>
