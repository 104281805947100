<template>
  <nav class="navbar nav-header py-0">
    <div
      class="w-100 d-flex align-items-center justify-content-between position-relative"
    >
      <!-- Mobile -->
      <div class="d-flex align-items-center d-lg-none">
        <img src="@/core/assets/images/layout/logo-razzil.png" alt="Razzil" class="mr-2">
        <span class="sidebar-title text-white w-50">RAZZIL</span>
      </div>

      <div class="d-lg-none">
        <button type="button" class="bg-transparent px-0 border-0" @click="toggleMenu(true)">
          <MenuIcon />
        </button>
      </div>

      <!-- Desktop -->
      <a
        :href="websiteUrl"
        target="_blank"
        class="d-none open-website-link text-white text-decoration-none align-items-md-center d-lg-flex justify-content-md-center"
        ><WorldIcon class="fill-white mr-md-1" />
        <span class="text-center d-none d-md-inline-block"
          >Visitar sitio web</span
        ></a
      >

      <UserDropdown :user="user" @logout="logout()" class="d-none d-lg-block" />
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { UserDropdown, WorldIcon, MenuIcon } from "wize-admin";

export default {
  name: "PageHeader",
  data() {
    return {
      showCart: false,
      categories: [],
      subcategories: [],
    };
  },
  components: {
    UserDropdown,
    WorldIcon,
    MenuIcon,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    websiteUrl: function () {
      return process.env.VUE_APP_WEB_URL;
    },
  },
  methods: {
    ...mapMutations({
      toggleMenu: "app/toggleMenu"
    }),
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
};
</script>

<style scoped lang="stylus"></style>
