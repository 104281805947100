<template>
  <div class="page-container form-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar redirección" : "Nuevo redirección" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing ? "Datos de la redirección" : "Datos de la nueva redirección"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section py-0 border-0">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="type">Tipo de redirección *</label>
                        <FormSelect
                          id="type"
                          v-model="form.type"
                          :items="redirectTypes"
                          defaultOption="Elegir tipo de redirección"
                          :showError="$v.form.type.$error"
                        />
                        <form-error
                          message="Tipo de redirección es requerido"
                          v-if="
                            $v.form.type.$error &&
                            !$v.form.type.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="from">URL antigua *</label>
                        <FormInput
                          id="from"
                          type="text"
                          placeholder="Ingresar url antigua"
                          maxlength="150"
                          :show-error="
                            ($v.form.from.$error &&
                              !$v.form.from.required) ||
                            ($v.form.from.$error &&
                              !$v.form.from.maxLength)
                          "
                          v-model="form.from"
                        />
                        <form-error
                          message="URL antigua es requerida"
                          v-if="
                            $v.form.from.$error && !$v.form.from.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group mb-0">
                        <label for="to">URL nueva {{ disableTo ? '' : '*' }} </label>
                        <FormInput
                          id="to"
                          maxlength="250"
                          type="text"
                          :disabled="disableTo"
                          placeholder="Ingresar URL nueva"
                          :show-error="
                            $v.form.to.$error && !$v.form.to.required
                          "
                          v-model="form.to"
                        />
                        <form-error
                          message="URL nueva es requerida"
                          v-if="
                            $v.form.to.$error && !$v.form.to.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pt-0">
                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'tools-redirections-list' }"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  FormError,
  FormSelect,
  FormInput,
} from "wize-admin";

import { required, requiredIf } from "vuelidate/lib/validators";

import { RedirectService } from "@/core/services";

import { Constants as LocalConstants } from '@/core/utils'

export default {
  data() {
    return {
      form: {
        type: null,
        from: null,
        to: null
      },
      redirectTypes: [
        LocalConstants.RedirectionsOptions.MOVED_PERMANENTLY,
        LocalConstants.RedirectionsOptions.FOUND,
        LocalConstants.RedirectionsOptions.TEMPORARY_REDIRECT,
        LocalConstants.RedirectionsOptions.GONE,
        LocalConstants.RedirectionsOptions.UNAVAILABLE_FOR_LEGAL_REASONS
      ],
      editing: false,
      disableTo: false
    };
  },
  components: {
    FormError,
    FormInput,
    FormSelect,
  },
  validations: {
    form: {
      type: { required },
      from: { required },
      to: {
        required: requiredIf((model) => {
          return model.type == LocalConstants.RedirectionsOptions.MOVED_PERMANENTLY.key || model.type == LocalConstants.RedirectionsOptions.FOUND.key || model.type == LocalConstants.RedirectionsOptions.TEMPORARY_REDIRECT.key;
        }),
      },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
    "form.type": function () {
      if (this.form.type == LocalConstants.RedirectionsOptions.GONE.key ||
        this.form.type == LocalConstants.RedirectionsOptions.UNAVAILABLE_FOR_LEGAL_REASONS.key) {
        this.form.to = null;
        this.disableTo = true;
      } else {
        this.disableTo = false;
      }
    }
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);

      const data = {
        ...this.form,
      };

      try {
        let response;
        if (!this.editing) {
          response = await RedirectService.save(data);
        } else {
          response = await RedirectService.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "tools-redirections-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await RedirectService.get(id);

        this.form = {
          ...response.payload,
        };

        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus">
</style>
