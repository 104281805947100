<template>
  <div class="select-days position-relative">
    <button type="button" class="custom-select-button text-left" @click="setShowContent">
      <span>{{ label }}</span>

      <ArrowBottomIcon class="form-select-arrow" />
    </button>

    <section class="content overflow-hidden" v-if="showContent">
      <div class="select-delivery-days-content">
        <div v-for="item in daysList" :key="item.label" class="select-delivery-days-item d-flex align-items-center">
          <b-form-checkbox :id="item.label" v-model="item.status" name="check-button" @change="verifySelection(item)">
          </b-form-checkbox>
          <span class="check-label" @click="changeStatusByLabel(item)">{{
            item.label
          }}</span>
        </div>
      </div>

      <section class="footer d-flex justify-content-between">
        <a href="" class="button button-light" @click.prevent="showContent = false">
          Cancelar
        </a>
        <button class="button button-primary" type="button" @click="selectedOptions()">
          Aplicar
        </button>
      </section>
    </section>

    <div class="overlay" v-if="showContent" @click="showContent = false"></div>
  </div>
</template>

<script>
import { ArrowBottomIcon } from "wize-admin";

export default {
  data() {
    return {
      showContent: false,
      label: "Selecciona los días",
    };
  },
  mounted() {
    this.generateLabel(this.oldSelection)
  },
  components: {
    ArrowBottomIcon,
  },
  props: {
    oldSelection: Array,
    daysList: Array
  },
  watch: {
    oldSelection: function () {
      this.generateLabel(this.oldSelection)
    }
  },
  methods: {
    selectedOptions() {
      let selected = this.daysList.filter((item) => item.status);
      selected = selected.map(item => item.key)
      this.$emit("selected", selected);
      this.daysList.forEach((item) => item.status = false)
      this.showContent = false;
    },
    changeStatusByLabel(item) {
      item.status = !item.status;

      this.verifySelection(item)
    },
    verifySelection(item) {
      let draft = { ...item }

      if (item.unique) {

        this.daysList.forEach((item2) => item2.status = false);
        item.status = draft.status

      } else {

        this.daysList.forEach((item2) => item2.unique ? item2.status = false : true)
        item.status = draft.status

      }
    },
    generateLabel(selected) {
      if (!selected || selected.length === 0) {
        this.label = 'Selecciona los días';
        return
      }

      const dayOrder = {
        "l": 1,
        "m": 2,
        "mi": 3,
        "j": 4,
        "v": 5,
        "s": 6,
        "d": 7
      };

      let oldList = [...selected]

      oldList.sort((a, b) => dayOrder[a] - dayOrder[b])

      let list = []

      oldList.forEach(item => {
        let info = this.daysList.find(item2 => item2.key == item)

        if (info) {
          list.push(info)
        }
      })

      oldList = list.map((item) => item.label).join(", ");

      if (oldList.length >= 100) {
        oldList = oldList.slice(0, 100) + "...";
      }

      this.label = oldList;
    },
    setShowContent() {
      if (this.showContent) {
        this.showContent = false;

        return;
      } else {
        this.daysList.forEach((item) => item.status = false)

        this.oldSelection.forEach(item => {
          let selected = this.daysList.find(day => day.key == item)
          selected.status = true
        })

        this.generateLabel(this.oldSelection);
        this.showContent = true;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../styles/components/select-days.styl';
</style>
