var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list.length)?_c('div',{staticClass:"quotes quotes-table custom-form m-0"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"custom-table w-100"},[_vm._m(0),_c('tbody',_vm._l((_vm.list),function(o,i){return _c('tr',{key:o.id},[_c('td',{staticClass:"text-center pl-md-4"},[_c('div',{staticClass:"table-image cursor-pointer"},[(o.thumbnail)?_c('img',{attrs:{"src":o.thumbnail,"alt":""}}):_vm._e()])]),_c('td',{staticClass:"product-name pl-3"},[_vm._v(_vm._s(o.name || "-"))]),_c('td',{staticClass:"text-center"},[_c('FormInput',{staticClass:"product-quantity",attrs:{"type":"number","max":o.stock,"placeholder":"Ingresar","show-error":_vm.validationList &&
                !_vm.validationList.$each[i].quantity.required &&
                _vm.validationList.$each[i].quantity.$error,"tabindex":"1"},model:{value:(o.quantity),callback:function ($$v) {_vm.$set(o, "quantity", $$v)},expression:"o.quantity"}}),(
                _vm.validationList &&
                !_vm.validationList.$each[i].quantity.required &&
                _vm.validationList.$each[i].quantity.$error
              )?_c('form-error',{attrs:{"message":"Campo requerido"}}):_vm._e()],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(o.stock))]),_c('td',{staticClass:"text-center"},[_c('FormInput',{staticClass:"product-quantity",attrs:{"type":"number","placeholder":"Ingresar","show-error":_vm.validationList &&
                !_vm.validationList.$each[i].price.required &&
                _vm.validationList.$each[i].price.$error,"tabindex":"1"},model:{value:(o.price),callback:function ($$v) {_vm.$set(o, "price", $$v)},expression:"o.price"}}),(
                _vm.validationList &&
                !_vm.validationList.$each[i].price.required &&
                _vm.validationList.$each[i].price.$error
              )?_c('form-error',{attrs:{"message":"Campo requerido"}}):_vm._e()],1),_c('td',{directives:[{name:"decimal",rawName:"v-decimal:pen",value:(o.price * o.quantity),expression:"o.price * o.quantity",arg:"pen"}],staticClass:"text-center"}),_c('td',{staticClass:"text-center custom-form d_option pl-md-5 pr-md-4"},[_c('button',{staticClass:"remove-item bg-danger rounded-circle border-0 d-flex justify-content-center align-items-center",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('remove-selection', o)}}},[_c('TrashIcon',{staticClass:"fill-white trash-icon"})],1)])])}),0)])]),_c('div',{staticClass:"details d-flex align-items-center justify-content-end mt-2 py-2 px-5"},[_c('p',{staticClass:"details-item my-0 pr-4"},[_vm._v(" Cantidad de items: "),_c('span',{staticClass:"text-color-dark font-weight-bold"},[_vm._v(_vm._s(_vm.list.length))])]),_c('h3',{staticClass:"font-sm details-item details-item-total my-0 pl-4"},[_vm._v(" Total: "),_c('span',{directives:[{name:"decimal",rawName:"v-decimal:pen",value:(_vm.total),expression:"total",arg:"pen"}],staticClass:"text-color-dark font-weight-bold"})])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Imagen")]),_c('th',[_vm._v("Descripción")]),_c('th',[_vm._v("Cantidad")]),_c('th',[_vm._v("Stock")]),_c('th',{staticClass:"px-3"},[_vm._v("Precio unitario")]),_c('th',{staticClass:"px-3"},[_vm._v("Valor")]),_c('th',{staticClass:"pr-md-3"})])])}]

export { render, staticRenderFns }