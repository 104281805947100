<template>
  <div class="select-time-range position-relative">
    <button
      type="button"
      class="button-select text-left"
      @click="showContent = !showContent"
    >
      <span>{{ label }}</span>

      <ArrowBottomIcon class="form-select-arrow" />
    </button>

    <section class="content bg-white" v-if="showContent">
      <ul>
        <li v-for="item in options" :key="item.key" @click="select(item)">{{ item.label }}</li>
      </ul>
    </section>

    <div class="overlay" v-if="showContent" @click="showContent = false"></div>
  </div>
</template>

<script>
import { ArrowBottomIcon } from "wize-admin";

export default {
  data() {
    return {
      showContent: false,
      label: null
    };
  },
  mounted() {
    if(this.oldValue) {
      this.setLabel(this.oldValue)
    } else {
      this.label = 'Seleccione'
    }
  },
  props: {
    options: Array,
    oldValue: String
  },
  components: {
    ArrowBottomIcon,
  },
  watch: {
    oldValue: function(value) {
      this.setLabel(value)
    }
  },
  methods: {
    select(item) {
        this.setLabel(item.label)

        this.$emit('selected', {...item})
        this.showContent = false
    },
    setLabel(value) {
      let item = this.options.find(item => item.label == value)

      if(item) {
        this.label = item.label
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../../styles/components/select-time-range.styl'
</style>
