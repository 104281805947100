<template>
  <div class="page-container page-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar página" : "Nueva página" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing ? "Datos de la página" : "Datos de la nueva página"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle">Información general</h2>
                  <p class="custom-card-description">
                    Ingresa los datos
                    {{ editing ? "de la página" : "de la nueva página" }}
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="name">Título de la página *</label>

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar título"
                          v-model="page.name"
                          disabled
                        />
                      </div>

                      <div class="form-group">
                        <label for="slug">Slug *</label>

                        <FormInput
                          id="slug"
                          type="text"
                          placeholder="Ingresar slug"
                          disabled
                          v-model="page.slug"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="description">Descripción</label>
                        <global-trumbowyg v-model="page.description"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Datos SEO</h2>

                  <p class="custom-card-description">
                    Ingresa los siguientes datos SEO
                  </p>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="seoTitle">Título SEO ({{ page.seoTitle ? page.seoTitle.length : 0 }})</label>
                        <FormInput
                          id="seoTitle"
                          type="text"
                          placeholder="Ingresar título SEO"
                          v-model="page.seoTitle"
                        />
                        <form-error
                          message="El título SEO puede tener hasta 70 caracteres"
                            v-if="$v.page.seoTitle.$error && !$v.page.seoTitle.maxLength"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="seoKeywords">Palabras Clave</label>
                        <FormInput
                          id="seoKeywords"
                          type="text"
                          placeholder="Ingresar palabras clave"
                          v-model="page.seoKeywords"
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group mb-0">
                        <label for="seoMeta">Meta description ({{ page.seoMeta ? page.seoMeta.length : 0 }})</label>
                        <textarea
                          id="seoMeta"
                          placeholder="Escriba aquí"
                          class="custom-form-control"
                          v-model="page.seoMeta"
                        ></textarea>
                        <form-error
                        message="El meta description puede tener hasta 320 caracteres"
                        v-if="$v.page.seoMeta.$error && !$v.page.seoMeta.maxLength"
                        ></form-error>
                      </div>
                    </div>

                    <div class="form-group-buttons text-right">
                      <router-link
                        :to="{ name: 'config-pages-list' }"
                        class="button button-light"
                      >
                        {{ editing ? "Volver" : "Cancelar" }}
                      </router-link>
                      <button class="button button-primary" type="submit">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, FormInput, StringUtil, Constants, FormError, } from "wize-admin";

import { required, maxLength } from "vuelidate/lib/validators";

import { PostService } from "@/core/services";
import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";

export default {
  data() {
    return {
      page: {
        name: null,
        slug: null,
        description: null,
        seoTitle: null,
        seoKeywords: null,
        seoMeta: null,
      },
      editing: false,
    };
  },
  components: {
    FormInput,
    GlobalTrumbowyg,
    FormError
  },
  validations: {
    page: {
      name: { required },
      slug: { required },
      seoTitle: {maxLength: maxLength(70)},
      seoMeta: {maxLength: maxLength(320)}
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
    "page.name": function (val) {
      if (val && !this.editing) {
        this.page.slug = StringUtil.toSlug(val);
      }
    },
  },
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);

      let page = { ...this.page };

      const data = { ...page };

      try {
        let response;
        if (!this.editing) response = await PostService.save(data);
        else response = await PostService.update(this.$route.params.id, data);

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-categories-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await PostService.get(id);
        this.page = { ...response.payload };

        this.editing = true;
      } catch (ex) {
        let status = ex.status;

        if (status == "NOT_FOUND") {
          let message = Constants.Errors.RECORD_NOT_FOUND;

          Alert.error(message, () =>
            this.$router.push({ name: "config-pages-list" })
          );
        } else {
          Alert.error(ex.errors.message)
        }
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped></style>
