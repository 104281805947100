import {LocalStorage} from 'wize-admin'
import {UserService, SecurityService} from '../core/services'
import abilities from "@/core/plugins/abilities";

const state = {
  accessToken: LocalStorage.getAccessToken(),
  user: {},
  isTokenValid: false,
}

const getters = {
  isAuthenticated: state => state.accessToken,
  getUser: state => state.user,
  isTokenValid: state => state.isTokenValid,
  isUserLoaded: state => !!state.user.username,
};

const actions = {
  setAccessToken({commit}, payload) {
    commit('isTokenValid', true)
    commit('accessToken', payload);
  },
  setUser({commit,dispatch}) {
    return new Promise((resolve, reject) => {
      UserService.getUserInfo().then((resp) => {
        const user = resp.payload;
        commit('user', user);
        commit('app/setCurrentPlan', user.currentPlan, { root: true })
        abilities.defineAbilitiesForPlan(user.currentPlan)
        resolve(user)
      }).catch((ex) => {
        console.error(ex);
        dispatch('logout');
        reject();
      })

    })
  },
  checkValidToken({dispatch, commit}){
    return new Promise((resolve, reject) => {
      SecurityService.isValidToken()
        .then(response => {
          if (response) {
            commit('isTokenValid', true)
            resolve()
          }
        }).catch(err => {
        reject(err);
        dispatch('logout')
      });
    });
  },
  logout({commit}) {
    return new Promise((resolve) => {
      commit('accessToken', null)
      commit('user', null)
      commit('isTokenValid', false)
      resolve()
    })
  }
};

const mutations = {
  isTokenValid(state, value){
    state.isTokenValid = value;
  },
  accessToken(state, value) {
    value ? LocalStorage.setAccessToken(value) : LocalStorage.clearAccessToken();
    state.accessToken = value;
  },
  user(state, value) {
    let user = {
      ...value,
      name: value ? `${value.firstName} ${value.lastName}` : null
    }

    value ? LocalStorage.setUser(user) : LocalStorage.clearUser();
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
