import { http } from '@/http'

export default {
  list(params) {
    return http.get('/api/v1/admin/quote', {params})
  },
  save(payload) {
    return http.post('/api/v1/admin/quote', payload)
  },
  get(id) {
    return http.get(`/api/v1/admin/quote/${id}`);
  },
  update(id, payload) {
    return http.put(`/api/v1/admin/quote/${id}`, payload);
  },
  delete(id) {
    return http.delete(`/api/v1/admin/quote/${id}`);
  }
}
