<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar usuario" : "Nuevo usuario" }}
      </h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex align-items-center"
        >
          <h3>
            {{ editing ? "Datos del usuario" : "Datos del nuevo usuario" }}
          </h3>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-section">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">Correo electrónico *</label>
                    <FormInput
                      id="email"
                      type="text"
                      placeholder="Ingresar correo electrónico"
                      :show-error="
                        $v.user.email.$error && !$v.user.email.required
                      "
                      v-model="user.email"
                    />
                    <form-error
                      message="Usuario es requerido"
                      v-if="$v.user.email.$error && !$v.user.email.required"
                    ></form-error>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="firstName">Nombres *</label>

                    <FormInput
                      id="firstName"
                      type="text"
                      placeholder="Ingresar nombres"
                      :show-error="
                        $v.user.firstName.$error && !$v.user.firstName.required
                      "
                      v-model="user.firstName"
                    />

                    <form-error
                      message="Nombres son requeridos"
                      v-if="
                        $v.user.firstName.$error && !$v.user.firstName.required
                      "
                    ></form-error>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="lastName">Apellidos *</label>
                    <FormInput
                      id="lastName"
                      type="text"
                      placeholder="Ingresar apellidos"
                      :show-error="
                        $v.user.lastName.$error && !$v.user.lastName.required
                      "
                      v-model="user.lastName"
                    />
                    <form-error
                      message="Apellidos son requeridos"
                      v-if="
                        $v.user.lastName.$error && !$v.user.lastName.required
                      "
                    ></form-error>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="mobile">Celular</label>

                    <FormInput
                      id="mobile"
                      type="text"
                      maxlength="20"
                      placeholder="Ingresar celular"
                      @input="$v.user.mobile.$touch()"
                      v-model="user.mobile"
                    />

                    <form-error
                      message="Celular debe contener números o el signo '+'"
                      v-if="!$v.user.mobile.regex"
                    ></form-error>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="profile">Perfil *</label>
                    <FormSelect
                      id="profile"
                      v-model="user.profile"
                      :items="profilesType"
                      defaultOption="Elegir perfil"
                      :showError="$v.user.profile.$error"
                    />

                    <form-error
                      message="Perfil es requerido"
                      v-if="$v.user.profile.$error && !$v.user.profile.required"
                    ></form-error>
                  </div>
                </div>
              </div>

              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'config-users-list' }"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </router-link>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, FormError, FormSelect, FormInput, Constants } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { UserService } from "@/core/services";
import { required, helpers } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: {
        email: null,
        firstName: null,
        lastName: null,
        mobile: null,
        profile: null,
      },
      profilesType: [
        LocalConstants.UsersProfileValues.ADMINISTRATOR,
        LocalConstants.UsersProfileValues.SALES,
        LocalConstants.UsersProfileValues.WAREHOUSE,
      ],
      editing: false,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
  },
  validations: {
    user: {
      email: { required },
      firstName: { required },
      lastName: { required },
      profile: { required },
      mobile: {
        regex: helpers.regex("alpha", /^[0-9+]*$/),
      },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  computed: {},
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      this.$store.dispatch("app/loading", true);

      const data = { ...this.user };

      try {
        let response;
        if (!this.editing) response = await UserService.save(data);
        else response = await UserService.update(this.$route.params.id, data);

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-users-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await UserService.get(id);
        this.user = { ...response.payload };
        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "config-users-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped></style>
