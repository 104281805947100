import OrdersView from "../OrdersView.vue";
import OrderDetails from "../Pages/OrderDetails.vue";
import OrdersList from "../Pages/OrdersList.vue";

const routes = [
  {
    path: '/pedidos',
    plan: ['ALL'],
    name: 'orders',
    component: OrdersView,
    children: [
      {
        path: '',
        name: 'orders-list',
        plan: ['ALL'],
        component: OrdersList
      },
      {
        path: ':id',
        name: 'order-details',
        plan: ['ALL'],
        component: OrderDetails
      }
    ]
  },
]

export default routes
