<template>
  <page-base>
    <router-view></router-view>
  </page-base>
</template>

<script>
import {PageBase} from "@/core/components"

export default {
  name: "BusinessesView",
  components: {
    PageBase
  }
}
</script>

<style scoped lang="stylus">
</style>
