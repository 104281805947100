<template>
  <div class="dashboard-page">
    <section class="dashboard-banner payment-methods">
      <h1 class="dashboard-title">Métodos de pago</h1>

      <div class="dashboard-container">
        <div
          class="dashboard-card payment-methods-card"
          v-if="paymentMethodsList.length"
        >
          <div
            class="payment-methods-card-header d-flex justify-content-between align-items-center"
          >
            <h2 class="subtitle">Múltiples métodos de pago</h2>

            <div class="options text-center">
              <h4 class="subtitle-2">Habilitar</h4>
              <h4 class="subtitle-2">Editar</h4>
            </div>
          </div>

          <div
            class="payment-item text-decoration-none d-flex justify-content-between align-items-center"
            :class="{ last: index === paymentMethodsList.length - 1 }"
            v-for="(item, index) in paymentMethodsList"
            :key="item.key"
          >
            <span
              class="payment-name text-white text-center text-uppercase d-flex align-items-center"
              v-if="!item.logo"
            >
              {{ item.label }}
            </span>

            <div class="image-container" v-else>
              <img
                :src="require(`@/core/assets/images/payments/${item.logo}`)"
                :alt="item.label"
                class="img-fluid"
              />
            </div>

            <div
              class="options"
              :class="{
                'd-flex align-items-center justify-content-end': !item.switch,
              }"
            >
              <b-form-checkbox
                class="ml-2"
                v-model="item.isActive"
                switch
                @change="updatePaymentMethodActive($event, item)"
                v-if="item.switch"
              >
              </b-form-checkbox>
              <div>
                <router-link
                  :to="{
                    name: 'config-payment-details',
                    params: { slug: item.key },
                  }"
                  class="button text-white text-decoration-none button-primary"
                >
                  Editar
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  ConfirmationModalMixin,
  FilterMixin,
  PaginationMixin,
} from "wize-admin";

import PaymentMethodsMixin from "@/core/mixins/PaymentMethodsMixin";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  name: "PaymentMethods",
  mixins: [
    PaginationMixin,
    ConfirmationModalMixin,
    FilterMixin,
    PaymentMethodsMixin,
  ],
  data() {
    return {
      paymentMethodsList: [
        {
          ...LocalConstants.PaymentMethods.WIRE_TRANSFER,
        },
        {
          key: "PAY_ONLINE",
          label: "Pago en línea con tarjeta",
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.UPON_DELIVERY,
          switch: true,
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.UPON_DELIVERY_POS,
          switch: true,
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.LINK,
          switch: true,
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.YAPE,
          switch: true,
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.PLIN,
          switch: true,
          isActive: false,
        },
        {
          ...LocalConstants.PaymentMethods.PAGO_EFECTIVO,
          switch: true,
          isActive: false,
        },
      ],
      serverList: [],
    };
  },
  methods: {
    edit(key) {
      this.$router.push({
        name: "config-payment-details",
        params: { slug: key },
      });
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus">
@import '../Styles/payment-methods.styl';
</style>
