var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container provider-editor"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar proveedor" : "Nuevo proveedor")+" ")])]),_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos del proveedor" : "Datos del nuevo proveedor")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body px-0 pb-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section pt-0"},[_c('h2',{staticClass:"custom-card-subtitle"},[_vm._v("Información general")]),_c('p',{staticClass:"custom-card-description"},[_vm._v(" Ingresa los datos "+_vm._s(_vm.editing ? "del proveedor" : "del nuevo proveedor")+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"redirectTo"}},[_vm._v("Tipo de documento *")]),_c('FormSelect',{attrs:{"id":"redirectTo","show-error":_vm.$v.provider.identifierType.$error &&
                          !_vm.$v.provider.identifierType.required,"items":_vm.identifierTypes,"defaultOption":"Elegir tipo de documento"},model:{value:(_vm.provider.identifierType),callback:function ($$v) {_vm.$set(_vm.provider, "identifierType", $$v)},expression:"provider.identifierType"}}),(
                          _vm.$v.provider.identifierType.$error &&
                          !_vm.$v.provider.identifierType.required
                        )?_c('form-error',{attrs:{"message":"Tipo de documento es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"identifier"}},[_vm._v("Número de documento *")]),_c('FormInput',{attrs:{"id":"identifier","type":"number","maxlength":"20","placeholder":"Ingresar número de documento","show-error":_vm.$v.provider.identifier.$error &&
                          !_vm.$v.provider.identifier.required},model:{value:(_vm.provider.identifier),callback:function ($$v) {_vm.$set(_vm.provider, "identifier", $$v)},expression:"provider.identifier"}}),(
                          _vm.$v.provider.identifier.$error &&
                          !_vm.$v.provider.identifier.required
                        )?_c('form-error',{attrs:{"message":"Número de documento es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre / Razón social *")]),_c('FormInput',{attrs:{"id":"name","type":"text","placeholder":"Ingresar Nombre / Razón social","show-error":_vm.$v.provider.name.$error &&
                          !_vm.$v.provider.name.required},model:{value:(_vm.provider.name),callback:function ($$v) {_vm.$set(_vm.provider, "name", $$v)},expression:"provider.name"}}),(
                          _vm.$v.provider.name.$error &&
                          !_vm.$v.provider.name.required
                        )?_c('form-error',{attrs:{"message":"Nombre / Razón social es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"address"}},[_vm._v("Dirección fiscal * ")]),_c('FormInput',{attrs:{"id":"address","type":"text","placeholder":"Ingresar dirección fiscal * ","show-error":_vm.$v.provider.address.$error &&
                          !_vm.$v.provider.address.required},model:{value:(_vm.provider.address),callback:function ($$v) {_vm.$set(_vm.provider, "address", $$v)},expression:"provider.address"}}),(
                          _vm.$v.provider.address.$error &&
                          !_vm.$v.provider.address.required
                        )?_c('form-error',{attrs:{"message":"Dirección fiscal es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'config-providers-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }