import { Alert } from "wize-admin";

import { ConfigurationService } from "@/core/services";

export default {
  data() {
    return {
      form: [
        {
          identifier: "STORE_TYPE",
          value: null,
        },
        {
          identifier: "COMPANY_ID",
          value: null,
        },
        {
          identifier: "COMPANY_NAME",
          value: null,
        },
        {
          identifier: "TRADE_NAME",
          value: null,
        },
        {
          identifier: "COMPANY_ADDRESS",
          value: null,
        },
        {
          identifier: "MOBILE",
          value: null,
        },
        {
          identifier: "WHATSAPP",
          value: null,
        },
        {
          identifier: "EMAIL",
          value: null,
        },
        {
          identifier: "DESCRIPTION",
          value: null,
        },
        {
          identifier: "INDEX_FLAG",
          value: null,
        },
        {
          identifier: "MAINTENANCE_FLAG",
          value: null,
        },
        {
          identifier: "AGE_VERIFICATION",
          value: null,
        }
      ],
    };
  },
  methods: {
    async getStoreConfig() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await ConfigurationService.list();
        let data = response.payload;
        this.form.forEach((item) => {
          let field = data.find((d) => d.identifier == item.identifier);
          if (field) {
            item.value = field.value || null;
          }
        });
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        this.$store.commit("app/setLoading", true);

        const params = this.form.map((item) => {
          return {
            identifier: item.identifier,
            value: item.value
              ? item.value
              : item.identifier == "INDEX_FLAG" ||
                item.identifier == "MAINTENANCE_FLAG"
              ? false
              : "",
          };
        });

        const resp = await ConfigurationService.save(params);

        if (resp.status === "OK") {
          this.$store.commit("app/setStoreConfig", resp.payload );
          Alert.success("Se ha realizado la actualización correctamente");
        }
      } catch (error) {
        Alert.error(error);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    getStoreTradeName() {
      return this.form.find(item => item.identifier == 'TRADE_NAME').value
    }
  }
};
