<template>
  <div class="page-container product-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar producto" : "Nuevo producto" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card tag="article" no-body style="max-width: 760px" class="custom-card">
            <b-card-header header-tag="section" class="custom-card-header d-flex align-items-center">
              <h3>
                {{
                  editing ? "Datos del producto" : "Datos del nuevo producto"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle">Información general</h2>

                  <p class="custom-card-description">
                    Ingresa los datos del nuevo producto
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="name">Nombre del producto *</label>
                        <FormInput id="name" type="text" placeholder="Ingresar nombre del producto" maxlength="150"
                          :show-error="($v.product.name.$error &&
                            !$v.product.name.required) ||
                            ($v.product.name.$error &&
                              !$v.product.name.maxLength)
                            " v-model="product.name" />
                        <form-error message="Nombre del producto es requerido" v-if="
                          $v.product.name.$error && !$v.product.name.required
                        "></form-error>

                        <form-error message="El nombre de producto debe tener máximo 100 caracteres" v-if="
                          $v.product.name.$error && !$v.product.name.maxLength
                        "></form-error>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="slug">Slug *</label>
                        <FormInput id="slug" maxlength="250" type="text" placeholder="Ingresar slug" :show-error="$v.product.slug.$error && !$v.product.slug.required
                          " v-model="product.slug" />
                        <form-error message="Slug es requerido" v-if="
                          $v.product.slug.$error && !$v.product.slug.required
                        "></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="code">Código de producto *</label>
                        <FormInput id="code" type="text" maxlength="50" placeholder="Ingresar código de producto"
                          :show-error="$v.product.code.$error && !$v.product.code.required
                            " v-model="product.code" />
                        <form-error message="Código de producto es requerido" v-if="
                          $v.product.code.$error && !$v.product.code.required
                        "></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="brand">Marca</label>

                        <FormInput id="brand" type="text" maxlength="100" placeholder="Ingresar marca"
                          v-model="product.brand" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="price">Precio unitario *</label>

                        <FormInput id="price" type="number" placeholder="Precio unitario" min="0" :show-error="$v.product.price.$error &&
                          !$v.product.price.required
                          " v-model="product.price" maxlength="9"
                          oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />

                        <form-error message="Precio unitario es requerido" v-if="
                          $v.product.price.$error &&
                          !$v.product.price.required
                        "></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="baseQuantity">Cantidad base *</label>

                        <FormInput id="baseQuantity" type="number" placeholder="Ingresar cantidad base" min="0"
                          maxlength="9"
                          oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          :show-error="$v.product.baseQuantity.$error &&
                            !$v.product.baseQuantity.required
                            " v-model="product.baseQuantity" />

                        <form-error message="Cantidad base es requerida" v-if="
                          $v.product.baseQuantity.$error &&
                          !$v.product.baseQuantity.required
                        "></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="maxQuantity">Cantidad máxima</label>

                        <FormInput id="maxQuantity" type="number" min="0" maxlength="9"
                          oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          placeholder="Ingresar cantidad máxima" v-model="product.maxQuantity" />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="discount">% de Descuento (No afecta precio unitario)</label>
                        <FormInput id="discount" type="number" min="0" placeholder="Ingresar % de descuento"
                          v-model="product.discount" maxlength="9"
                          oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="stock">Stock *</label>

                        <FormInput id="stock" type="number" min="0" placeholder="Ingresar cantidad máxima" maxlength="9"
                          oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          :show-error="$v.product.stock.$error &&
                            !$v.product.stock.required
                            " v-model="product.stock" />

                        <form-error message="Stock es requerido" v-if="
                          $v.product.stock.$error &&
                          !$v.product.stock.required
                        "></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="unitMeasure">Unidad de medida</label>
                        <FormInput id="unitMeasure" type="text" maxlength="9"
                          oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          placeholder="Ingresar unidad de medida" v-model="product.unitMeasure" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="unitDescription">Dato adicional</label>
                        <FormInput id="unitDescription" type="text" maxlength="200"
                          placeholder="Ingresar dato adicional" v-model="product.unitDescription" />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="supplierId">Proveedor</label>
                        <FormSelect id="supplierId" v-model="product.supplierId" :items="providerOptions"
                          defaultOption="Elegir proveedor" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="description">Descripción del producto</label>
                        <global-trumbowyg v-model="product.description" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Categorización</h2>

                  <p class="custom-card-description">
                    Seleccione las categorías anexadas al producto
                  </p>

                  <div class="form-group" :class="{ 'mb-0': i == formCategories.length - 1 }"
                    v-for="(item, i) in formCategories" :key="item.id">
                    <div class="form-group" :class="{ 'mb-0': i == formCategories.length - 1 }">
                      <b-form-checkbox :id="`category-${item.id}`" v-model="item.value">
                        <span class="font-weight-medium custom-checkbox-label">{{ item.name }}
                        </span>
                      </b-form-checkbox>
                    </div>

                    <div class="pl-3">
                      <div class="row">
                        <div :class="item.children.length > 1 ? 'col-md-4' : 'col-12'" v-for="subitem in item.children"
                          :key="subitem.id">
                          <b-form-checkbox :id="`subcategory-${subitem.id}`" v-model="subitem.value">
                            <span class="font-weight-medium custom-checkbox-label">{{ subitem.name }}</span>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Fotos del producto</h2>

                  <p class="custom-card-description">
                    Agregue las fotos del producto (máximo {{ productMaxImages }})
                  </p>

                  <UploadImages v-if="loadImages" :maxFiles="productMaxImages" :files="product.images"
                    @onChange="onChange" @remove="removeImage" />
                </div>

                <PriceVolumeEditor v-model="volumePrices" ref="priceVolumeEditor" />
                <!-- Variaciones -->
                <div class="form-section">
                  <h2 class="custom-card-subtitle">Combinaciones</h2>

                  <p class="custom-card-description">
                    Esta opción es exclusiva para tiendas tipo Boutique.
                  </p>
                  <section v-if="storeType == boutiqueValue">
                    <div class="row product-conbination-row mb-0 pb-0" v-for="(item, i) in variations" :key="i">
                      <div class="col-md-3 mb-0 pb-0">
                        <div class="form-group" :class="{ 'mb-0': showErrorInAttribute }">
                          <label for="product-conbination-attribute">Atributo</label>
                          <FormSelect id="product-conbination-attribute" v-model="variations[i].attribute"
                            :items="variations[i].attributes" defaultOption="Elegir atributo"
                            @change="changeAttribute(variations[i], i)" />
                        </div>
                      </div>

                      <div class="col-md-3 mb-0 pb-0">
                        <div class="form-group" :class="{ 'mb-0': showErrorInAttribute }">
                          <label for="product-conbination-value">Valor</label>
                          <FormSelect id="product-conbination-value" v-model="variations[i].valueId"
                            :items="variations[i].values" defaultOption="Elegir valor" />
                        </div>
                      </div>

                      <div class="col-md-2 mb-0 pb-0">
                        <div class="form-group" :class="{ 'mb-0': showErrorInAttribute }">
                          <label for="changePrice">Afecta precio</label>
                          <div class="mt-1">
                            <b-form-checkbox :id="`change-price-${i}`" v-model="variations[i].affectPrice"
                              @change="changePrice($event, variations[i])" switch>
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 mb-0 pb-0">
                        <div class="form-group" :class="{ 'mb-0': showErrorInAttribute }">
                          <label for="product-combination-price">Precio</label>
                          <FormInput id="product-combination-price" type="number" min="0"
                            :disabled="!variations[i].affectPrice" placeholder="Ingresar precio" maxlength="9"
                            oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            v-model="variations[i].price" />
                        </div>
                      </div>

                      <div class="col-md-1 d-flex justify-content-center align-items-center">
                        <div>
                          <button type="button"
                            class="remove-item bg-danger rounded-circle border-0 d-flex justify-content-center align-items-center"
                            @click="deleteVariation(i)">
                            <TrashIcon class="fill-white trash-icon" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <FormError class="mb-2" message="Por favor complete el primer atributo."
                      v-if="showErrorInAttribute" />

                    <p class="mb-2" v-if="storeType == boutiqueValue">
                      <PlusIcon class="fill-primary" />
                      <a href="" class="add-link font-weight-bold ml-2" @click.prevent="addVariant()">Agregar</a>
                    </p>
                  </section>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle">Productos envasados</h2>

                  <p class="custom-card-description">Añadir octógonos</p>

                  <div class="row">
                    <div class="col-md-3" v-for="item in octogonos" :key="item.id">
                      <b-form-checkbox :id="`octogono-${item.id}`" v-model="item.value">
                        <span class="font-weight-medium custom-checkbox-label" v-html="item.name"></span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="form-section border-bottom-0 pb-0">
                  <h2 class="custom-card-subtitle">Datos SEO</h2>

                  <p class="custom-card-description">
                    Ingresa los siguientes datos SEO
                  </p>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="seoTitle">Título SEO ({{ product.seoTitle ? product.seoTitle.length : 0 }})</label>
                        <FormInput id="seoTitle" type="text" placeholder="Ingresar título SEO"
                          v-model="product.seoTitle" />
                        <form-error
                          message="El título SEO puede tener hasta 70 caracteres"
                            v-if="$v.product.seoTitle.$error && !$v.product.seoTitle.maxLength"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="seoKeywords">Palabras clave</label>
                        <FormInput id="seoKeywords" type="text" placeholder="Ingresar palabras clave"
                          v-model="product.seoKeywords" />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group mb-0">
                        <label for="seoMeta">Meta description ({{ product.seoMeta ? product.seoMeta.length : 0 }})</label>
                        <textarea id="seoMeta" placeholder="Escriba aquí" class="custom-form-control"
                          v-model="product.seoMeta"></textarea>
                        <form-error
                        message="El meta description puede tener hasta 320 caracteres"
                        v-if="$v.product.seoMeta.$error && !$v.product.seoMeta.maxLength"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pt-0">
                  <div class="form-group-buttons text-right">
                    <router-link :to="{ name: 'products-list' }" class="button button-light">
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Constants as LocalConstants } from "@/core/utils";
import { mapGetters } from "vuex";
import {
  Alert,
  Constants,
  FormError,
  FormInput,
  FormSelect,
  PlusIcon,
  StringUtil,
  TrashIcon,
  UploadImages,
} from "wize-admin";

import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";
import UploadImagesMixin from "@/core/mixins/UploadImagesMixin";
import PriceVolumeEditor from "../Components/PriceVolumeEditor";

import {
  ProductService
} from "@/core/services";
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [UploadImagesMixin],
  data() {
    return {
      attributesList: [],
      values: [LocalConstants.ProductsAttributes.LIST],
      product: {
        name: null,
        slug: null,
        code: null,
        brand: null,
        price: null,
        baseQuantity: null,
        maxQuantity: null,
        discount: null,
        stock: null,
        unitMeasure: null,
        unitDescription: null,
        supplierId: null,
        description: null,
        categoryIds: [],
        assetIds: [],
        seoTitle: null,
        seoKeywords: null,
        seoMeta: null,
        sendEmail: false,
      },
      volumePrices: [],
      maxImages: 1,
      isOpenStartPicker: false,
      providerOptions: [],
      identifierTypes: [
        Constants.IdentifierType.DNI,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.PASSPORT,
      ],
      formCategories: [],
      octogonos: [
        {
          id: 1,
          name: "Alto en sodio",
          key: "sodio",
          value: false,
        },
        {
          id: 2,
          name: "Alto en azúcar",
          key: "azucar",
          value: false,
        },
        {
          id: 3,
          name: "Alto en grasas <br> saturadas",
          key: "grasas-saturadas",
          value: false,
        },
        {
          id: 4,
          name: "Alto en grasas trans",
          key: "grasas-trans",
          value: false,
        },
      ],
      variations: [],
      currentValues: [],
      editing: false,
      showErrorInAttribute: false,
      draftVariations: [],
      storeConfig: null,
      boutiqueValue: LocalConstants.StoreTypes.BOUTIQUE.key,
      loadImages: false,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
    PlusIcon,
    UploadImages,
    TrashIcon,
    GlobalTrumbowyg,
    PriceVolumeEditor
  },
  validations: {
    product: {
      name: { required, maxLength: maxLength(100) },
      slug: { required },
      code: { required },
      price: { required },
      baseQuantity: { required },
      stock: { required },
      seoTitle: {maxLength: maxLength(70)},
      seoMeta: {maxLength: maxLength(320)}
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
      this.initialize();
    },
    "product.name": function (val) {
      if (val && !this.editing) {
        this.product.slug = StringUtil.toSlug(val);
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: "app/getCategories",
      attributes: "app/getAttributes",
      suppliers: "app/getSuppliers",
      getConfValue: "app/getConfValue",
      productMaxImages: "app/getProductMaxImages",
    }),
    storeType() {
      const storeType = this.getConfValue("STORE_TYPE")
      return storeType ? storeType : null;
    },
  },
  methods: {
    async onChange(files) {
      if (!files.length) return;

      try {
        let filesId = await this.uploadFiles(files);
        filesId = filesId.map((item) => item.id);

        this.product.assetIds.push(...filesId);
      } catch (error) {
        Alert.error(error);
      }
    },
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);
      this.$refs.priceVolumeEditor.changeValue();
      const childrenCategories = this.formCategories.reduce((acc, item) => {
        if (item.children) {
          acc.push(...item.children.filter((ch) => ch.value));
        }
        return acc;
      }, []).map((item) => item.id);

      const parentCategories = this.formCategories.filter((item) => item.value).map((item) => item.id);
      this.product.categoryIds = [...childrenCategories, ...parentCategories];

      const variations = this.variations.filter((item) => item.attribute && item.valueId)
        .map((item) => {
          return {
            valueId: item.valueId,
            affectPrice: item.affectPrice,
            price: item.affectPrice ? parseFloat(item.price) : 0,
          };
        });

      const nutrition = this.octogonos.filter((item) => item.value).map((item) => item.key);
      this.product.nutrition = JSON.stringify(nutrition);

      const volumePrices = this.volumePrices.filter((item) => item.min && item.price);
      const data = {
        ...this.product,
        variations,
        priceVariations: {
          VOLUME: JSON.stringify(volumePrices)
        }
      };

      data.seoMeta = data.seoMeta ? data.seoMeta.trim() : "";
      data.seoTitle = data.seoTitle ? data.seoTitle.trim() : "";

      try {
        let response;
        if (!this.editing) {
          response = await ProductService.save(data);

          if (response.status == "OK") {
            // Eliminar variaciones anteriores
            this.variations = [];
          }
        } else {
          response = await ProductService.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "products-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ProductService.get(id);

        let data = {
          ...response.payload,
          assetIds: [],
        };

        this.product = JSON.parse(JSON.stringify(data));
        if(data.priceVariations){
          const priceVariations = JSON.parse(data.priceVariations);
          if(priceVariations.VOLUME){
            this.volumePrices = JSON.parse(priceVariations.VOLUME);
          }
        }

        this.product.supplierId = this.product.supplier
          ? this.product.supplier.id
          : null;
        this.editing = true;
      } catch (ex) {
        console.error(ex);
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    removeImage(i) {
      this.product.assetIds.splice(i, 1);
    },
    addVariant() {
      this.showErrorInAttribute = false;
      const firstVariation = this.variations[0];
      if (firstVariation && !firstVariation.attribute) {
        this.showErrorInAttribute = true;
        return;
      }

      const newVariant = {
        id: this.variations.length + 1,
        attribute: null,
        valueId: null,
        affectPrice: false,
        price: null,
        attributes: [...this.attributesList]
      };

      // Asignar las opciones de los siguientes atributos en base al primero seleccionado
      const currentAttribute = this.attributesList.find(
        (item) => item.key == firstVariation?.attribute
      );
      if(currentAttribute){
        newVariant.attributes = [currentAttribute];
      }	

      this.variations.push(newVariant);
    },
    changeAttribute(variant, i) {
      this.showErrorInAttribute = false;

      variant.valueId = null;
      this.getValues(variant, i);

      // En caso se haya cambiado el atributo de la primera variación, se deben eliminar las anteriores guardadas
      if (i == 0 && this.variations.length > 1) {
        this.variations = [this.variations[0]];
      }
    },
    getValues(variant) {
      if (!variant.attribute) {
        variant.values = [];

        return [];
      }

      // Asignar valores según atributo
      let values = this.attributes.find(
        (item) => item.id == variant.attribute
      ).values;
      variant.values = values.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

     
    },
    changePrice(check, combination) {
      if (!check) {
        combination.price = null;
      }
    },
    toggleVariations(val) {
      if (!val) {
        this.draftVariations = [...this.variations];
        this.variations = [];

        return;
      }

      if (this.draftVariations.length) {
        this.variations = [...this.draftVariations];
        return;
      } else if (val && !this.variations.length) {
        this.variations.push({
          id: 1,
          attribute: null,
          valueId: null,
          affectPrice: false,
          price: null,
          attributes: [...this.attributesList],
        });
      }
    },
    deleteVariation(i) {
      this.variations.splice(i, 1);
    },
    setCategories() {
      this.formCategories = this.categories.filter(c => !c.parent).map((item) => {
        return {
          ...item,
          value: this.isCategorySelected(item),
          children: this.categories.filter(
            (c) => c.parent && c.parent.id == item.id
          ).map(children => { return { ...children, value: this.isCategorySelected(children) } }) ?? [],
        };
      });
    },
    isCategorySelected(category) {
      if (this.product && this.product.categories) {
        return this.product.categories.some((item) => category.id == item.id);
      }
    },
    setAttributes() {
      this.attributesList = this.attributes.map(a => { return { key: a.id, label: a.name } });
      if (this.product.variations && this.product.variations.length) {

        this.product.variations.forEach((item) => {
          const values = this.attributes.find(
            (a) => a.id == item.value.attribute.id
          ).values.map((v) => ({ key: v.id, label: v.name }));

          const variation = {
            attribute: item.value.attribute.id,
            valueId: item.value.id,
            affectPrice: item.price ? true : false,
            price: item.price,
            attributes: [
              this.attributesList.find((a) => a.key == item.value.attribute.id),
            ],
            values,
          };

          this.variations.push(variation);
        });
      }
    },
    setNutritionInfo() {
      if (this.product.nutrition) {
        this.product.nutrition = JSON.parse(this.product.nutrition);
        this.product.nutrition.forEach((item) => {
          let octogono = this.octogonos.find((o) => o.key == item);
          octogono.value = true;
        });
      }
    },
    setAssetsIds() {
      if (this.product.assetIds) {
        this.product.assetIds = this.product.images.map((item) => {
          return item.id;
        });
      }
    },
    setSuppliers() {
      this.providerOptions = this.suppliers.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });
    },
    initialize() {
      this.setCategories();
      this.setAttributes();
      this.setNutritionInfo();
      this.setAssetsIds();
      this.setSuppliers();
    }
  },
  async created() {
    await this.$store.dispatch("app/loadAttributes");
    await this.$store.dispatch("app/loadCategories");
    await this.$store.dispatch("app/loadSuppliers");
    await this.$store.dispatch("app/loadProductMaxImages");
    this.loadImages = true;
    if (this.$route.params.id) await this.load(this.$route.params.id);
    this.initialize();
  },
};
</script>

<style lang="stylus">
@import "../Styles/product-editor.styl"
</style>
