<template>
  <div id="quote-editor" class="page-container quotes">
    <div class="page-title-container border-bottom-0 mb-0">
      <h1 class="page-title">Cotizaciones</h1>
    </div>

    <div>
      <b-card class="custom-card">
        <b-card-body class="p-4">
          <h2 class="custom-card-subtitle">Información general</h2>
          <p class="custom-card-description">Ingresa los datos de la empresa</p>

          <form class="custom-form" novalidate>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="customerIdentifierType">Tipo de documento *</label>
                  <FormSelect id="customerIdentifierType" v-model="customer.customerIdentifierType"
                    :items="identifierTypes" defaultOption="Elegir tipo de documento"
                    :showError="$v.customer.customerIdentifierType.$error" />
                  <form-error message="Tipo de documento es requerido" v-if="
                    $v.customer.customerIdentifierType.$error &&
                    !$v.customer.customerIdentifierType.required
                  "></form-error>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="customerIdentifier">Número de documento *</label>
                  <FormInput id="customerIdentifier" type="text" placeholder="Ingresar número de documento" :show-error="$v.customer.customerIdentifier.$error &&
                    !$v.customer.customerIdentifier.required
                    " v-model="customer.customerIdentifier" />

                  <form-error message="Número de documento es requerido" v-if="
                    $v.customer.customerIdentifier.$error &&
                    !$v.customer.customerIdentifier.required
                  "></form-error>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="name">Razón social/nombre *</label>
                  <FormInput id="name" type="text" placeholder="Ingresar Razón social/nombre" :show-error="$v.customer.customerFullName.$error &&
                    !$v.customer.customerFullName.required
                    " v-model="customer.customerFullName" />

                  <form-error message="Razón social/nombre es requerido" v-if="
                    $v.customer.customerFullName.$error &&
                    !$v.customer.customerFullName.required
                  "></form-error>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group d-flex flex-column">
                  <div class="label-container">
                    <label for="mobile">Teléfono</label>
                  </div>

                  <div class="form-field">
                    <b-input-group prepend="+51" class="form-phone-group d-flex flex-nowrap rounded-0">
                      <FormInput id="mobile" type="text" maxlength="20" placeholder="Ingresar teléfono"
                        v-mask="'#########'" v-model="customer.customerMobile" />
                    </b-input-group>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="address">Dirección</label>
                  <FormInput id="address" type="text" placeholder="Ingresar dirección" maxlength="150"
                    v-model="customer.customerAddress" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="customerContact">Persona de contacto</label>
                  <FormInput id="customerContact" type="text" placeholder="Ingresar nombre de persona de contacto"
                    maxlength="150" v-model="customer.customerContact" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group d-flex flex-column">
                  <div class="label-container">
                    <label for="mobile">Teléfono de la persona de contacto</label>
                  </div>

                  <div class="form-field">
                    <b-input-group prepend="+51" class="form-phone-group d-flex flex-nowrap rounded-0">
                      <FormInput id="mobile" type="text" maxlength="20" placeholder="Ingresar teléfono"
                        v-mask="'#########'" v-model="customer.customerContactMobile" />
                    </b-input-group>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="email">Correo electrónico</label>
                  <FormInput id="email" type="email" placeholder="Ingresar email" maxlength="100" :show-error="$v.customer.customerEmail.$error &&
                    !$v.customer.customerEmail.email
                    " v-model="customer.customerEmail" />

                  <form-error message="El correo electrónico ingresado no es válido" v-if="
                    $v.customer.customerEmail.$error &&
                    !$v.customer.customerEmail.email
                  "></form-error>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="contactType">Forma de contacto</label>
                  <FormSelect id="contactType" v-model="customer.contactType" :items="contactOptions"
                    defaultOption="Elegir forma de contacto" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="createdByName">Asesor comercial</label>
                  <FormInput id="createdByName" type="text" placeholder="Ingresar nombre de asesor comercial"
                    v-model="customer.createdByName" />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group d-flex justify-content-between align-items-start">
                  <div class="d-flex flex-column">
                    <label for="deliveryOption">Costo por envío</label>
                    <b-form-checkbox id="deliveryOption" v-model="customer.deliveryOption" switch
                      @change="changeDeliveryAmount($event)">
                    </b-form-checkbox>
                  </div>

                  <div class="d-flex flex-column" v-if="customer.deliveryOption">
                    <label for="address">Agregar costo</label>
                    <FormInput id="delivery" type="number" placeholder="Ingresar costo" maxlength="20" :show-error="$v.customer.delivery.$error &&
                      !$v.customer.delivery.required
                      " v-model="customer.delivery" />
                    <form-error message="Servicio de entrega es requerido" v-if="
                      $v.customer.delivery.$error &&
                      !$v.customer.delivery.required
                    "></form-error>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="observations">Observaciones</label>
                  <textarea id="observations" placeholder="Escriba aquí" class="custom-form-control"
                    v-model="customer.observations"></textarea>
                </div>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>

    <div class="table-container border-top-0 rounded-0">
      <div class="table-header rounded-0 px-4">
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div class="position-relative">
            <div class="d-flex align-items-center justify-content-between filter" @click="showFilters = !showFilters">
              <FiltersIcon />
              <div>Filtrar productos</div>
            </div>

            <filter-menu :show="showFilters" @hide="showFilters = false" :options="filterOptions"
              :selectedOptionFilter="filters" @setFilters="setFilters"
              :class="{ 'filter-menu-bottom': list.length }"></filter-menu>
          </div>

          <section class="d-flex align-items-center">
            <SearchBar placeholder="Buscar por descripción o ID de producto." :oldValue="$route.query.search"
              @search="onSearch($event)" @input="search = $event" class="searchbar" />
          </section>
        </div>

        <div class="d-flex flex-wrap pt-1">
          <span v-for="(val, name) in filters" v-bind:key="name"
            class="filter-item">
            {{ getFilterName(name, val) }}
            <button type="button" class="p-0 bg-transparent border-0" @click="deleteFilter(name)">
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>
      <div class="mb-0 p-4 bg-white">
        <p class="font-sm text-color-light-grey font-weight-semibold font-italic">
          ({{ list.length }})
          {{ list.length == 0 || list.length > 1 ? "Resultados" : "Resultado" }}
        </p>

        <div class="products-grid-container bg-white" v-if="list.length">
          <div class="products-grid bg-white">
            <div
              class="product-quote-card cursor-pointer d-flex flex-column"
              :class="{
                'border-card': o.selected,
                'cursor-disabled': o.disabled,
              }"
              v-for="o in list"
              :key="o.id"
              @click="addProductToProductList(o)"
            >
              <div
                class="product-quote-card-header d-flex justify-content-center position-relative"
              >
                <div
                  class="image-container d-flex justify-content-center align-items-center"
                >
                  <img
                    :src="o.thumbnail"
                    alt=""
                    v-if="o.thumbnail"
                    class="img-fluid"
                    :class="{ 'gray-image': o.disabled }"
                  />
                </div>

                <div class="tag text-white" :class="getBadgeInfo(o).color">
                  {{ getBadgeInfo(o).text }}
                </div>
              </div>

              <div class="flex-fill product-quote-card-name font-sm text-center py-3 px-2">
                <p class="m-0 text-color-light-grey font-weight-light">
                  {{ o.name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="whitout-results text-center d-flex justify-content-center align-items-center" v-else>
          <p class="font-italic">No se encontraron resultados</p>
        </div>

        <div class="mt-4 mb-3">
          <h2 class="custom-card-subtitle mb-2">Detalle del pedido</h2>

          <form-error message="Es necesario agregar productos a la cotización."
            v-if="$v.productList.$error && !$v.productList.required"></form-error>
        </div>

        <section class="mb-5">
          <ProductQuoteTable :list.sync="productList" :validationList="$v.productList"
            @remove-selection="removeProductFromProductList($event)" v-if="productList.length" />

          <p class="text-center font-sm mb-0 pt-3" v-else>
            Aún no ha agregado productos para cotizar.
          </p>
        </section>

        <section class="buttons d-flex justify-content-between align-items-center">
          <div>
            <button type="button" class="buttons-link bg-transparent mr-4"
              @click="save((status = quoteDraftStatusKey), (preview = false))">
              <DraftIcon class="fill-path-primary" />

              <span class="ml-2"> Guardar Borrador </span>
            </button>

            <button type="button" class="buttons-link bg-transparent"
              @click="save((status = quoteDraftStatusKey), (preview = true))">
              <ViewIcon class="fill-path-primary" />
              <span class="ml-2"> Visualizar </span>
            </button>
          </div>

          <div>
            <router-link :to="{ name: 'tools-quotes-list' }" class="button button-light mr-3">
              Cancelar
            </router-link>

            <button class="button button-primary" type="submit" @click="
              save((status = quoteGeneratedStatuskey), (preview = false))
              ">
              Generar cotización
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, requiredIf, email } from "vuelidate/lib/validators";

import {
  Alert,
  Constants,
  FilterMixin,
  SearchBar,
  FilterMenu,
  FiltersIcon,
  CloseBorderRoundedIcon,
  ConfirmationModalMixin,
  FormInput,
  FormSelect,
  FormError,
  DraftIcon,
  ViewIcon,
  PaginationMixin,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import QuoteMixin from "@/core/mixins/QuoteMixin";

import { ProductService, QuoteService } from "@/core/services";

import ProductQuoteTable from "../Components/ProductQuoteTable";

export default {
  components: {
    SearchBar,
    FilterMenu,
    FiltersIcon,
    CloseBorderRoundedIcon,
    ProductQuoteTable,
    FormInput,
    FormSelect,
    FormError,
    DraftIcon,
    ViewIcon,
  },
  mixins: [FilterMixin, ConfirmationModalMixin, QuoteMixin, PaginationMixin],
  data() {
    return {
      quoteId: this.$route.params.id,
      editing: false,
      quoteDraftStatusKey: LocalConstants.QuoteStatus.DRAFT.key,
      quoteGeneratedStatuskey: LocalConstants.QuoteStatus.GENERATED.key,
      list: [],
      productList: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ProductsStatus.ACTIVE,
            LocalConstants.ProductsStatus.INACTIVE,
          ],
        },
        {
          label: "Categoría",
          key: "categoryIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Con stock",
          key: "hasStock",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.StockProductsValues.YES,
            LocalConstants.StockProductsValues.NOT,
          ],
        },
        {
          label: "Con Imagen",
          key: "hasImage",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ImageProductsValues.YES,
            LocalConstants.ImageProductsValues.NOT,
          ],
        },
      ],
      customer: {
        customerFullName: null,
        customerIdentifierType: null,
        customerIdentifier: null,
        customerMobile: "",
        customerAddress: null,
        customerEmail: null,
        contactType: null,
        customerContact: null,
        customerContactMobile: "",
        observations: null,
        delivery: null,
        createdByName: null,
        deliveryOption: false,
      },
      identifierTypes: [
        Constants.IdentifierType.DNI,
        Constants.IdentifierType.RUC,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.PASSPORT,
      ],
      contactOptions: [
        LocalConstants.ContactOptions.CALL,
        LocalConstants.ContactOptions.WHATSAPP,
        LocalConstants.ContactOptions.EMAIL,
      ],
      storeConfig: null,
    };
  },
  validations: {
    customer: {
      customerIdentifierType: { required },
      customerIdentifier: { required },
      customerFullName: { required },
      customerEmail: { email },
      delivery: {
        required: requiredIf((model) => {
          return model.deliveryOption;
        }),
      },
    },
    productList: {
      required,
      minLength: (value) => value.length > 0,
      $each: {
        quantity: {
          required,
        },
        price: {
          required,
        }
      },
    },
  },
  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.quoteId = val
        this.loadQuoteData(val)
      }
    }
  },
  methods: {
    initialize() {
      const filterOptions = this.filterOptions.find(
        (item) => item.key === "categoryIds"
      );
      filterOptions.options = this.categories.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });
    },
    async loadQuoteData(id) {
      const response = await this.getQuoteData(id);
      if (response) {
        let delivery = response.customer.delivery

        this.customer = {
          ...response.customer,
          deliveryOption: !!delivery,
          delivery: delivery > 0 ? delivery : null
        };

        this.productList = response.productList.map((item) => {
          return {
            code: item.productCode,
            name: item.productName,
            stock: item.stock,
            images: [
              {
                url: item.productImage,
              },
            ],
            quantity: item.quantity,
            price: item.price,
            thumbnail: item.productImage
          };
        });

        this.editing = true;
      }
    },
    async loadData() {
      if (this.search)
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: { search: this.search },
        });

      try {
        this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          search: this.search,
          ...this.filters,
        };

        let response = await ProductService.list(params);

        this.list = response.payload.content.map((item) => {
          let existsInProductList = this.productList.find(
            (o) => o.code == item.code
          );

          return {
            ...item,
            selected: existsInProductList ? true : false,
            quantity: existsInProductList ? existsInProductList.quantity : null,
            disabled:
              !item.stock ||
              item.stock == 0 ||
              item.status == LocalConstants.ProductsStatus.INACTIVE.key,
          };
        });

        this.orderProductsByStatus();

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    orderProductsByStatus() {
      this.list.sort((a, b) => {
        // Si 'a' no tiene 'disabled' o es 'false' y 'b' tiene 'disabled' o es 'true', 'a' va primero
        if (
          (a.disabled === undefined || a.disabled === false) &&
          b.disabled === true
        ) {
          return -1;
        }
        // Si 'a' tiene 'disabled' o es 'true' y 'b' no tiene 'disabled' o es 'false', 'b' va primero
        if (
          a.disabled === true &&
          (b.disabled === undefined || b.disabled === false)
        ) {
          return 1;
        }
        // Si ambos tienen la misma condición respecto a 'disabled', se mantienen en su orden actual
        return 0;
      });
    },
    addProductToProductList(item) {
      if (item.disabled) return;

      if (!item.selected) {
        item.selected = true;
        this.productList.push(item);
      } else {
        item.selected = false;
        item.quantity = null;

        let existsInMainList = this.list.find((o) => o.code == item.code);
        if (existsInMainList) {
          existsInMainList.selected = false;
        }

        let index = this.productList.findIndex((o) => o.code == item.code);

        if (index >= 0) {
          this.productList.splice(index, 1);
        }
      }
    },
    removeProductFromProductList(o) {
      let findIndexToRemoveFromProductList = this.productList.indexOf(o);
      this.productList.splice(findIndexToRemoveFromProductList, 1);

      let existsInMainList = this.list.find((item) => item.code == o.code);
      if (existsInMainList) {
        existsInMainList.selected = false;
      }
    },
    validateFormFields() {
      let status = true;

      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -100,
          });
        });

        status = false;
      }

      return status;
    },
    getQuotePayload(status) {
      return {
        ...this.customer,
        delivery: (this.customer.deliveryOption && this.customer.delivery) ? this.customer.delivery : 0,
        items: this.productList.map(({ code, quantity, price }) => {
          return {
            productCode: code,
            quantity,
            price
          };
        }),
        status,
      };
    },
    async save(status, preview) {
      let validatedForm = this.validateFormFields();
      if (!validatedForm) return;

      this.$store.dispatch("app/loading", true);

      try {
        let data = this.getQuotePayload(status);
        let response = null;

        if (!this.editing) {
          response = await QuoteService.save(data);
        } else {
          response = await QuoteService.update(this.quoteId, data);
        }

        if (response.status == "OK") {
          response.payload.expirationDate = "7 Días";

          if (status == this.quoteDraftStatusKey && !preview) {
            Alert.success("Se almacenó la información correctamente", () =>
              this.$router.push({ name: "tools-quotes-list" })
            );
          } else if (status == this.quoteGeneratedStatuskey) {
            await this.generatePDF(response.payload);
            Alert.success("Se almacenó la información correctamente", () =>
              this.$router.push({ name: "tools-quotes-list" })
            );
          } else if (preview) {
            await this.generatePDF(response.payload, preview);
            this.$router.push({ name: 'tools-quotes-edit', params: { id: response.payload.id } })
          }
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    getBadgeInfo(o) {
      let data = {};

      if (!o.stock || o.stock == 0) {
        data.color = "disabled";
        data.text = "Agotado";
      } else if (o.status == LocalConstants.ProductsStatus.INACTIVE.key) {
        data.color = "disabled";
        data.text = "No disponible";
      } else {
        data.color = "available";
        data.text = "Disponible";
      }

      return data;
    },
    changeDeliveryAmount(e) {
      if (!e) this.customer.delivery = null
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      categories: "app/getCategories"
    })
  },
  async created() {
    if (this.quoteId) {
      // Asignar carga de todos los productos
      await this.onSearch("");
      await this.loadQuoteData(this.quoteId);
    }
  },
  async mounted() {
    await this.$store.dispatch("app/loadCategories");
    this.initialize();
    if (!this.quoteId && this.user) {
      this.customer.createdByName = `${this.user.firstName} ${this.user.lastName}`;
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      await this.loadData();
    }
  },
};
</script>

<style lang="stylus">
@import '../Styles/products-quote-form.styl';
</style>
