<template>
  <div
    class="custom-filter-menu d-none"
    :class="{ 'd-block': show, 'show-options': showOptions }"
  >
    <div class="content">
      <div
        id="parent-options"
        class="d-flex flex-column options parent-options"
        :class="{ 'show-options': showOptions }"
        v-if="selectedFilter"
      >
        <span
          v-for="option in options"
          :class="`d-flex justify-content-between item-option ${
            option.key == selectedFilter.key ? 'active' : ''
          }`"
          v-bind:key="option.label"
          @click="changeSelectedType(option)"
          >{{ option.label }}

          <span
            class="d-none d-md-inline-block text-dark font-weight-normal"
            v-if="option.key == selectedFilter.key"
          >
            <i class="ri-arrow-right-s-line ri-xl"></i>
          </span>
        </span>
      </div>

      <div
        id="children-options"
        class="options children-options"
        :class="{ active: showOptions }"
        v-if="selectedFilter"
      >
        <!-- Single -->
        <div
          class="subitem-options-container"
          v-if="selectedFilter.type == Constants.TypeFilters.SINGLE"
        >
          <span
            v-for="subItem in selectedFilter.options"
            :class="`d-flex justify-content-between subitem-option text-uppercase ${
              localFilters[selectedFilter.key] != subItem.key ? '' : 'active'
            }`"
            v-bind:key="subItem.label"
            @click="setOptionFilter(selectedFilter.key, subItem)"
            >{{ subItem.label }}</span
          >
        </div>

        <!-- Multiple -->
        <div
          class="subitem-options-container"
          v-if="selectedFilter.type == Constants.TypeFilters.MULTIPLE"
        >
          <b-form-checkbox-group
            id="multipleOption"
            class="custom-form"
            v-model="localFilters[selectedFilter.key]"
          >
            <span
              v-for="subItem in selectedFilter.options"
              class="d-flex justify-content-between subitem-option text-uppercase"
              v-bind:key="subItem.label"
              @click="toggleFilter(subItem.key)"
            >
              <b-form-checkbox :value="subItem.key"
                ><span class="custom-checkbox-label-filters">{{
                  subItem.label
                }}</span></b-form-checkbox
              >
            </span>
          </b-form-checkbox-group>
        </div>

        <!-- Date -->
        <div
          class="subitem-options-container options-datepicker"
          v-if="selectedFilter.type == Constants.TypeFilters.DATE"
        >
          <date-picker
            :is-range="isRange"
            :columns="
              isRange
                ? $screens({ default: 1, lg: 2 })
                : $screens({ default: 1 })
            "
            v-model="localFilters[selectedFilter.key]"
            @update:from-page="pageChange"
            @input="selectedDays($event)"
          />
          <!-- @input="selectedDays($event)" -->
        </div>
      </div>
    </div>

    <div
      class="d-flex align-items-center filter-menu-buttons justify-content-md-end"
      :class="showOptions ? 'justify-content-between' : 'justify-content-end'"
    >
      <button
        type="button"
        class="bg-transparent border-0 d-md-none"
        @click="showOptions = false"
        v-if="showOptions"
      >
        <i class="ri-arrow-left-s-line"></i>
      </button>

      <div>
        <span class="mx-2 button button-cancel" @click="hideFilter"
          >Cancelar</span
        >
        <button class="mx-2 button button-primary" @click="setFilters">
          Aplicar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";

import { Constants } from "wize-admin";

export default {
  name: "FilterMenu",
  data() {
    return {
      Constants,
      showOptions: false,
      selectedFilter: null,
      localFilters: {},
      listDaysValues: Constants.ListDaysValues,
    };
  },
  created() {
    let queryParams = this.$route.query;
    let filters = { ...queryParams };

    if (Object.keys(queryParams).length >= 1) {
      // Eliminar queries que no sean filtros
      delete filters.search;
      delete filters.page;

      for (const filter in filters) {
        let option = this.options.find((item) => item.key == filter);

        if (option.type == Constants.TypeFilters.MULTIPLE) {
          // En algunos casos cuando solo se ha seleccionado un item
          // el valor devuelto por Vue Router es un string y cuando se selecciona múltiples items
          // el valor es un array
          if (queryParams[filter] instanceof Array) {
            filters[filter] = queryParams[filter];
          } else {
            filters[filter] = [queryParams[filter]];
          }
        } else if (option.type == Constants.TypeFilters.SINGLE) {
          filters[filter] = queryParams[filter];
        } else if (option.type == Constants.TypeFilters.DATE) {
          if (option.isRange) {
            let newKey = queryParams[filter].split("__");
            let startDate = newKey[0];
            let endDate = newKey[1];

            startDate = moment(startDate, "DD-MM-YYYY");

            endDate = moment(endDate, "DD-MM-YYYY");

            filters[filter] = {
              start: startDate,
              end: endDate,
            };

            filters[`${filter}Start`] = moment(startDate).format("YYYY-MM-DD");
            filters[`${filter}End`] = moment(endDate).format("YYYY-MM-DD");
          } else {
            filters[filter] = moment(queryParams[filter], "DD-MM-YYYY");
          }
        }
      }

      this.localFilters = {
        ...filters,
      };

      this.$emit("setFilters", { ...this.localFilters });
    }
  },
  mounted() {
    let defaultFilter = this.options.find((item) => item.default);
    this.selectedFilter = defaultFilter || this.options[0];

    this.setDefaultOptions();
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedOptionFilter: {
      type: [Object, Array],
    },
  },
  components: {
    DatePicker,
  },
  watch: {
    selectedOptionFilter: function () {
      this.localFilters = { ...this.selectedOptionFilter };
    },
  },
  methods: {
    setDefaultOptions() {
      if (Object.keys(this.$route.query).length === 0) {
        this.options.forEach((option) => {
          if (option.type == Constants.TypeFilters.SINGLE) {
            if (option.defaultOptions.length) {
              this.setOptionFilter(option.key, option.defaultOptions[0]);
            }
          } else if (option.type == Constants.TypeFilters.MULTIPLE) {
            if (option.defaultOptions.length) {
              option.defaultOptions.forEach((item) => {
                this.toggleFilter(item.key, option.key);
              });
            }
          }
        });

        setTimeout(() => {
          this.setFilters();
        }, 400);
      }
    },
    toggleFilter(key, defaultParentKey) {
      let filters = { ...this.localFilters };

      if (defaultParentKey) {
        if (!filters[defaultParentKey]) {
          filters[defaultParentKey] = [];
        }
      } else {
        if (!filters[this.selectedFilter.key]) {
          filters[this.selectedFilter.key] = [];
        }
      }

      setTimeout(() => {
        let selected = defaultParentKey
          ? filters[defaultParentKey]
          : filters[this.selectedFilter.key];

        const index = selected.indexOf(key);
        if (index === -1) {
          selected.push(key); // Agrega si no existe
        } else {
          selected.splice(index, 1); // Elimina si ya está seleccionado
        }
      }, 100);

      this.localFilters = { ...filters };
    },
    changeSelectedType(option) {
      this.showOptions = true;
      this.selectedFilter = option;
    },
    setFilters() {
      // Asignar fechas
      let filters = { ...this.localFilters };

      // Eliminar opciones que no tengan un valor asignado
      for (const filter in filters) {
        if (
          filters[filter] == null ||
          filters[filter] == undefined ||
          (filters[filter] instanceof Array && !filters[filter].length)
        ) {
          delete filters[filter];
        }
      }

      // En el caso de fechas de debe evaluar si es con rango par así
      // asignar dos valores
      for (const filter in filters) {
        let option = this.options.find((item) => item.key == filter);

        if (option && option.type == this.Constants.TypeFilters.DATE) {
          if (option.isRange) {
            filters[`${filter}Start`] = moment(filters[filter].start).format(
              "YYYY-MM-DD"
            );
            filters[`${filter}End`] = moment(filters[filter].end).format(
              "YYYY-MM-DD"
            );

            // delete filters[filter]
          } else {
            filters[filter] = moment(filters[filter]).format("YYYY-MM-DD");
          }
        }
      }

      this.localFilters = JSON.parse(JSON.stringify(filters));

      // Asignar filtros a rutas
      let routerQueries = { ...this.$route.query, ...filters };

      for (const key in routerQueries) {
        if (key.includes("Start") || key.includes("End")) {
          delete routerQueries[key];
        }
      }

      for (const key in routerQueries) {
        let option = this.options.find((item) => item.key == key);

        // Para fechas
        if (option && option.type == this.Constants.TypeFilters.DATE) {
          if (option.isRange) {
            let startDate = routerQueries[key].start;
            let endDate = routerQueries[key].end;

            if (startDate) {
              startDate = moment(startDate).format("DD-MM-YYYY");
            }

            if (endDate) {
              endDate = moment(endDate).format("DD-MM-YYYY");
            }

            routerQueries[key] =
              startDate && endDate ? `${startDate}__${endDate}` : startDate;
          } else {
            let date = moment(routerQueries[key]).format("DD-MM-YYYY");
            routerQueries[key] = date;
          }
        }
      }

      this.$router.push({
        name: this.$route.name,
        query: { ...routerQueries },
      });

      this.$emit("setFilters", { ...this.localFilters });
    },
    hideFilter() {
      this.$emit("hide");
    },
    pageChange() {
      this.assignDayNames();
    },
    assignDayNames() {
      setTimeout(async () => {
        let daysList = document.querySelectorAll(".vc-weekday");

        // Cambiar nombre de días
        daysList.forEach((item) => {
          let str = this.listDaysValues.find((i) => i.key == item.textContent);

          if (str) {
            item.textContent = str.value;
          }
        });

        this.assignTextColor();
      }, 100);
    },
    assignTextColor() {
      let dateItems = document.querySelectorAll(".vc-day");

      dateItems.forEach((item) => {
        if (item.firstElementChild.tagName == "DIV") {
          item.lastChild.style.color = "white";
        }
      });
    },
    selectedDays() {
      this.assignTextColor();
    },
    setOptionFilter(option, subItem) {
      let filters = { ...this.localFilters };
      // En caso la opción seleccionada no exista ó tenga un valor distinto al que se quiere asignar
      if (!filters[option] || filters[option] != subItem.key) {
        filters = {
          ...filters,
          [option]: subItem.key,
        };
      } else {
        delete filters[option];
        filters = {
          ...filters,
        };
      }

      this.localFilters = { ...filters };
    },
  },
  computed: {
    isRange: function () {
      return this.selectedFilter && this.selectedFilter.isRange ? true : false;
    },
  },
};
</script>

<style lang="stylus">
@import "./filter-menu.styl"
</style>
