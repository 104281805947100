/* eslint-disable no-useless-catch */
import { Constants, Util, Alert } from "wize-admin";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { mapGetters } from 'vuex'

import { Helpers } from "@/core/utils";

import { QuoteService } from "@/core/services";

export default {
  methods: {
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    async generatePDF(response, preview) {
      const customerIdentifierType = this.getIdentifierType(
        response.customerIdentifierType
      ).label;
      const customerIdentifier = response.customerIdentifier;

      const customerNameType =
        response.customerIdentifierType == Constants.IdentifierType.RUC.key
          ? "Razón social"
          : "Nombre";
      const customerName = response.customerFullName;

      const customerAdrress = response.customerAddress || "No especificado";
      const customerContact = response.customerContact || "No especificado";
      const observations = response.observations;

      let logo = await this.getImageToPdf();
      let content = [
        {
          text: `COTIZACIÓN N° ${response.correlationId}`,
          style: "title",
        },
        {
          text: [
            {
              text: moment(new Date()).format("D [de] MMMM [de] YYYY"),
              alignment: "right",
            },
          ],
          margin: [0, 0, 0, 40],
        },
        {
          text: [
            { text: `${customerIdentifierType}: `, bold: true },
            customerIdentifier,
          ],
          margin: [0, 0, 0, 4],
        },
        {
          text: [{ text: `${customerNameType}: `, bold: true }, customerName],
          margin: [0, 0, 0, 4],
        },
        {
          text: [{ text: "Dirección: ", bold: true }, customerAdrress],
          margin: [0, 0, 0, 4],
        },
        {
          text: [{ text: "Contacto: ", bold: true }, customerContact],
          margin: [0, 0, 0, 4],
        },
        { text: "\n" },
        {
          text: "Estimados Señores. Por medio de la presente, y de acuerdo a su solicitud, sometemos a su consideración nuestra oferta como sigue:",
        },
        { text: "\n" },
        {
          style: "table",
          table: {
            headerRows: 1,
            widths: ["auto", 300, 70, 70],
            body: this.getTableData(response),
          },
          margin: [0, 0, 0, 15], // Espacio inferior entre la tabla y el total
        },
        {
          text: "Sin otro particular y en espera de su aceptación a la presente, quedamos a su disposición para cualquier consulta que ustedes estimen conveniente.",
        },
        { text: "\n\n" },
        {
          text: "CONDICIONES GENERALES", margin: [0, 0, 0, 4], bold: true
        },
        {
          text: [{ text: "Precios: ", bold: true }, "Incluyen IGV"],
          margin: [0, 0, 0, 4],
        },
        {
          text: [{ text: "Validez de la oferta: ", bold: true }, "7 días calendario"],
          margin: [0, 0, 0, 4],
        },
        {
          text: [{ text: "Plazo de entrega: ", bold: true }, "48 horas máximo sin considerar domingos y feriados"],
          margin: [0, 0, 0, 4],
        },
        {
          text: [{ text: "Forma de pago: ", bold: true }, "Depósito o transferencia interbancaria"]
        },
        { text: "\n\n" },
        {
          text: "OBSERVACIONES", margin: [0, 0, 0, 4], bold: true
        },
        {
          text: observations,
          margin: [0, 0, 0, 4],
        },
        { text: "\n\n\n" },
        {
          text: response.createdByName,
          alignment: "right",
          margin: [0, 0, 0, 4],
        },
        {
          text: `Cel: ${this.getConfValue('MOBILE')}`,
          alignment: "right"
        },
      ];

      if (preview) {
        content.unshift({
          style: "alert",
          table: {
            headerRows: 1,
            widths: [515],
            body: [[{ text: "Éste documento no tiene validez." }]],
          },
        });
      }
      const self = this;
      const quoteContent = {
        content,
        styles: {
          title: {
            fontSize: 22,
            bold: true,
            alignment: "center",
            margin: [0, 0, 0, 40],
          },
          content: {
            fontSize: 12,
            margin: [0, 0, 0, 10],
          },
          table: {
            fontSize: 12,
            margin: [0, 0, 0, 15],
          },
          total: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          footer: {
            fontSize: 14,
            italic: true,
          },
          label: {
            fontSize: 10,
            bold: true,
          },
          alert: {
            fontSize: 10,
            margin: [0, 0, 0, 15],
            fillColor: "#e82c1e",
            alignment: "center",
            bold: true,
            color: "#ffffff",
          },
        },
        defaultStyle: {
          color: "#3e4657"
        },
        pageSize: "A4",
        pageMargins: [40, 80, 40, 60],
        header: [
          {
            columns: [
              {
                image: logo,
                fit: [120, 120],
                alignment: "left",
                margin: [40, 20, 40, 0],
              },
              {
                text: {
                  text: self.getWebsiteUrl(),
                  link: self.getWebsiteUrl(),
                },
                alignment: "right",
                color: self.getConfValue("COLOR_PRIMARY"),
                bold: true,
                margin: [40, 35, 40, 0],
              },
            ]
          },
          {
            canvas: [
              { type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 },
            ],
            margin: [40, 5, 40, 0],
          },
        ],
        footer: function (currentPage) {
          return [
            {
              canvas: [
                { type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 },
              ],
              margin: [40, 0, 40, 5],
            },
            {
              columns: [
                {
                  margin: [40, 0, 40, 0],
                  text: currentPage.toString(),
                  color: self.getConfValue("COLOR_PRIMARY"),
                },
                {
                  margin: [40, 0, 40, 0],
                  text: "Cotización",
                  alignment: "right",
                  color: self.getConfValue("COLOR_PRIMARY")
                },
              ]
            },
          ]
        },
      };

      pdfMake.createPdf(quoteContent).download(`Cotización-${response.correlationId}`);
    },
    getTableData(response) {
      let products = [...response.items],
        data = [],
        subList = [];

      let { totalPlusDelivery } = response

      products.forEach((item) => {
        let obj = {
          quantity: item.quantity,
          name: item.productName,
          unitPrice: item.price,
          price: item.price * item.quantity,
        };

        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            let element = { text: obj[key], margin: [0, 4, 0, 4] };
            if (key == "price" || key == "unitPrice") {
              element = { text: Util.formatNumber(obj[key]), alignment: 'right', margin: [0, 4, 0, 4] };
            } else if (key == "quantity") {
              element = { text: obj[key], alignment: 'center', margin: [0, 4, 0, 4] };
            }
            subList.push(element);
          }
        }

        data.push(subList);
        subList = [];
      });

      if(response.delivery && response.delivery > 0) {
        data.push(
          [
            { text: "1", alignment: 'center', margin: [0, 4, 0, 4] },
            { text: "Servicio de entrega", margin: [0, 4, 0, 4] },
            { text: Util.formatNumber(response.delivery), margin: [0, 4, 0, 4], alignment: 'right' },
            { text: Util.formatNumber(response.delivery), margin: [0, 4, 0, 4], alignment: 'right' }
          ]
        )
      }

      const tableHeader = [
        { text: "CANT.", margin: [0, 4, 0, 4], bold: true },
        { text: "DESCRIPCIÓN", margin: [0, 4, 0, 4], bold: true },
        { text: "P. UNIT.", margin: [0, 4, 0, 4], bold: true },
        { text: "P. TOTAL", margin: [0, 4, 0, 4], bold: true },
      ];

      tableHeader.forEach((item) => {
        item.fillColor = this.getConfValue("COLOR_PRIMARY");
        item.color = "#FFFFFF";
        item.alignment = "center";
      });

      const tableFooter = [
        [
          { text: "", colSpan: 2, border: [false, false, false, false] },
          "",
          { text: "TOTAL", bold: true, margin: [0, 4, 0, 4] },
          { text: Helpers.formatPrice(totalPlusDelivery), alignment: 'right', bold: true, margin: [0, 4, 0, 4] }
        ],
      ];

      return [tableHeader, ...data, ...tableFooter];
    },
    async getQuoteData(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await QuoteService.get(id);
        if (response.payload) {
          let customer = JSON.parse(JSON.stringify(response.payload));

          let productList = [...response.payload.items]

          return {
            customer,
            productList,
          };
        }
      } catch (ex) {
        let message = ex.errors.message;

        if (message == "No encontrado") {
          message = Constants.Errors.RECORD_NOT_FOUND;
        }

        Alert.error(message, () =>
          this.$router.push({ name: "tools-quotes-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async getImageToPdf() {
      const response = await fetch(this.getConfValue("LOGO_IMAGE"));
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    getWebsiteUrl: function () {
      return process.env.VUE_APP_WEB_URL;
    }
  },
  computed: {
    ...mapGetters({
      getConfValue: "app/getConfValue"
    })
  }
};
