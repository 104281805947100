<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    dialog-class="add-block-modal general-configuration"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    id="add-block-modal"
  >
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="bg-transparent p-0 border-0"
        @click.prevent="close()"
      >
        <CloseModalIcon class="fill-primary" />
      </button>
    </div>

    <section>
      <form
        class="general-configuration custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
        @submit.prevent="submit"
        novalidate
      >
        <div class="form-container border-bottom-0 p-0">
          <div class="form-group form-group-2-columns px-0">
            <div class="label-container label-image-container mb-2 mb-md-0">
              <label for="name">Imagen</label>
              <p class="label-description">
                Sube una imagen que contenga como máximo {{ maxWidth }}px de ancho en
                formato .png o .jpg
              </p>
            </div>

            <div class="form-field w-auto">
              <UploadImages
                :maxWidth="maxWidth"
                :maxHeight="maxHeight"
                :maxFiles="1"
                :files="setOldFile"
                @onChange="onChange"
                @remove="removeImage"
              />
            </div>
          </div>

          <div class="form-group form-group-2-columns px-0">
            <div class="label-container">
              <label for="title">Título * </label>
            </div>

            <div class="form-field w-100">
              <FormInput
                id="title"
                type="text"
                placeholder="Ingresar título"
                :show-error="$v.form.title.$error && !$v.form.title.required"
                v-model="form.title"
              />

              <form-error
                message="El título es requerido"
                v-if="$v.form.title.$error && !$v.form.title.required"
              ></form-error>
            </div>
          </div>

          <div class="form-group form-group-2-columns px-0">
            <div class="label-container">
              <label for="link">Enlace</label>
            </div>

            <div class="form-field w-100">
              <FormInput
                id="link"
                type="url"
                placeholder="Ingresar enlace"
                v-model="form.link"
              />

              <!-- <form-error
          message="Facebook"
          v-if="$v.form.facebook.$error && !$v.form.facebook.required"
        ></form-error> -->
            </div>
          </div>

          <div class="form-group-buttons text-right mt-0 mb-0 px-0 pt-0">
            <a href="" class="button button-light" @click.prevent="close()">
              Cancelar
            </a>

            <button class="button button-primary" type="submit">Guardar</button>
          </div>
        </div>
      </form>
    </section>
  </b-modal>
</template>

<script>
import { required, Alert } from "vuelidate/lib/validators";

import {
  ModalMixin,
  CloseModalIcon,
  UploadImages,
  FormInput,
  FormError,
} from "wize-admin";

import UploadImagesMixin from "@/core/mixins/UploadImagesMixin";

export default {
  name: "AddBlockModal",
  mixins: [ModalMixin, UploadImagesMixin],
  data() {
    return {
      maxWidth: 400,
      maxHeight: 400,
      form: {
        image: null,
        title: null,
        link: null,
      },
      oldFiles: [],
      showImage: true,
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  props: {
    blockType: String,
    selectedOption: Object,
  },
  components: {
    CloseModalIcon,
    UploadImages,
    FormInput,
    FormError,
  },
  watch: {
    selectedOption: function (data) {
      setTimeout(() => {
        this.form = { ...data };
      }, 300);
    },
  },
  methods: {
    async onChange(files) {
      if (!files.length) return;

      try {
        let uploadedfiles = await this.uploadFiles(files);
        this.form.image = uploadedfiles[0].url;
      } catch (error) {
        Alert.error(error);
      }
    },
    removeImage() {
      this.form.image = null;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      if (this.selectedOption) {
        this.$emit("update", this.form);
        this.hide();

        return;
      }

      let blockData = { ...this.form, type: this.blockType };

      // Reiniciar datos de form
      for (let key in this.form) {
        this.form[key] = null;
      }

      this.$emit("save", blockData);
      this.close();
    },
    close() {
      // Reiniciar datos de form
      for (let key in this.form) {
        this.form[key] = null;
      }

      this.$v.$reset();

      this.$emit("clearSelectedOption");

      this.hide();
    },
  },
  computed: {
    setOldFile: function () {
      if (this.form.image) {
        return [{ url: this.form.image }];
      } else {
        return [];
      }
    },
  },
};
</script>
<style lang="styl">
@import "../Styles/general-configuration.styl";
@import "../Styles/add-block-modal.styl";
</style>
