import { http } from "@/http";

export default {
  list(params) {
    return http.get("api/v1/admin/customer", { params });
  },
  get(id) {
    return http.get(`/api/v1/admin/customer/${id}`);
  },
  save(payload) {
    return http.post("/api/v1/admin/customer", payload);
  },
  update(id, payload) {
    return http.put(`/api/v1/admin/customer/${id}`, payload);
  },
  toggleStatus(id, status) {
    return http.put(`/api/v1/admin/customer/${id}/status`, null, {
      params: {
        status,
      },
    });
  },
  downloadCustomersList(params) {
    return http.get('/api/v1/admin/customer/xlsx', {
      responseType: 'blob',
      params
    })
  }
};
