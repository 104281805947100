import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/admin/attribute", { params });
  },
  get(id) {
    return http.get(`/api/v1/admin/attribute/${id}`);
  },
  save(payload) {
    return http.post("/api/v1/admin/attribute", payload);
  },
  update(id, payload) {
    return http.put(`/api/v1/admin/attribute/${id}`, payload);
  },
  toggleStatus(id, isActive) {
    return http.put(`/api/v1/admin/attribute/${id}/active`, null, {
      params: {
        isActive,
      },
    });
  },
  createAttributeValue(attributeId, payload) {
    return http.post(`/api/v1/admin/attribute/${attributeId}/value`, payload);
  },
  updateAttributeValue(attributeId, valueId, payload) {
    return http.put(
      `/api/v1/admin/attribute/${attributeId}/value/${valueId}`,
      payload
    );
  },
  updateAttributeValueStatus(attributeId, valueId, isActive) {
    return http.put(
      `/api/v1/admin/attribute/${attributeId}/value/${valueId}/active`,
      null,
      {
        params: {
          isActive,
        },
      }
    );
  },
  deleteAttributeValue(attributeId, valueId) {
    return http.delete(`/api/v1/admin/attribute/${attributeId}/value/${valueId}`);
  },
  delete(id) {
    return http.delete(`/api/v1/admin/attribute/${id}`);
  },
};
