<template>
  <div
    class="category-item d-flex justify-content-center align-items-center mb-2 px-4 cursor-pointer animate__animated animate__fadeIn"
    draggable
    @dragstart="$emit('dragstart', $event)"
    @drop="$emit('drop', $event)"
    @dragover="$emit('dragover')"
    @dragleave="$emit('dragleave')"
  >
    <p class="category-item-label my-0">{{ category.name }}</p>

    <button
      type="button"
      class="button-remove bg-danger rounded-circle border-0 d-flex justify-content-center align-items-center"
      @click="removeItem"
    >
      <TrashIcon class="fill-white" />
    </button>
  </div>
</template>

<script>
import { TrashIcon } from "wize-admin";

export default {
  name: "CategoryItem",
  props: {
    category: Object,
  },
  components: {
    TrashIcon,
  },
  methods: {
    removeItem() {
      this.$emit('remove', this.category.id)
    }
  }
};
</script>

<style lang="stylus">
@import "../Styles/category-item.styl"
</style>
