<template>
  <div id="container" class="general-config page-container">
    <div class="page-title-container">
      <h1 class="page-title">Configuración de Biolink</h1>
    </div>

    <section
      class="tabs-container d-flex flex-column flex-md-row justify-content-center"
    >
      <div
        class="tabs-sidebar d-flex flex-column"
        :class="{ header: isHeader, theme: isThemeColor }"
      >
        <router-link
          :to="{ name: $route.name, query: { tab: item.tab } }"
          v-for="item in tabs"
          :key="item.id"
          class="tab-link text-decoration-none"
          :class="{
            active:
              item.tab === $route.query.tab ||
              (item.id == 1 && !$route.query.tab),
          }"
          >{{ item.text }}</router-link
        >
      </div>

      <div
        class="tabs-content"
        :class="{ header: isHeader, theme: isThemeColor }"
      >
        <GeneralConfig v-if="isGeneral" />

        <SocialNetworksConfig v-if="isSocialNetworks" />

        <BlocksBiolinkConfig v-if="isBlocksConfig" />
      </div>
    </section>
  </div>
</template>

<script>
import GeneralConfig from "../Components/GeneralConfig";
import SocialNetworksConfig from "../Components/SocialNetworksConfig";
import BlocksBiolinkConfig from "../Components/BlocksBiolinkConfig";

export default {
  data() {
    return {
      tabs: [
        {
          id: 1,
          text: "Información general",
          tab: "general",
        },
        {
          id: 2,
          text: "Redes sociales",
          tab: "social-networks",
        },
        {
          id: 3,
          text: "Bloques",
          tab: "blocks",
        }
      ],
    };
  },
  components: {
    GeneralConfig,
    SocialNetworksConfig,
    BlocksBiolinkConfig
  },
  computed: {
    isGeneral() {
      return this.$route.query.tab == "general" || !this.$route.query.tab;
    },
    isOrders() {
      return this.$route.query.tab == "orders"
    },
    isHeader() {
      return this.$route.query.tab == "header";
    },
    isBanner() {
      return this.$route.query.tab == "banner";
    },
    isFooter() {
      return this.$route.query.tab == "footer";
    },
    isThemeColor() {
      return this.$route.query.tab == "theme";
    },
    isSocialNetworks() {
      return this.$route.query.tab == "social-networks";
    },
    isJsCodes() {
      return this.$route.query.tab == "js-codes"
    },
    isBlocksConfig() {
      return this.$route.query.tab == "blocks"
    }
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/general-config-page.styl"
</style>
