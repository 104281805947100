<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar cliente" : "Nuevo cliente" }}
      </h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 760px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex align-items-center"
        >
          <h3>
            {{ editing ? "Datos del cliente" : "Datos del nuevo cliente" }}
          </h3>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-section">
              <h2 class="custom-card-subtitle">Información general</h2>

              <p class="custom-card-description">
                Ingresa los datos del cliente
              </p>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="identifierType">Tipo de documento *</label>
                    <FormSelect
                      id="identifierType"
                      v-model="customer.identifierType"
                      :items="identifierTypes"
                      defaultOption="Elegir tipo de documento"
                      :showError="$v.customer.identifierType.$error"
                      @change="updateMask()"
                    />
                    <form-error
                      message="Tipo de documento es requerido"
                      v-if="
                        $v.customer.identifierType.$error &&
                        !$v.customer.identifierType.required
                      "
                    ></form-error>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="identifier">Número de documento *</label>

                    <FormInput
                      id="identifier"
                      type="text"
                      v-mask="documentMask"
                      placeholder="Ingresar número de documento"
                      :show-error="
                        $v.customer.identifier.$error &&
                        !$v.customer.identifier.required
                      "
                      v-model="customer.identifier"
                    />

                    <form-error
                      message="Número de documento es requerido"
                      v-if="
                        $v.customer.identifier.$error &&
                        !$v.customer.identifier.required
                      "
                    ></form-error>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="firstName">Nombres *</label>

                    <FormInput
                      id="firstName"
                      type="text"
                      maxlength="100"
                      placeholder="Ingresar nombres"
                      :show-error="
                        $v.customer.firstName.$error &&
                        !$v.customer.firstName.required
                      "
                      v-model="customer.firstName"
                    />

                    <form-error
                      message="Nombres son requeridos"
                      v-if="
                        $v.customer.firstName.$error &&
                        !$v.customer.firstName.required
                      "
                    ></form-error>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="lastName">Apellidos *</label>
                    <FormInput
                      id="lastName"
                      type="text"
                      maxlength="100"
                      placeholder="Ingresar apellidos"
                      :show-error="
                        $v.customer.lastName.$error &&
                        !$v.customer.lastName.required
                      "
                      v-model="customer.lastName"
                    />
                    <form-error
                      message="Apellidos son requeridos"
                      v-if="
                        $v.customer.lastName.$error &&
                        !$v.customer.lastName.required
                      "
                    ></form-error>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="mobile">Celular *</label>

                    <FormInput
                      id="mobile"
                      type="text"
                      maxlength="20"
                      @input="$v.customer.mobile.$touch()"
                      placeholder="Ingresar celular"
                      v-mask="'#########'"
                      :show-error="
                        $v.customer.mobile.$error &&
                        !$v.customer.mobile.required
                      "
                      v-model="customer.mobile"
                    />

                    <form-error
                      message="Celular es requerido"
                      v-if="
                        $v.customer.mobile.$error &&
                        !$v.customer.mobile.required
                      "
                    ></form-error>

                    <form-error
                      message="Celular solo debe contener números o el signo '+'"
                      v-if="!$v.customer.mobile.regex"
                    ></form-error>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">Correo electrónico</label>
                    <FormInput
                      id="email"
                      type="email"
                      :disabled="editing"
                      maxlength="100"
                      placeholder="Ingresar correo electrónico"
                      :show-error="
                        $v.customer.email.$error && !$v.customer.email.required
                      "
                      v-model="customer.email"
                    />
                    <form-error
                      message="Correo electrónico es requerido"
                      v-if="
                        $v.customer.email.$error && !$v.customer.email.required
                      "
                    ></form-error>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="birthday">Fecha de nacimiento</label>

                    <FormInput
                      id="DELIVERY_SPAN_DAYS"
                      type="text"
                      placeholder="DD/MM/AAAA"
                      v-model="customer.birthday"
                      v-mask="'##/##/####'"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="gender">Género</label>
                    <FormSelect
                      id="gender"
                      v-model="customer.gender"
                      :items="genders"
                      defaultOption="Elegir género"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="referenceId">Referencia de registro</label>
                    <FormSelect
                      id="referenceId"
                      v-model="customer.referenceId"
                      :items="referencesOptions"
                      defaultOption="Elegir referencia"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'clients-list' }"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </router-link>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, Constants, FormError, FormSelect, FormInput } from "wize-admin";
import { VueMaskDirective } from "v-mask";
import moment from "moment";

import { ReferenceService, CustomerService } from "@/core/services";
import { required, helpers } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

export default {
  directives: {
    mask: VueMaskDirective,
  },
  data() {
    return {
      customer: {
        identifierType: null,
        identifier: null,
        firstName: null,
        lastName: null,
        mobile: null,
        email: null,
        birthday: null,
        gender: null,
        referenceId: null,
      },
      genders: [
        LocalConstants.Genders.MALE,
        LocalConstants.Genders.FEMALE,
        LocalConstants.Genders.OTHER,
      ],
      identifierTypes: [
        Constants.IdentifierType.DNI,
        Constants.IdentifierType.RUC,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.PASSPORT,
      ],
      referencesOptions: [],
      editing: false,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
  },
  validations: {
    customer: {
      identifierType: { required },
      identifier: { required },
      firstName: { required },
      lastName: { required },
      mobile: { required, regex: helpers.regex("alpha", /^[0-9+]*$/) },
      email: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  computed: {
    documentMask() {
      let max = "########";

      switch (this.customer.identifierType) {
        case Constants.IdentifierType.RUC.key:
          max = "###########";
          break;
        case Constants.IdentifierType.CE.key:
          max = "############";
          break;
        case Constants.IdentifierType.PASSPORT.key:
          max = "NNNNNNNNNNNNNNN";
          break;
      }

      return max;
    },
  },
  methods: {
    updateMask() {
      this.showIdentifier = false;

      setTimeout(() => {
        this.showIdentifier = true;
      }, 300);
    },
    async save() {
      const self = this;
      self.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      let data = { ...this.customer };
      data.birthday = this.customer.birthday
        ? moment(this.customer.birthday).format("YYYY-MM-DD")
        : null;

      try {
        this.$store.dispatch("app/loading", true);
        let response;
        if (!this.editing) response = await CustomerService.save(data);
        else
          response = await CustomerService.update(this.$route.params.id, data);

        Alert.success("Se almacenó la información correctamente");

        if (!this.editing) {
          this.$router.push({
            name: "clients-edit",
            params: { id: response.payload.id },
          });

          this.editing = true;
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await CustomerService.get(id);
        this.customer = { ...response.payload };
        this.customer.birthday = this.customer.birthday
          ? moment(this.customer.birthday).format("DD/MM/YYYY")
          : null;
        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({ name: "clients-list" }));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async getReferences() {
      try {
        this.$store.dispatch("app/loading", true);

        const resp = await ReferenceService.listActive();
        this.referencesOptions = [];

        resp.payload.forEach((it) => {
          this.referencesOptions.push({ key: it.id, label: it.description });
        });
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.getReferences();

    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped></style>
